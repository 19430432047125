<template>
  <div class="evaluation">
    <second-model>
        <template v-slot:right-content>
            <second-model-title :breadcrumbList="breadcrumbList"></second-model-title>
            <router-view></router-view>
        </template>
    </second-model>
    <the-footer></the-footer>
  </div>
</template>

<script>
import  { onMounted, reactive, watch} from 'vue'
import { useRouter, useRoute } from 'vue-router';
import SecondModel from '../components/SecondModel.vue'
import SecondModelTitle from '../components/SecondModelTitle.vue'
import TheFooter from '@/components/TheFooter.vue';
export default {
  components: { SecondModel, SecondModelTitle, TheFooter },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const breadcrumbList = reactive([{
      title: '在线测评',
      path: ''
    }])

    onMounted(() => {
        // router.push('/evaluation/scratch')
    })

    watch(
      () => route.path, // 监听的源对象，这里使用 route.path
      (newPath) => {
        console.log(newPath)
        // 在 $route 变化时被调用
        if (newPath == '/evaluation') {
            router.push('/evaluation/scratch')
        }
        // 在这里执行其他操作
      },
      { immediate: true } // 立即触发监听
    );

    return {
        breadcrumbList
    }
  }
}
</script>

<style lang="scss">
.evaluation {
    .left-menu {
        position: relative;
        .el-menu-item {
            &::before {
                content: '';
                position: absolute;
                left: 20px;
                top: 7px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }
        }

        .el-menu-item1 {
            &::before {
                background-color: #ffb925;
            }
        }
        .el-menu-item2 {
            &::before {
                background-color: #ff967e;
            }
        }
        .el-menu-item3 {
            &::before {
                background-color: #ade471;
            }
        }
    }

    .evaluation-list {
        min-height: 300px;
    }
}
</style>