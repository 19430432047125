<template>
  <div class="second-model2">
    <the-header></the-header>
    <slot name="banner"></slot>
    <div class="second-model2-container container">
      <slot name="model-content"></slot>
    </div>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
export default {
  components: { TheHeader },

}
</script>

<style lang="scss">
.second-model2 {
  .second-model2-container {
    margin-top: 15px;
    margin-bottom: 15px;
    min-height: 800px;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 12px 40px;
    box-sizing: border-box;
  }
}

.second-model2.is-banner {
  .second-model2-container {
    margin-top: -50px;
    position: relative;
    z-index: 2;
  }
}
</style>