<template>
    <div class="evaluation-cell-body">
        <div :class="['evaluation-cell', stateTextClass]">
            <div class="cell-title ub ub-ac">{{ item.competitionName }}
                <span class="cell-state ub-f1 tx-r" v-if="!isMockExam">{{ stateText }}</span>
            </div>
            <div :class="['cell-info ub ub-ac ub-be']">
                <!-- 考生查看 -->
                <div class="ub ub-ver ub-pc" v-if="isMockExam">
                    <div class="cell-date">模拟考试时间：长期</div>
                </div>
                <div class="ub ub-ver ub-pc" v-if="!isMockExam && userAccount.role == 0">
                    <div class="cell-date" v-if="item.enrollStats != -1">报名时间：{{ item.enrollStartTime }}-{{ item.enrollEndTime }}</div>
                    <div class="cell-date" v-if="item.examinationStartTime">考试时间：{{ item.examinationStartTime }}</div>
                    <div class="cell-date" v-if="item.examinationStats != 0">考试结果公布时间：{{ item.openTime }}</div>
                </div>
                <!-- 评审者查看 -->
                <div class="cell-info-item ub ub-ac" v-if="userAccount.role == 1">
                    <div class="cell-date">考试时间：{{ item.startTime }}-{{ item.endTime }}</div>
                    <div class="cell-corrected">已批改：{{ item.correctedSum }}人</div>
                    <div class="cell-uncorrected">待批改：{{ item.uncorrectedSum }}人</div>
                </div>
                <div class="cell-buttons ub ub-ac">
                    <template v-if="isMockExam">
                        <!-- 模拟考试 -->
                        <el-dropdown>
                            <!-- <el-button color="#ff632d" round plain @click="examinationChange(item.examId, item.competitionId, 1, true)">模拟考试</el-button> -->
                            <el-button color="#ff632d" round plain @click="item.examId && mockExamData.length == 0 ? examinationChange(item.examId, 1, true) : ''">模拟考试</el-button>
                            <template #dropdown v-if="mockExamData.length > 0">
                                <el-dropdown-menu>
                                    <el-dropdown-item v-for="(subItem) in mockExamData" :key="subItem.examId" @click="examinationChange(subItem.examId, 1, true)">{{ subItem.examName }}</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </template>
                    <template v-if="!isMockExam && userAccount.role == 0">
                        <!-- 常规考试 -->
                        <el-button color="#0e9bee" round plain @click="registrationChange(item.competitionId, item.step)" v-if="isRegistrationBtn">去报名</el-button>
                    </template>
                    <!-- 评审者查看 -->
                    <template v-if="userAccount.role == 1">
                        <el-button color="#0e9bee" round plain v-if="item.reviewStatus == 0" disabled>未开始</el-button>
                        <el-button :color="item.uncorrectedSum > 0 ? '#0e9bee' : ''" round plain @click="examinationChange(item.examId, 3)" v-if="item.reviewStatus == 1" :disabled="item.uncorrectedSum == 0">去批改</el-button>
                        <el-button round plain v-if="item.reviewStatus == -1" disabled>已结束</el-button>
                    </template>
                </div>
            </div>
        </div>
        
    </div>
    <el-dialog
            v-model="tipDialogVisible"
            :title="tipDialogTitle"
            width="80%"
            destroy-on-close
            center
            custom-class="notice-dialog"
            @close="cancelTipDialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            >

            <div class="tip-cotent">
                <p v-for="(item, index) in tipDialogContent" :key="index">{{ item }}</p>
            </div>
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="cancelTipDialog">取消</el-button>
                <el-button type="primary" :disabled="countdown != 0" @click="confirmBtn()">
                    <span v-if="countdown != 0" style="margin-right: 5px;">{{formattedTime}}</span>我已知晓 
                </el-button>
                </span>
            </template>
        </el-dialog>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import DownloadTicket from '@/pages/components/DownloadTicket.vue';
import store from '@/store';
import config from '@/http/config'
import { queryExamInfo } from '@/http/api'

export default {
  components: { DownloadTicket },
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        },
        id: {
            type: Number,
            default: 1
        }
    }, 
    setup(props) {
        const tipDialogVisible = ref(false)

        const router = useRouter();
        const route = useRoute();
        const userAccount = computed(() => store.getters.userAccount)
        // 模考测试试卷
        const mockExamData = reactive([])
        // 是否是模拟考试
        const isMockExam = computed(() => {
            return props.item.testPaperType == 1 // 1模拟考试 2常规考试
        })
        // 是否显示去报名按钮
        const isRegistrationBtn = computed(() => { 
            return props.item.enrollStats == 1 && props.item.userEnrollStats == 0 && props.item.applicationStatus != 2
        })
        const stateTextClass = computed(() => {
            if (stateText.value == '报名未开始' || stateText.value == '考试已结束') {
                return 'info'
            }
        })
        // 当前考试状态
        const stateText = computed(() => {
            switch (Number(props.item.enrollStats)) { // 报名状态(0未开始 1开始 -1结束)
                case 0:
                    return '报名未开始'
                case 1: 
                    return '报名进行中'
                case -1:
                    switch (Number(props.item.examinationStats)) { // 考试是否开始状态(0未开始 1已开始 -1已结束)
                        case 0:
                            // return '已报名，等待考试开始'
                            return '等待考试开始'
                        case 1:
                            return '考试进行中'
                        case -1:
                            return '考试已结束'
                        default: 
                            return ''
                    }
                default:
                    return '';
            }
        })

        const specialityType = computed(() => {
            if (props.id == 1) {
                return 'scratch'
            } else if (props.id == 2) {
                return 'Python'
            } else {
                return 'C++'
            }
        })

        const tipDialogTitle = computed(() => {
            return `${specialityType.value}的报名须知`
        })
        const tipDialogContent = computed(() => {
            if (props.id == 1) {
                return [
                    '1、请考生确认报名专业为“'+ specialityType.value +'”，该专业通过一级后可跳级；',
                    '2、该专业分为客观题和主观题，客观题学生需要在考试系统中进行答题，此阶段不允许切换答题环境。主观题允许学生在自己电脑的编程环境中实现编程过程及调试，然后再考试系统中对应位置提交代码。百分制，原则上60分以上通过；',
                    '3、注册考生信息时，红色*为必填项，其中考生照片上传要求:标准2寸（蓝底或红底），长宽：3.5*5.3cm，jpg格式，大小1M以内，分辨率范围在300~400之间；',
                    '4、具体报名及考试时间请关注“关工智慧”公众号或通过报考官网“青少年人工智能素养与创新能力测评”-首页或在线测评列表中查看；',
                    '5、请考试明确费用标准，点击确认缴费报考成功，报名成功后可“在线测评”界面进行模拟练习；',
                    '6、本次考试报名截止时问为'+ props.item.enrollEndTime +'，在报名结束前可取消报名，考试费即时退还至原账户;报名结束后不可取消报名，费用将不再退还'
                ]
            } else {
                return [
                    '1、请考生确认报名专业为“'+ specialityType.value +'”，该专业通过一级后可跳级；',
                    '2、该专业采用考试系统内嵌的编程环境实现编程过程及调试，百分制，原则上60分以上通过；',
                    '3、注册考生信息时，红色*为必填项，其中考生照片上传要求:标准2寸（蓝底或红底），长宽：3.5*5.3cm，jpg格式，大小1M以内，分辨率范围在300~400之间；',
                    '4、具体报名及考试时间请关注“关工智慧”公众号或通过报考官网“青少年人工智能素养与创新能力测评”-首页或在线测评列表中查看；',
                    '5、请考试明确费用标准，点击确认缴费报考成功，报名成功后可“在线测评”界面进行模拟练习；',
                    '6、本次考试报名截止时问为XX月XX日XX: 00，在报名结束前可取消报名，考试费即时退还至原账户;报名结束后不可取消报名，费用将不再退还'
                ]
            }
        })
        
        onMounted(() => {
            if (isMockExam.value && config.type != 'prod') {
                getMockExamData()
            }
        })

        function getMockExamData() {
            queryExamInfo({
                examType: props.id
            }).then((res) => {
                mockExamData.length = 0
                mockExamData.push(...res.data);
            }).catch((err) => {
            })
        }

        /**
         * 模拟考试
         */
        function examinationChange(examId, type, isMockExamType) {
            const params = {
                examId: examId,
                type: type,
                uid: userAccount.value.userId,
                pageName: props.item.examinationType,
                routeName: route.name,
                competitionId: props.item.competitionId,
                isMockExamType: isMockExamType
            }
            router.push({ name: 'ModelTest', params: params })
        }

        /**
         * 去报名
         * @param {*} id 
         * @param {*} step 
         */
        function registrationChange(id, step) {
            if (step == 1) {
                tipDialogVisible.value = true
                timer = setInterval(updateCountdown, 1000);
            } else {
                router.push(`/examination-registration?id=${id}&step=${step}&from=1`)
            }
        }

        // 倒计时
        const countdown = ref(10); // 设置倒计时的初始时间（秒）
        let timer; // 用于存储 setInterval 的 ID
        // 格式化倒计时时间为 ss 格式
        const formattedTime = computed(() => {
            const seconds = countdown.value % 60;
            return `${seconds.toString().padStart(2, '0')}`;
        });
        // 更新倒计时
        const updateCountdown = () => {
            if (countdown.value > 0) {
                countdown.value--;
            } else {
                clearInterval(timer);

            }
        };

        onUnmounted(() => {
            clearInterval(timer);
        })

        /**
         * 确认无误，提交报考信息
         */
        function confirmBtn() {
            cancelTipDialog()
            router.push(`/examination-information?id=${props.item.competitionId}&from=1`)
        }

        function cancelTipDialog() {
            countdown.value = 10
            clearInterval(timer);
            tipDialogVisible.value = false
        }
        
        
        return {
            userAccount,
            isMockExam,
            isRegistrationBtn,
            stateTextClass,
            stateText,
            mockExamData,
            examinationChange,
            registrationChange,
            countdown,
            formattedTime,
            cancelTipDialog,
            tipDialogVisible,
            tipDialogTitle,
            tipDialogContent,
            confirmBtn
        }
    }
}
</script>

<style lang="scss">
.evaluation-cell-body {
    margin-bottom: 25px;
    .evaluation-cell {
        border: 1px solid #acdefc;
        border-radius: 15px;
        overflow: hidden;
        .cell-title {
            height: 74px;
            padding: 0 24px;
            background-color: #e6f4fc;
            font-size: 24px;
            .cell-state {
                color: #0e9bee;
                font-size: 18px;
            }

            .cell-countdown {
                font-size: 14px;
                color: red;
            }
        }
        .cell-info {
            padding: 10px 24px;

            .cell-info-item {
                line-height: 30px;
            }
            .cell-date {
                font-size: 16px;
                line-height: 30px;
                margin-right: 10px;
                color: #666666;
            }
            .cell-corrected {
                margin-right: 20px;
            }
            .cell-buttons {
                .el-button {
                    margin: 24px 0;
                }
                .download-ticket {
                    margin-left: 20px;
                    line-height: 80px;
                }
            }
        }
    }   
}
.evaluation-cell.info {
    .cell-title {
        .cell-state {
            color: #989a99;
        }
    }
}
.notice-dialog {
    .el-dialog__body {
        .tip-title {
            font-size: 16px;
        }
        .tip-cotent {
            margin-top: 10px;
            font-size: 16px;
            p {
                line-height: 24px;
                margin-bottom: 15px;
                color: #37383a;
            }
        }
    }
}
</style>