<template>
 <div :class="['quesition-tips ub ub-ver', showBorder ? 'show-border': '']">
     <div class="que-tip-info">
         <div class="ub tip-con" v-if="getQuesitionData.pageType == 1">
             <span class="block answered">已答</span>
             <div class=" ub-f1 center tx-c"><span class="block current">当前</span></div>
             <span class="block unanswered">未答</span>
         </div>
         <div :class="['ub ub-ver']" v-else>
             <div class="score-info">
               <!-- <div class="score-total" v-if="getQuesitionData.pageType == 4">正确率：{{getQuesitionData.quesitionInfoData.paperAccuracy}}</div> -->
               <div class="score-total" v-if="getQuesitionData.pageType != 4">总计得分：{{getQuesitionData.quesitionInfoData.totalAnswerScore}}分</div>
               <div class="score-detail">（共{{getQuesitionData.quesitionInfoData.totalQuestionCount}}题<span v-if="getQuesitionData.pageType != 4">，答对{{getQuesitionData.quesitionInfoData.rightAnswerSum}}题，答错{{getQuesitionData.quesitionInfoData.errorAnswerSum}}题</span>）</div>
             </div>
             <div class="ub tip-con" v-if="getQuesitionData.pageType == 2">
                <span class="block right">正确</span>
                 <div class="ub-f1 center tx-c"><span class='block error'>错误</span></div>
                <span class='block uncorrected'>未批改</span>
             </div>
             <div class="ub tip-con tip-con4 ub ub-ac ub-pc" v-else>
                <div class="ub ub-ver ub-f1">
                  <div class="ub-f1"><span class='block right'>正确率=100%</span></div>
                  <div class="ub-f1"><span class='block error'>正确率&lt;100%</span></div>
                </div>
                <span class='block uncorrected'>操作题</span>
             </div>
         </div>
     </div>
     <div class="que-tip-progress" v-if="getQuesitionData.pageType == 1">
       <el-progress :text-inside="true" :stroke-width="15" :percentage="getQuesitionData.progress"></el-progress>
     </div>
 </div>
</template>

<script>
import { mapGetters } from 'vuex'

 export default {
   props: {
     type: {
       type: Number,
       default: 0
     },

     showBorder: {
       type: Boolean,
       default: false
     }
   },
   data () {
     return {
       progress: 0
     }
   },
   created() {

   },
   components: {

   },

   computed: {
     ...mapGetters([
        'getQuesitionData'
      ]),
   },

   watch: {
     'getQuesitionData.actQueData.userAnswer' (newVal, oldVal) {
       if (oldVal) return
       let quesitionData = this.getQuesitionData.quesitionData[this.getQuesitionData.answerSheetIndex]
       let answerNum = 0
       for (let i = 0; i < quesitionData.length; i++) {
         for (let j = 0; j < quesitionData[i].list.length; j++) {
           if(quesitionData[i].list[j].userAnswer) {
             answerNum += 1
           }
         }
       }
        this.$store.dispatch('setProgress', answerNum)
      //  queState.setProgress(answerNum)
     }
   }
 }
</script>

<style lang="scss">
.quesition-tips {
  padding: 15px;

  .que-tip-info {

    .tip-con {
      padding: 0 15px;
      
      .block {
        display: block;
        text-align: center;
        font-size: 12px;
        padding-right: 20px;
        position: relative;
      }

      .center {
        padding-right: 0;
        display: inline-block;
      }

      .block.current {
        color: var(--el-color-primary);;
        display: inline-block;
        vertical-align: top;
      }

      .block::before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        box-sizing: border-box;
        position: absolute;
        right: 0;
        top: 1px;
        border-radius: 2px;
      }

      .block.answered:before {
        background-color: var(--el-color-primary);
      }

      .block.current:before, .block.error:before {
        border:1px solid var(--el-color-primary);
      }

      .block.unanswered:before {
        border:1px solid #a0a0a0;
      }

      .block.right:before {
        background-color: #22ac38;
      }

      .block.error {
        display: inline-block;
        vertical-align: top;
      }

      .block.error:before {
        background-color: #fe6f71;
        border: 0px;
      }

      .block.uncorrected:before {
        background-color: #c0c0c0;
      }
    }

    .tip-con4 {

      .block.right {
        display: inline-block;
        margin-bottom: 6px;
      }
    }

    .score-info {
      text-align: center;

      .score-total {
        font-size: 18px;
        font-weight: bold;
      }

      .score-detail {
        font-size: 12px;
        line-height: 60px;
        margin-bottom: 8px;
      }
    }
  }

  .que-tip-progress {
    margin-top: 15px;
    .el-progress-bar {
      .el-progress-bar__outer {
        .el-progress-bar__innerText {
          height: 15px;
        }
      }
    }
  }
}

.quesition-tips.show-border {
  border-bottom: 1px solid #eff3f8;
}
 
</style>
