<template>
    <div class="query-index">
        <second-model-2>
            <template v-slot:model-content>
                <second-model-title :breadcrumbList="breadcrumbList"></second-model-title>
                <div class="query-title">成绩及证书查询</div>
                <el-form class="query-form" :model="formData" :rules="rules" ref="formRef" label-position="top"  label-width="100px">
                    <el-form-item prop="ticketNumber" label="准考证号">
                        <el-input v-model="formData.ticketNumber" clearable placeholder="请输入准考证号" autocomplete="new-ticketNumber"></el-input>
                    </el-form-item>
                    <el-form-item prop="idCardNumber" label="身份证号">
                        <el-input v-model="formData.idCardNumber" clearable placeholder="请输入身份证号" autocomplete="new-idCardNumber" ></el-input>
                    </el-form-item>
                    <el-button class="submit-button" type="primary" round @click="submitForm">确认</el-button>
                </el-form>
            </template>
        </second-model-2>
    </div>
</template>

<script>
import SecondModel2 from '../components/SecondModel2.vue'
import SecondModelTitle from '../components/SecondModelTitle.vue'
import { reactive, ref, nextTick } from 'vue'
import { queryAchievement } from '@/http/api'
import { useRouter } from 'vue-router';

export default {
    components: {
        SecondModel2,
        SecondModelTitle
    },
    setup() {
        const router = useRouter()
        const breadcrumbList = reactive([{
            title: '成绩及证书查询',
            path: ''
        }])
        const formRef = ref(null); // 创建对 form 元素的引用
        const formData = ref({
            ticketNumber: '',
            idCardNumber: ''
        });
        const rules = reactive({
            ticketNumber: [
                { required: true, message: '请输入准考证号', trigger: 'blur' }
            ],
            idCardNumber: [
                { required: true, message: '请输入身份证号', trigger: 'blur' },
            ]
        });

        const submitForm = async () => {
            // 表单提交逻辑
            // ...

            // 等待 nextTick 后再执行校验操作
            await nextTick();

            // 校验表单
            if (formRef.value) {
                formRef.value.validate(valid => {
                    if (valid) {
                        // 校验通过，执行表单提交逻辑
                        // ...
                        queryHttp()
                    }
                });
            }
        };

        function queryHttp() {
            queryAchievement({
                admission: formData.value.ticketNumber,
                userIdCard: formData.value.idCardNumber
            }).then((res) => {
                console.log(res)
                router.push({
                    path:'/query-detail', 
                    query: {data: JSON.stringify(res.data)}
                })
            }).catch((err) => {
            })
        }

        return {
            breadcrumbList,
            formRef,
            formData,
            rules,
            submitForm
        }
    }
}
</script>

<style lang="scss">
    .query-index {
        .query-title {
            font-size: 24px;
            font-weight:bold;
            text-align: center;
            margin: 75px 0 30px;
            color: #0e9bee; 
        }
        .query-form {
            width: 405px;
            margin: auto;
            .el-form-item {
                margin-bottom: 40px;
                .el-form-item__label {
                    color: #0e9bee; 
                }
                .el-form-item__content {
                    .el-input {
                        .el-input__wrapper {
                            padding: 0;
                            border-radius: 5px;
                            overflow: hidden;
                            box-shadow: inset 0 0 8px 2px #ededed;
                            .el-input__inner {
                                height: 40px;
                                line-height: 40px;
                                font-size: 16px;
                                padding: 0 10px;
                            }
                            .el-input__suffix {
                                padding-right: 5px;
                            }
                        }
                    }
                }
            }
            .submit-button {
                width: 120px;
                height: 40px;
                font-size: 18px;
                margin: 37px auto 0;
                display: block;
            }
        }
    }
</style>