<template >
    <div class="query-detail">
        <SecondModel2>
            <template v-slot:model-content v-if="data">
                <SecondModelTitle :breadcrumbList="breadcrumbList"></SecondModelTitle>
                <div class="query-title">2024年3月青少年人工智能素养与创新能力测评-成绩</div>
                <div class="detail-info ub">
                    <div class="detail-info-user ub ub-ver">
                        <div class="ub">
                            <el-image :src="data.userImg" fit="cover" />
                            <div class="user-info">
                                <div class="user-item">姓名：{{data.userName}}</div>
                                <div class="user-item">准考证号：{{data.admission}}</div>
                                <div class="user-item">证件类型及证件号：{{data.userCardType}} {{data.userIdCard}}</div>
                                <div class="user-item">考试科目：{{data.competitionName}}</div>
                                <div class="user-item">等级：{{data.examGrade}}</div>
                            </div>
                        </div>
                        <div class="result">成绩结果：<span>{{data.userPassedOrNot}}</span></div>
                    </div>
                    <div class="detail-info-certificate ub ub-f1">
                        <div class=" ub ub-ac ub-ver ub-f1" v-if="data.isContest == 1 && data.passedOrNot == 1">
                            <el-image src="" fit="cover" />
                            <div class="download ub ub-be">
                                <div class="tip">请点击下载证书保存本次考试记录</div>
                                <DownloadTicket :certificateData="data.certificate" :type="2">下载证书</DownloadTicket>
                            </div>
                        </div>
                        <div class="no-certificate ub ub-ac ub-pc ub-f1" v-else>
                            如对结果有疑问，可联系组委会申请查询成绩
                        </div>
                    </div>
                </div>
            </template>
        </SecondModel2>
    </div>
</template>
<script>
import SecondModel2 from '../components/SecondModel2.vue'
import SecondModelTitle from '../components/SecondModelTitle.vue'
import { reactive, onMounted, ref, shallowReactive } from 'vue'
import DownloadTicket from '../components/DownloadTicket.vue'
import { useRoute } from 'vue-router';
import dataJs from '@/utils/data'

export default {
    components: {
        SecondModel2,
        SecondModelTitle,
        DownloadTicket
    },
    setup() {
        const route = useRoute();
        const breadcrumbList = reactive([{
            title: '成绩及证书',
            path: ''
        }])
        let data = ref(null)
        onMounted(() => {
            const paramsData = route.query.data
            if (paramsData) {
                let dataInfo = JSON.parse(paramsData) 
                dataInfo.userCardType = dataJs.idCardType.find(item => item.id == dataInfo.idCardType).name;
                dataInfo.userPassedOrNot = dataInfo.isContest == 0 ? '未参与考试' : dataInfo.passedOrNot == 1? '通过' :'未通过考试'
                data.value = dataInfo

            }
        })
        

        return {
            breadcrumbList,
            data
            
        }
    }
}
</script>
<style lang="scss">
    .query-detail {
        .query-title {
            font-size: 24px;
            font-weight:bold;
            margin: 50px 0 30px;
            color: #0e9bee; 
        }
        .detail-info {
            .detail-info-user {
                width: 550px;
                border-right: 1px dashed #999999;
                .el-image {
                    width: 113px;
                    height: 157px;
                }
                .user-info {
                    margin-left: 37px;
                    color: #999999;
                    .user-item {
                        margin-bottom: 8px;
                    }
                }
                .result {
                    margin-top: 90px;
                    span {
                        font-size: 30px;
                        font-weight: bold;
                        color: #ff632d;
                    }
                }
            }
            .detail-info-certificate {
                .el-image {
                    width: 336px;
                    height: 248px;
                    border: 1px solid #a0a0a0;
                }
                .download {
                    width: 336px;
                    margin-top: 14px;
                    .tip {
                        color: #0e9bee;
                        font-size: 14px;

                    }
                    .download-ticket {
                        position: inherit;
                        .download-ticket-button {
                            color: #333333;
                        }
                        canvas {
                            left: -272px;
                            top: -263px;
                            width: 336px;
                        }
                    }
                }
                .no-certificate {
                    color: #333333;
                }
            }
        }
    }
</style>