<template>
  <!-- 填空题 -->
  <div class="que-option type2">
    <div class="select-item">
      <div class="text">填空1:</div>
      <div class="select-item">
        <el-input
          v-model="getAnswer"
          placeholder=""
          :disabled="getQuesitionData.pageType != 1"
        ></el-input>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {

    };
  },
  created() {

  },
 computed: {
        ...mapGetters([
        'getQuesitionData'
        ]),

       getAnswer: {
           get: function () {
               if (this.getQuesitionData.pageType == 1) {
                   return this.getQuesitionData.actQueData.userAnswer
               } else if (this.getQuesitionData.pageType == 2){
                   return this.getQuesitionData.actQueData.useAnswerRecord
               } else if (this.getQuesitionData.pageType == 4) {
                   return this.getQuesitionData.actQueData.optionAnswer
               } else {
                 return ''
               }
               
           },

           set: function (val) {
                this.getQuesitionData.actQueData.userAnswer = val
           }
       }
   },
  methods: {
    changeSelect(e) {
    },
  },
};
</script>
<style lang="scss" scoped>
.que-option {
  .text {
    float: left;
    color: #000;
    margin-top: 8%;
  }
  .select-item {
    display: inline-block;
    padding: 5px 5px 5px 5px;
    color: #000;
    line-height: 20px;
    margin: 5px 0;
  }
}
</style>