<template>
    <div class="download-ticket">
        <div class="download-ticket-button" @click="convertCanvasToPDF"><slot></slot></div>
        <canvas :class="type == 1 ? 'canvas-1' : ''" ref="canvasRef" :width="canvasSize.width" :height="canvasSize.height"></canvas>
    </div>
</template>

<script>

import { exhibitionConfirMenter } from '@/http/api'
import dataJs from '@/utils/data'
import { computed, onMounted, ref } from 'vue'
import store from '@/store'
import { ElMessage } from 'element-plus'
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
export default {
    props: {
        competitionId: {
            type: Number,
            default: null
        },
        certificateData: { // 证书数据
            type: String,
            default: ''
        },
        type: { // 1下载准考证 2下载证书
            type: Number,
            default: 1
        }
    }, 
    setup(props) {
        const userAccount = computed(() => store.getters.userAccount)
        const canvasRef = ref(null);
        const canvasSize = computed(() => {
            switch (props.type) {
                case 1:
                    return {
                    }
                case 2:
                    return {
                        width: 3366,
                        height: 2480
                    }
            
                default:
                    return {
                        width: 0,
                        height: 0
                    }
            }
        })
        const ticketName = ref('')
        onMounted(() => {
            console.log(props.type, props.competitionId)
            if (props.type == 1) {
                
                getTicketCanvas()
            } else {
                if (props.certificateData) {
                    getCertificateCanvas()
                } else {
                    ElMessage({
                        message: '数据异常',
                        type: 'warning',
                    })
                }
            }
        })
        /**
         * 获取准考证图片
         */
         function getTicketCanvas() {
            exhibitionConfirMenter({
                userId: userAccount.value.userId,
                competitionId: props.competitionId
            }).then((res) => {
                const data = res.data
                const canvas = canvasRef.value;
                const ctx = canvas.getContext('2d');
                var cssWidth = parseFloat(window.getComputedStyle(canvas).width);
                var cssHeight = parseFloat(window.getComputedStyle(canvas).height);
                canvas.width = cssWidth; // 考虑设备像素比
                canvas.height = cssHeight;
                // 绘制一个矩形
                ctx.fillStyle = '#ffffff';
                ctx.fillRect(0, 0, cssWidth, cssHeight);
                ctx.fillStyle = '#cccccc';
                ctx.fillRect(0, cssHeight - 250, cssWidth, 250);
                // 小logo
                const logoImg = new Image();
                logoImg.crossOrigin = 'anonymous';
                logoImg.src = require('../../assets/image/logo-small.png');
                logoImg.onload = () => {
                    ctx.drawImage(logoImg, (cssWidth - logoImg.width) / 2, 28, logoImg.width, logoImg.height);
                };
                // 准考证标题
                ctx.font = 'bold 40px Arial';
                ctx.fillStyle = '#000000';
                const ticketText = '准考证';
                const ticketTextWidth = ctx.measureText(ticketText).width;
                ctx.fillText('准考证', (cssWidth - ticketTextWidth) / 2, 165);
                // 线下标题
                ctx.font = '20px Arial';
                ctx.fillStyle = '#000000';
                ctx.fillText('（线下）', ((cssWidth - ticketTextWidth) / 2) + ticketTextWidth, 160);
                // 准考证号
                ctx.font = 'bold 18px Arial';
                ctx.fillStyle = '#000000';
                ctx.fillText('准考证号：', 269, 230);                
                ctx.fillText(data.admission, 358, 230);


                // 姓名信息
                ctx.font = 'bold 16px Arial';
                ctx.fillStyle = '#000000';
                ctx.fillText('姓名：', 269, 270);
                ctx.fillText('紧急联系人：', 269, 310);
                ctx.fillText('紧急联系人电话：', 269, 350);
                ctx.fillText('证件类型及号码：', 130, 395);
                ctx.fillText('考试科目：', 130, 435);
                ctx.fillText('考试时间：', 130, 475);
                ctx.fillText('考试教室：', 130, 515);
                ctx.fillText('考试地点：', 130, 555);
                ctx.font = '16px Arial';
                ctx.fillStyle = '#333333';
                ctx.fillText(data.userName, 319, 270);
                ctx.fillText(data.contactName, 370, 310);
                ctx.fillText(data.contactTel, 401, 350);
                const userCardType = dataJs.idCardType.find(item => item.id == data.idCardType).name;
                ctx.fillText(userCardType + ' ' + data.userIdCard , 262, 395);
                ctx.fillText(data.competitionName, 214, 435);
                ctx.fillText(data.time, 214, 475);
                ctx.fillText(data.competitionClassroom, 214, 515);
                wrapText(ctx, data.competitionLocation, 214, 555, 325, 20);
                // 内容宽度
                const userNameMetricsWidth = ctx.measureText(data.userName).width;
                const classRoomWidth = ctx.measureText(data.competitionClassroom).width;
                const competitionNameWidth = ctx.measureText(data.competitionNameWidth).width;
                // 右边标题
                ctx.font = 'bold 16px Arial';
                ctx.fillStyle = '#000000';
                const sexTitle = '性别：'
                const sexTitleX = 409 + userNameMetricsWidth + 50
                ctx.fillText(sexTitle, sexTitleX, 270);
                const userSexMetricsWidth = ctx.measureText(sexTitle).width;
                const seatNumberTitle = '座位号：'
                const seatNumberTitleX = 254 + classRoomWidth + 50
                ctx.fillText(seatNumberTitle, seatNumberTitleX, 515);
                const seatNumberWidth = ctx.measureText(seatNumberTitle).width;
                const examGradeTitle = '等级：'
                const examGradeTitleX = 254 + competitionNameWidth + 50
                ctx.fillText(examGradeTitle, examGradeTitleX, 435);
                const examGradeWidth = ctx.measureText(examGradeTitle).width;


                // 右边内容
                ctx.font = '16px Arial';
                ctx.fillStyle = '#333333';
                if (data.sex == 1) {
                    ctx.fillText('男', sexTitleX + userSexMetricsWidth, 270);
                } else {
                    ctx.fillText('女', sexTitleX + userSexMetricsWidth, 270);
                }
                ctx.fillText(data.seatNumber, seatNumberTitleX + seatNumberWidth, 515);
                ctx.fillText(data.examGrade, examGradeTitleX + examGradeWidth, 435);
                // 注意事项
                ctx.font = 'bold 16px Arial';
                ctx.fillStyle = '#000000';
                ctx.fillText('注意事项：', 10, cssHeight - 220);
                ctx.font = '16px Arial';
                ctx.fillStyle = '#000000';
                wrapText(ctx, '1、考生拿到准考证后请认真核对姓名、证件号码是否有误，如有错误请及时联系报名机构；', 10, cssHeight - 195, cssWidth - 20, 18);
                // ctx.fillText('2、开考30分钟后不得进入考场，缺考或误场作自动弃权处理；', 10, cssHeight - 110);
                wrapText(ctx, '2、考生请自行打印准考证，考试当天请带好准考证，考前30分钟，请你凭此准考证及木人身份证件(身份证或护照)原件进入所指定的地点参加考试，按照监考者师指引入座后，将准考证和身份证件放在桌面右上角，以备查对，无准考证不得参加考试；', 10, cssHeight - 170, cssWidth - 20, 22);
                ctx.fillText('3、开考30分钟后不得进入考场，缺考或误场作自动弃权处理；', 10, cssHeight - 100);
                wrapText(ctx, '4、考讨结束时间已到时，系统自动终止考试,以最后一次提交的内容为最终结果如已确认提前交卷，则不能再登录系统答题；', 10, cssHeight - 75, cssWidth - 20, 22);
                ctx.fillText('5、请提前效悉指定的考试地点，确认考点的县体位置和乘车路线，以便能按时到达；', 10, cssHeight - 25);


                // 用户头像
                const userImg = new Image();
                const userImgWidth = 120
                const userImgHeight = 156
                userImg.crossOrigin = 'anonymous';
                userImg.src = data.userImg;
                userImg.onload = () => {
                    const scale = Math.min(userImgWidth / userImg.width, userImgHeight / userImg.height);
                    // 计算绘制的宽度和高度
                    const drawWidth = userImg.width * scale;
                    const drawHeight = userImg.height * scale;
                    ctx.drawImage(userImg, 130, 207, drawWidth, drawHeight);
                };
                ticketName.value = `准考证_${data.userName}_${data.admission}`
                consople.log(ticketName.value)
            }).catch((err) => {
            })
        }

        /**
         * canvas绘制超出换行
         */
         function wrapText(context, text, x, y, maxWidth, lineHeight) {
            let line = '';
            let currentLine = '';

            for (let i = 0; i < text.length; i++) {
                const currentChar = text.charAt(i);
                const metrics = context.measureText(currentLine + currentChar);
                const currentWidth = metrics.width;

                if (currentWidth <= maxWidth || i === 0) {
                currentLine += currentChar;
                } else {
                context.fillText(currentLine, x, y);
                currentLine = currentChar;
                y += lineHeight;
                }
            }

            context.fillText(currentLine, x, y);
         }

        /**
         * 获取证书图片
         */
        function getCertificateCanvas() {
            const certificateData = JSON.parse(props.certificateData)
            const canvas = canvasRef.value;
            const ctx = canvas.getContext('2d');
            // 底logo
            const bigBg = new Image();
            bigBg.crossOrigin = 'anonymous';
            bigBg.src = require('../../assets/image/certificate-bg.jpg');
            bigBg.onload = () => {
                    ctx.drawImage(bigBg, 0, 0, 3366, 2480);
                    ctx.font = 'bold 50px Arial';
                    ctx.fillStyle = '#000000';
                    // 发证时间
                    ctx.fillText((certificateData.createTime).substring(0, 10), (2140 + 30), 1265);
                    // 姓名信息
                    ctx.fillText(certificateData.userName, (2046 + 30), 1435);
                    // 性别
                    ctx.fillText(certificateData.sex, (2046 + 30), 1605);
                    // 出生日期
                    ctx.fillText(certificateData.birthday, (2140 + 30), 1775);
                    // 证书编号
                    ctx.fillText(certificateData.certificateNo, (2795 + 30), 1265);
                    // 专业
                    ctx.fillText(certificateData.speciality, (2701 + 30), 1435);
                    // 等级
                    ctx.fillText(certificateData.examGrade, (2701 + 30), 1605);

                    // 用户头像
                    const userImg = new Image();
                    const userImgWidth = 411
                    const userImgHeight = 579
                    userImg.crossOrigin = 'anonymous';
                    userImg.src = certificateData.userImg;
                    userImg.onload = () => {
                        const scale = Math.min(userImgWidth / userImg.width, userImgHeight / userImg.height);
                        // 计算绘制的宽度和高度
                        // const drawWidth = userImg.width * scale;
                        // const drawHeight = userImg.height * scale;
                        const drawWidth = userImg.width;
                        const drawHeight = userImg.height;
                        ctx.drawImage(userImg, 2292, 529, drawWidth, drawHeight);
                    };
                    ticketName.value = `证书_${certificateData.userName}_${certificateData.speciality}`
                };
        }

        function downloadTicketTap() {
            
            const canvas = canvasRef.value;

            // 将 Canvas 转换为 Blob 对象
            canvas.toBlob(blob => {
                const blobUrl = URL.createObjectURL(blob);

                // 创建链接元素并设置属性
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = `${ticketName.value}.png`;

                // 触发点击事件进行下载
                link.click();

                // 释放 Blob 对象的 URL
                URL.revokeObjectURL(blobUrl);
            }, 'image/png');
        }

        function convertCanvasToPDF() {
            const canvas = canvasRef.value;

            // 使用 html2canvas 将 Canvas 转换为图像
            html2canvas(canvas).then(canvasImage => {
                // 创建 PDF 文档
                const pdf = new jsPDF();
                
                // 获取 Canvas 的宽度和高度
                const canvasWidth = canvas.width;
                const canvasHeight = canvas.height;

                // 设置 PDF 页面的宽度和高度
                const pdfWidth = pdf.internal.pageSize.width;
                const pdfHeight = pdf.internal.pageSize.height;

                // 计算缩放比例，确保图片适应 PDF 页面且保持纵横比
                const scale = Math.min(pdfWidth / canvasWidth, pdfHeight / canvasHeight);

                // 计算调整后的图片宽度和高度
                const scaledWidth = canvasWidth * scale;
                const scaledHeight = canvasHeight * scale;

                // 计算居中位置
                const x = (pdfWidth - scaledWidth) / 2;
                const y = (pdfHeight - scaledHeight) / 2;

                // 将 Canvas 添加到 PDF，并设置 options 以适应整个页面
                pdf.addImage(canvas.toDataURL('image/png'), 'PNG', x, y, scaledWidth, scaledHeight);

                // 下载 PDF 文件
                pdf.save(`${ticketName.value}.pdf`);
            });
        }
        return {
            canvasRef,
            downloadTicketTap,
            canvasSize,
            convertCanvasToPDF
        }
    }
}
</script>

<style lang="scss">
.download-ticket {
    position: relative;
    .download-ticket-button {
        color: #ff632d;
        text-decoration: underline;
        cursor: pointer;
    }
    canvas {
        position: absolute;
        left: 10000px;
    }

    .canvas-1 {
        width: 210mm !important;  /* A4纸的宽度 */
        height: 297mm !important; /* A4纸的高度 */
        border: 1px solid #000 !important; /* 显示边框，可选 */
    }
}
</style>