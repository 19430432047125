let type = 'prod'
let env = require(`../env/${type}.js`);
let base = env.default
let host = base.host
let api = base.api
let url = host + api + '/ycsj_examination_system/api'
let competitionController = url + '/CompetitionController'
let levelController = url + '/LevelController'
let userInfoController = url + '/UserInfoController'
let questionInfoConfig = url + '/QuestionInfoConfig'
let orderInfoController = url + '/OrderInfoController'
let refundController = url + '/RefundController'
let importConfig = url + '/ImportConfig'

let config = {
    type,
    url,
    base,
    host,
    userInfoController: userInfoController,
    identityCheck: userInfoController + '/identityCheck',
    modifyUserInfo: userInfoController + '/modifyUserInfo',
    uploadFile: userInfoController + '/uploadFile',
    modifyPassWord: userInfoController + '/modifyPassWord',
    downloadInfo: userInfoController + '/downloadInfo',
    userReplacetel: userInfoController + '/userReplacetel',
    exhibition: competitionController + '/Exhibition',
    exhibitionApplication: competitionController + '/ExhibitionApplication',
    exhibitionConfirMenter: competitionController + '/ExhibitionConfirMenter',
    saveExhibitionApplication: competitionController + '/saveExhibitionApplication',
    upDetermineInfo: competitionController + '/upDetermineInfo',
    queryUserRecord: competitionController + '/queryUserRecord',
    queryExamInfo: competitionController + '/queryExamInfo',
    queryTimeInfo: competitionController + '/queryTimeInfo',
    queryAchievement: competitionController + '/queryAchievement',
    queryAdmissionNumber: competitionController + '/queryAdmissionNumber',
    recordEnterExamTime: competitionController + '/recordEnterExamTime',
    levelDistributionDetails: levelController + '/levelDistributionDetails',
    lecelIntroduceDetails: levelController + '/lecelIntroduceDetails',
    questionInfoConfig: questionInfoConfig,
    examInfoConfig: url + '/ExamInfoConfig',
    aliPay: url + '/alipay/pay',
    wxPay: url + '/wx-pay/nativePay',
    selectOrderInfo: orderInfoController + '/selectOrderInfo',
    deleteOrderInfo: orderInfoController + '/deleteOrderInfo',
    addRefundOrderInfo: refundController + '/addRefundOrderInfo',
    // 考试录屏上传
    importUplpadFile:  importConfig + '/uploadFile',
    stsServiceSample:  importConfig + '/stsServiceSample'
}

export default config