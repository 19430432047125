<template>
  <div class="step-review">
    <div class="review ub ub-ver ub-ac" v-if="dataInfo && dataInfo.applicationStatus == -1">
        <h2 class="ub ub-pc ub-ac"><span class="ub ub-ac ub-pc"><el-icon><WarningFilled /></el-icon></span>您的报名申请已提交，请等待审核并完成缴费</h2>
        <div class="ub ub-ver ub-ac review-info" v-if="dataInfo">
            <div class="info-cell">报考科目：{{ dataInfo.competitionName}}</div>
            <div class="info-cell">考点名称：{{ dataInfo.keynoteName }}</div>
            <div class="info-cell">考试时间：{{ dataInfo.time }}</div>
        </div>
        <div class="review-tip">
            审核大概需要1~3个工作日，学生可以选择在【{{dataInfo.enrollStartTime}}至{{dataInfo.EnrollEndTime}}】自行完成报名缴费。 如遇任何报名问题，学生可联系组委会。
        </div>
        <el-button class="" type="primary" round @click="submitBack">返回</el-button>
    </div>
    <div class="review-fail ub ub-ver ub-ac" v-if="dataInfo && dataInfo.applicationStatus == 0">
        <h2 class="ub ub-pc ub-ac"><span class="ub ub-ac ub-pc"><el-icon><WarningFilled /></el-icon></span>您的资料审核未通过</h2>
        <div class="review-tip">
            请在报名时间截止前重新完善报考信息并提交审核完成缴费
        </div>
        <el-button class="" type="primary" round @click="submitPrev">返回完善信息页</el-button>
    </div>

    <div class="review-fail ub ub-ver ub-ac" v-if="dataInfo && (dataInfo.applicationStatus == 1 || dataInfo.applicationStatus == 2)">
        <h2 class="ub ub-pc ub-ac"><span class="ub ub-ac ub-pc"><el-icon><Select /></el-icon></span>您的资料审核已通过</h2>
        <div class="review-tip">
            请完成支付
        </div>
        <el-button class="" type="primary" round @click="submitNext">下一步</el-button>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import store from '@/store';
import { exhibitionConfirMenter } from '@/http/api'
import { useRouter } from 'vue-router';
export default {
    props: {
        competitionId: {
            type: Number,
            default: null
        },
        currentNum: {
            type: Number,
            default: null
        }
    },
    setup(props, {emit}) {
        const router = useRouter()
        const userAccount = computed(() => store.getters.userAccount)
        const dataInfo = ref(null) 
        const reviewState = ref(1)
        /**
         * 使用 watch 监听 props 的变化
         */
        watch(props, (newProps) => {
            if (newProps.currentNum == 3) {
                getData()
            }

            /**
             * 获取报名数据
             */
            function getData() {
                exhibitionConfirMenter({
                    userId: userAccount.value.userId,
                    competitionId: props.competitionId
                }).then((res) => {
                    dataInfo.value = res.data
                }).catch((err) => {
                })
            }
        })
        
        function submitPrev() {
            emit('stepChange', 1)
        }
        function submitNext() {
            emit('stepChange', 4)
        }
        function submitBack() {
            router.go(-1)
        }
        return {
            reviewState,
            dataInfo,
            submitPrev,
            submitNext,
            submitBack
        }
    }
}
</script>

<style lang="scss">
.step-review {
    width: 682px;
    margin: 0 auto;
    h2 {
        text-align: center;
        font-weight: bold;
        span {
            width: 47px;
            height: 47px;
            line-height: 47px;
            font-size: 40px;
            font-weight: bold;
            border-radius: 50%;
            color: #ff632d;
            margin-right: 10px;
        }
    }
    .el-button {
        height: 40px;
        width: 241px;
        margin-top: 87px;
        a {
            color: #ffffff;
        }
    }
    .review {
        .review-info {
            display: inline-block;
            margin: 20px 0;
            .info-cell {
                margin-bottom: 25px;
                color: #707070;
            }
        }
        .review-tip {
            color: #0e9bee;
            display: inline-block;
        }
    }

    .review-fail {
        .review-tip {
            color: #666666;
            display: inline-block;
         }
     }
}
</style>