<template>
  <div class="step-registration-success ub ub-ver ub-ac ">
    <h2 class="ub ub-pc ub-ac">报名信息确认完成！<span class="ub ub-ac ub-pc"><el-icon><Select /></el-icon></span></h2>
    <div class="ub ub-ver" v-if="dataInfo">
        <div class="info-cell">报考科目：{{ dataInfo.competitionName
 }}</div>
        <div class="info-cell">考点名称：{{ dataInfo.keynoteName }}</div>
        <div class="info-cell">考试时间：{{ dataInfo.time }}</div>
        <download-ticket :competitionId="competitionId" :type="1">下载准考证</download-ticket>
    </div>
    <el-button class="" type="primary"  round @click="submitBack">返回</el-button>

  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import store from '@/store';
import { exhibitionConfirMenter } from '@/http/api'
import DownloadTicket from '@/pages/components/DownloadTicket.vue';
import { useRouter } from 'vue-router';

export default {
  components: { DownloadTicket },
  props: {
    competitionId: {
        type: Number,
        default: null
    },
    currentNum: {
      type: Number,
      default: null
    }
  },
  setup(props) {
    const router = useRouter()
    const dataInfo = ref(null)
    const userAccount = computed(() => store.getters.userAccount)
    /**
     * 使用 watch 监听 props 的变化
     */
     watch(props, (newProps) => {
      if (newProps.currentNum == 5) {
        getData()
      }

      /**
       * 获取报名数据
       */
      function getData() {
        exhibitionConfirMenter({
            userId: userAccount.value.userId,
            competitionId: props.competitionId
        }).then((res) => {
            dataInfo.value = res.data
        }).catch((err) => {
        })
      }
    });

    function submitBack() {
      router.go(-1)
    }
    return {
      dataInfo,
      submitBack
    }
  }
}
</script>

<style lang="scss">
.step-registration-success {
  h2 {
    text-align: center;
    font-weight: bold;
    span {
      width: 47px;
      height: 47px;
      line-height: 47px;
      font-size: 32px;
      font-weight: bold;
      background-color: #0e9bee;
      border-radius: 50%;
      color: #fff;
      margin-left: 10px;
    }
  }
  .info-cell {
    margin-bottom: 25px;
    color: #707070;
  }
  .download-ticket {

  }
  .el-button {
    height: 40px;
    width: 241px;
    margin-top: 87px;
     a {
      color: #ffffff;
     }
  }
}
</style>