<template>
  <div class="grading-test-introduce">
    <second-model-2>
        <template v-slot:model-content>
            <second-model-title :breadcrumbList="breadcrumbList"></second-model-title>
            <div v-loading="loading">
                <introduce-cell v-for="(item) in dataList" :key="item.id" :item="item"></introduce-cell>
                <div v-if="!loading && dataList.length == 0">暂无数据</div>
            </div>
            <pagination-model :total="total" :size="num" @paginationChange="paginationChange"></pagination-model>
        </template>
    </second-model-2>
    <the-footer></the-footer>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import TheBanner from '@/components/TheBanner.vue'
import SecondModel2 from '../components/SecondModel2.vue'
import SecondModelTitle from '../components/SecondModelTitle.vue'
import IntroduceCell from './components/IntroduceCell.vue'
import PaginationModel from '../components/PaginationModel.vue'
import { lecelIntroduceDetails } from '@/http/api'
import TheFooter from '@/components/TheFooter.vue'

export default {
  components: { SecondModel2, TheBanner, SecondModelTitle, IntroduceCell, PaginationModel, TheFooter },
  setup() {
    const loading = ref(true)
    const breadcrumbList = reactive([{
      title: '考级介绍',
      path: ''
    }])
    const num = ref(20)
    const page = ref(1)
    const total = ref(0)
    const dataList = reactive([])
    onMounted(() => {
        getData()
    });
    /**
     * 获取数据
     */
     function getData() {
        lecelIntroduceDetails({
            num: num.value,
            page: page.value
        }).then((res) => {
            dataList.length = 0
            dataList.push(...res.data.list)
            total.value = res.data.size
            loading.value = false
        }).catch((err) => {
        })
     }

     /**
      * 分页
      */
     function paginationChange(pageNum) {
        page.value = pageNum
        // page.value = Number(page)
        getData()
     }
    return {
        loading,
        breadcrumbList,
        total,
        num,
        dataList,
        paginationChange
    }
  }
}
</script>

<style>

</style>