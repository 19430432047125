//导入发送请求的方法
// import { fetchFormDataPost, fetchGet } from "@/http/http";
import {importUplpadFile, stsServiceSample} from "@/http/api"
import { Base64 } from "./base64";
import { HMAC } from "./hmac";
import { cryptoUtil } from "./crypto";
import { SHA } from "./sha1";

//定义阿里云上传的oss配置信息
export const accessid = "LTAI5tEbwGD7qgTu2s2BSycw";
export const accesskey = "YcjV5OASeKi4K8MzofMytLPIR1SjHp";
export const host = "https://ycsj2.oss-cn-qingdao.aliyuncs.com";

export const headers = {
  // 指定Object的访问权限。
  "x-oss-object-acl": "private",
  // 指定Object的存储类型。
  "x-oss-storage-class": "Standard",
  // 指定服务器端加密方式。此处指定为OSS完全托管密钥进行加密（SSE-OSS）。
  "x-oss-server-side-encryption": "AES256",
};

const policyText = {
  expiration: "2025-01-01T12:00:00.000Z", //设置该Policy的失效时间，超过这个失效时间之后，就没有办法通过这个policy上传文件了
  conditions: [
    ["content-length-range", 0, 1048576000], // 设置上传文件的大小限制
  ],
};

const policyBase64 = Base64.encode(JSON.stringify(policyText));
const message = policyBase64;
const bytes = HMAC(SHA, message, accesskey, { asBytes: true });

//获取签名
export const signature = cryptoUtil.bytesToBase64(bytes);

/**
 * 直传文件的方法
 * blob视频流文件
 */
export const uploadFileTooss = (blob) => {
  let param = new FormData();
  // param.append("OSSAccessKeyId", accessid);
  // param.append("key", "abc/" + g_object_name);
  // param.append("policy", policyBase64);
  // param.append("success_action_status", "200");
  // param.append("signature", signature);

  // let file = new window.File([blob], `${Date.now()}_name.mp4`, {
  //   type: blob.type,
  // });
  let file = new window.File([blob], `test_name.mp4`, {
    type: blob.type,
  });
  param.append("file", file);
  // param.append("objectName", `${Date.now()}_name.mp4`);
  param.append("objectName", `test_name.mp4`);

  //host
  importUplpadFile(
    // "http://192.168.3.138:8088/ycsj_examination_system/api/ImportConfig/uploadFile",
    param
  )
    .then((res) => {
      console.log(`上传结果是:`, res);
    })
    .catch((err) => {
      console.log(`上传错误信息是:${err}`);
    });
};

/**
 * 获取阿里云临时凭证的方法
 */
export const getOssInfo = () => {
  return stsServiceSample(
  );
};

//保存视频到本地的方法
export const saveVideoToLocal = (blob) => {
  // 将录制的视频下载到本地;
  // const blob_down = new Blob(blob, { type: "video/webm" });
  // const blob = new Blob(sliceArr, { type: 'video/webm' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = `${Date.now()}_test.webm`;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 100);
};
