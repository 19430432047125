<template>
  <div class="step-improve-userinfo">
    <form-model>
        <el-form :model="formData" :rules="rules" ref="formRef" label-width="200px" label-position="top">
            <div class="form-model-item ub ub-be">
                <el-form-item prop="imageUrl" class="upload-image" label="免冠照">
                    <div class="ub ub-f1 ub-be">
                        <div class="ub ub-ver">
                            <el-upload
                                class="avatar-uploader"
                                :action="config.url + '/UserInfoController/uploadFile'"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload"
                            >
                                <img v-if="formData.imageUrl" :src="formData.imageUrl" class="avatar" />
                                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                            </el-upload>
                            <div class="upload-image-tip">标准2寸(蓝底或红底)，jpg格式，大小1M以内，分辨率范围在300~400之间</div>
                        </div>
                        <example-avatar></example-avatar>
                    </div>
                   
                </el-form-item>
            </div>
            <div class="form-model-item ub ub-be">
                <el-form-item prop="sex" label="请选择性别">
                    <el-select v-model="formData.sex" disabled @change="handlePointChange" class="m-2" placeholder="请选择性别" size="large"  no-data-text="暂无数据">
                        <el-option
                        v-for="item in genders"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item prop="schoolName" label="就读学校">
                    <el-input v-model="formData.schoolName" placeholder="就读学校（请输入所读的公立学校名称）" clearable />
                </el-form-item>
            </div>
            <div class="form-model-item ub ub-be">
                <div class="ub form-address-item ub-be">
                    <el-form-item prop="province" label="所在地">
                        <el-select v-model="formData.province" clearable class="m-2" @change="handleProvinceChange" placeholder="省" size="large"  no-data-text="暂无数据">
                            <el-option
                                v-for="item in provinces"
                                :key="item.name"
                                :label="item.name"
                                :value="item.name"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="city" label="市">
                        <el-select v-model="formData.city" clearable class="m-2" @change="handleCityChange" placeholder="市" size="large"  no-data-text="暂无数据">
                            <el-option
                            v-for="item in cities"
                                :key="item.name"
                                :label="item.name"
                                :value="item.name"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="district" label="区">
                        <el-select v-model="formData.district" clearable class="m-2" placeholder="区" size="large"  no-data-text="暂无数据">
                            <el-option
                                v-for="item in districts"
                                :key="item.name"
                                :label="item.name"
                                :value="item.name"
                            />
                        </el-select>
                    </el-form-item>
                </div>
                <el-form-item prop="className" label="年级">
                    <el-select v-model="formData.className" class="m-2" placeholder="年级" size="large"  no-data-text="暂无数据">
                        <el-option
                        v-for="item in classList"
                            :key="item"
                            :label="item"
                            :value="item"
                        />
                    </el-select>
                </el-form-item>
            </div>
            <div class="form-model-item ub ub-be">
                <el-form-item prop="emergencyContactName" label="紧急联系人">
                    <el-input v-model="formData.emergencyContactName" placeholder="紧急联系人姓名" clearable />
                </el-form-item>
                <el-form-item prop="emergencyContactPhone" label="紧急联系人电话">
                    <el-input v-model="formData.emergencyContactPhone" placeholder="紧急联系人电话" clearable />
                </el-form-item>
            </div>
            <div class="ub">
                <el-button class="save-button" type="primary" round @click="submitForm">保存</el-button> 
            </div>
        </el-form>
    </form-model>
  </div>
  <el-dialog
        v-model="tutorialDialogVisible"
        title=""
        width="50%"
        destroy-on-close
        center
        custom-class="tutorial-dialog"
        @close="cancelTipDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        append-to-body
        >

        <el-carousel class="tutoria-swiper" :autoplay="false">
          <el-carousel-item v-for="item in tutorialStep" :key="item">
            <div class="model-title">{{ item.title }}</div>
            <el-image :src="item.src" fit="cover"/>
          </el-carousel-item>
        </el-carousel>
        <template #footer>
            <span class="dialog-footer">
                <!-- <el-button @click="tutorialDialogVisible = false">关闭</el-button> -->
            </span>
        </template>
    </el-dialog>
</template>

<script>
import { ref, reactive, onMounted, nextTick, computed, watch } from 'vue'
import config from '@/http/config'
import FormModel from "@/pages/components/FormModel.vue";
import areaData from '@/utils/pca-code.json'; // 导入省市区数据的 JSON 文件
import data from '@/utils/data'
import commmonJs from '@/utils/common'
import { useStore } from 'vuex';
import { uploadFile, modifyUserInfo } from '@/http/api'
import { ElMessage } from 'element-plus';
import { AuthService } from '@/utils/AuthService';
import { useRouter } from 'vue-router';
import ExampleAvatar from '@/components/ExampleAvatar.vue';


export default {
  components: { FormModel, ExampleAvatar }, 
  props: {
    competitionId: {
        type: Number,
        default: null
    },
    currentNum: {
        type: Number,
        default: null
    }
  },
  setup(props, {emit}) {
    const classList = ref(data.classList)
    const router = useRouter()
    const store = useStore()
    const userAccount = computed(() => store.getters.userAccount)
    const genders = ref(data.gender)
    const provinces = reactive([]);
    const cities = reactive([]);
    const districts = reactive([]);
    const addressArr = userAccount.value.city.split('-')
    const formData = ref({
        imageUrl: userAccount.value.userImg,
        sex: userAccount.value.sex,
        className: userAccount.value.userGrade,
        schoolName: userAccount.value.attendingCampus,
        province: addressArr.length > 0 ? addressArr[0] : '',
        city: addressArr.length > 0 ? addressArr[1] : '',
        district: addressArr.length > 0 ? addressArr[2] : '',
        emergencyContactName: userAccount.value.contactName,
        emergencyContactPhone: userAccount.value.contactTel,
    })
    const imageData = ref('')
    const imageName = ref('')

     /**
     * 使用 watch 监听 props 的变化
     */
     watch(props, (newProps) => {
      if (newProps.currentNum == 1) {
        if (!props.competitionId) {
            router.replace(`/evaluation`)
        }
      }
    });

    onMounted(() => {
      // 在组件挂载后，将 JSON 文件的内容赋值给 provinces
      provinces.push(...areaData);
      if(formData.value.province) {
        let newCities = provinces.find((obj) => obj.name === formData.value.province).children
        cities.push(...newCities);
      }
      if (formData.value.city) {
        let newDistricts = cities.find((obj) => obj.name === formData.value.city).children
        districts.push(...newDistricts);
      }
    });

    /**
     * 选择省份
     * @param {*} value 
     */
    const handleProvinceChange = (value) => {
      // 在这里根据选择的省份 value 获取对应的城市数据，并更新 cities 变量
      cities.length = 0
      if (value) {
        let newCities = provinces.find((obj) => obj.name === value).children
        cities.push(...newCities);
      }
      formData.value.province = value;
      formData.value.city = ''; // 重置已选城市
      formData.value.district = ''; // 重置已选区县
    }; 

    /**
     * 选择区县
     * @param {x} value 
     */
    const handleCityChange = (value) => {
      // 在这里根据选择的城市 value 获取对应的区县数据，并更新 districts 变量
      // 假设 getDistrictByCityValue 是一个根据城市 value 获取区县数据的方法
      districts.length = 0
      if (value) {
        let newDistricts = cities.find((obj) => obj.name === value).children
        districts.push(...newDistricts);
      }
      formData.value.city = value;
      formData.value.district = ''; // 重置已选区县
    };

    const rules = ref({
        province: [
            { required: true, message: '请选择地址', trigger: 'change' },
        ],
        city: [
            { required: true, message: '请选择地址', trigger: 'change' },
        ],
        district: [
            { required: true, message: '请选择地址', trigger: 'change' },
        ],
        schoolName: [
            { required: true, message: '请输入学校名称', trigger: 'blur' }
        ],
        emergencyContactName: [
            { required: true, message: '紧急联系人姓名', trigger: 'blur' },
        ],
        emergencyContactPhone: [
            { required: true, message: '紧急联系人电话', trigger: 'blur' },
            { validator: checkPhoneNumber, trigger: 'blur' },
        ],
        
    });

    const formRef = ref(null); // 创建对 form 元素的引用
        
    // methods
    const submitForm = async () => {
        // 表单提交逻辑
        // ...

        // 等待 nextTick 后再执行校验操作
        await nextTick();
        // 校验表单
        if (formRef.value) {
            formRef.value.validate(valid => {
                if (valid) {
                    // 校验通过，执行表单提交逻辑
                    // ...
                    imageUploadhttp()
                }
            });
        }
    };

    /**
     * 验证手机号
     * @param {*} rule 
     * @param {*} value 
     * @param {*} callback 
     */
    function checkPhoneNumber(rule, value, callback) {
        if (!commmonJs.validatePhoneNumber(value)) {
            callback(new Error('请输入正确的手机号'));
        } else {
            callback()
        }
    }

    /**
     * 上传图片
     */
     function imageUploadhttp() {
        if (!imageData.value) {
            imageName.value = userAccount.value.userImg
            editUserInfoHttp()
            return
        }
        uploadFile({
            file: imageData.value,
            fileType: 1
        }).then((res) => {
            imageName.value = res.data
            editUserInfoHttp()
        }).catch((err) => {
        })
     }

    function editUserInfoHttp() {
        let userImgArr = imageName.value.split('/')
        let userImg = userImgArr[userImgArr.length - 1]
        modifyUserInfo({
            attendingCampus: formData.value.schoolName,
            city: `${formData.value.province}-${formData.value.city}-${formData.value.district}`,
            sex: userAccount.value.sex, // 0位置 1男 2女
            state: 1,
            userGrade: formData.value.className,
            userId: userAccount.value.userId,
            userImg: userImg,
            userMailbox: userAccount.value.userMailbox,
            userParentName: userAccount.value.userParentName,
            contactName: formData.value.emergencyContactName,
            contactTel: formData.value.emergencyContactPhone,
            competitionId: props.competitionId
        }).then((res) => {

            ElMessage({
                message: '修改成功',
                type: 'success',
            })
            userAccount.value.attendingCampus = formData.value.schoolName
            userAccount.value.city = `${formData.value.province}-${formData.value.city}-${formData.value.district}`
            userAccount.value.userGrade = formData.value.className,
            userAccount.value.userImg = imageName.value,
            userAccount.value.contactName = formData.value.emergencyContactName
            userAccount.value.contactTel = formData.value.emergencyContactPhone
            AuthService.login(userAccount.value)
            store.commit('setUserAccount', userAccount.value)
            setTimeout(() => {
                emit('stepChange', 2)
            }, 300)
        }).catch((err) => {
        })
    }

    /**
     * 上传头像成功
     */
     function handleAvatarSuccess(res, file) {
        imageData.value = file.raw
        formData.value.imageUrl = URL.createObjectURL(file.raw);
    }

    /**
     * 上传头像前限制
     */
    function beforeAvatarUpload(rawFile) {
        // 获取上传文件的宽度和高度
        return new Promise((resolve, reject) => {
            if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
                ElMessage.error('请上传png或者jpg格式图片！')
                reject()
            } else if (rawFile.size / 1024 / 1024 > 2) {
                ElMessage.error('图片大小为2M以内！')
                reject()
            }
            const img = new Image();
            img.src = window.URL.createObjectURL(rawFile);
            img.onload = () => {
                const width = img.width;
                const height = img.height;
                
                if (width === 413 && height === 579) {
                    resolve(rawFile); // 符合尺寸要求，允许上传
                } else {
                    ElMessage.error('上传图片的尺寸必须为2寸（413x579像素）')
                    reject();
                }
            };
        });
    }

    return {
        config,
        userAccount,
        provinces,
        cities,
        districts,
        genders,
        classList,
        formData,
        handleProvinceChange,
        handleCityChange,
        rules,
        formRef,
        submitForm,
        handleAvatarSuccess,
        beforeAvatarUpload,
    }
  }
}
</script>

<style lang="scss">
.step-improve-userinfo {
    .form-model {
        .form-model-item {
            .el-form-item {
                width: 414px;
                margin-right: 8px;
            }

            .form-address-item {
                width: 414px;
                .el-form-item { 
                    width: 130px !important;
                }
                .el-form-item:last-child {
                    margin-right: 0 !important;
                }
            }

            .upload-image {
                display: flex;
                align-items: center;
                margin-bottom: 48px;
                width: 100% !important;
                .el-form-item__label {
                    margin-bottom: 0 !important;
                }
                .avatar-uploader {
                    img {
                        max-width: 112px;
                    }
                    .avatar-uploader-icon {
                        width: 37px;
                        height: 37px;
                        border: 3px dashed #999999;
                        border-radius: 5px;
                        color: #999999;
                        font-weight: bold;
                        font-size: 18px;
                    }
                }
                .upload-image-tip {
                    color: #a0a0a0;
                }
            }
        }   
        .el-button {
            margin-top: 67px;
        }
    }
}

</style>