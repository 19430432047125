<template>
  <div class="examination-place">
    <second-model-2>
        <template v-slot:model-content>
            <second-model-title :breadcrumbList="breadcrumbList"></second-model-title>
            <form-model class="ub ub-pc">
                <el-form class="ub" :model="formData" ref="formRef">
                    <el-form-item>
                        <el-select v-model="formData.province" clearable class="m-2" @change="handleProvinceChange" placeholder="省份" size="large" no-data-text="暂无数据">
                            <el-option
                                v-for="item in provinces"
                                :key="item.code"
                                :label="item.name"
                                :value="item.name"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="formData.city" clearable class="m-2" @change="handleCityChange" placeholder="城市" size="large" no-data-text="暂无数据">
                            <el-option
                            v-for="item in cities"
                                :key="item.code"
                                :label="item.name"
                                :value="item.name"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="formData.district" clearable class="m-2" placeholder="区县" size="large" no-data-text="暂无数据">
                            <el-option
                                v-for="item in districts"
                                :key="item.code"
                                :label="item.name"
                                :value="item.name"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="formData.placeName" placeholder="考点名称" clearable />
                    </el-form-item>
                    <el-form-item >
                        <el-input v-model="formData.serverCenter" placeholder="服务中心" clearable />
                    </el-form-item>
                </el-form>
                <el-button class="search-button" type="primary" round @click="submitForm">搜索</el-button>
            </form-model>
            <el-table :data="tableData" style="width: 100%" border   empty-text="暂无数据">
                <el-table-column prop="id" label="索引" width="66" />
                <el-table-column prop="province" label="省份" width="105" />
                <el-table-column prop="city" label="城市" width="105" />
                <el-table-column prop="county" label="区县" width="105" />
                <el-table-column prop="levelPointsName" label="考点名称" width="191" />
                <el-table-column prop="contacts" label="联系人" width="105" />
                <el-table-column prop="tel" label="手机" width="150" />
                <el-table-column prop="serviceCenter" label="所属服务中心" />
            </el-table>
            <pagination-model :total="total" :size="num" @paginationChange="paginationChange"></pagination-model>
        </template>
    </second-model-2>
    <the-footer></the-footer>
  </div>
</template>

<script>
import { ref, reactive, onMounted, nextTick } from 'vue'
import TheBanner from '@/components/TheBanner.vue'
import SecondModel2 from '../components/SecondModel2.vue'
import SecondModelTitle from '../components/SecondModelTitle.vue'
import FormModel from '../components/FormModel.vue'
import areaData from '@/utils/pca-code.json'; // 导入省市区数据的 JSON 文件
import { levelDistributionDetails } from '@/http/api'
import PaginationModel from '../components/PaginationModel.vue'
import TheFooter from '@/components/TheFooter.vue'
// import { ElMessage } from 'element-plus';
export default {
  components: { SecondModel2, TheBanner, SecondModelTitle, FormModel, PaginationModel, TheFooter },
  setup() {
    const breadcrumbList = reactive([{
      title: '考点分布',
      path: ''
    }])
    const num = ref(20)
    const page = ref(1)
    const total = ref(0)
    const provinces = reactive([]);
    const cities = reactive([]);
    const districts = reactive([]);
    const formData = ref({
        province: '',
        city: '',
        district: '',
        placeName: '',
        serverCenter: ''
    })
    /* const tableData = [
        {   index: 1,
            province: '湖北省',
            city: '武汉市',
            district: '江汉区',
            placeName: '武汉万松园小学',
            contacts: '张三',
            tel: '15888888888',
            serverCenter: 'No. 189, Grove St, Los Angeles',
        }
    ] */
    const tableData = reactive([])

    onMounted(() => {
      // 在组件挂载后，将 JSON 文件的内容赋值给 provinces
      provinces.push(...areaData);
      getDataHttp()
    });

    const formRef = ref(null); // 创建对 form 元素的引用
        
    // methods
    const submitForm = async () => {
        // 表单提交逻辑
        // ...

        // 等待 nextTick 后再执行校验操作
        await nextTick();
        // 校验表单
        if (formRef.value) {
            formRef.value.validate(valid => {
                if (valid) {
                    // 校验通过，执行表单提交逻辑
                    // ...
                    getDataHttp()
                }
            });
        }
    };

    /**
     * 获取数据
     */
     function getDataHttp() {
        levelDistributionDetails({
            province: formData.value.province,
            city: formData.value.city,
            county: formData.value.district,
            levelPointsName: formData.value.placeName,
            serviceCenter: formData.value.serverCenter,
            num: num.value,
            page: page.value
        }).then((res) => {
            tableData.length = 0
            tableData.push(...res.data.list)
            total.value = res.data.size
        }).catch((err) => {
        })
     }

     /**
      * 分页
      */
      function paginationChange(pageNum) {
        page.value = pageNum
        // page.value = Number(page)
        getDataHttp()
     }

    /**
     * 选择省份
     * @param {*} value 
     */
     const handleProvinceChange = (value) => {
      // 在这里根据选择的省份 value 获取对应的城市数据，并更新 cities 变量
      cities.length = 0
      if (value) {
        let newCities = provinces.find((obj) => obj.name === value).children
        cities.push(...newCities);
      }
      formData.value.province = value;
      formData.value.city = ''; // 重置已选城市
      formData.value.district = ''; // 重置已选区县
    }; 

    /**
     * 选择区县
     * @param {x} value 
     */
     const handleCityChange = (value) => {
      // 在这里根据选择的城市 value 获取对应的区县数据，并更新 districts 变量
      // 假设 getDistrictByCityValue 是一个根据城市 value 获取区县数据的方法
      districts.length = 0
      if (value) {
        let newDistricts = cities.find((obj) => obj.name === value).children
        districts.push(...newDistricts);
      }
      formData.value.city = value;
      formData.value.district = ''; // 重置已选区县
    };
    return {
        breadcrumbList,
        num,
        total,
        provinces,
        cities,
        districts,
        formRef,
        formData,
        submitForm,
        paginationChange,
        handleProvinceChange,
        handleCityChange,
        tableData
    }
  }
}
</script>

<style lang="scss">
.examination-place {
    .form-model {
        .el-form {
            .el-select {
                width: 130px;
                .el-input {
                    width: 100%;
                }
            }
            .el-input {
                width: 295px;
            }
        }
        .search-button.is-round {
            border-radius: 7px;
            height: 40px;
            width: 75px;
        }
    }
    .el-table {
        // border-radius: 5px;
        .el-table__header-wrapper {
            .el-table__header {
                th {
                    background-color: #f1f1f1;
                    border-right-color: #d2d2d2;
                    border-right-style: dashed;
                    text-align: center;
                }
            }
        }
        .el-table__body-wrapper {
            .el-table__body {
                td {
                    border-right-color: #d2d2d2;
                    border-right-style: dashed;
                    text-align: center;
                }
            }
        }
    }
    
}
</style>