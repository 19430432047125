<template>
    <div class="login-forget-password-model">
        <el-form :model="formData" :rules="rules" ref="formRef" label-width="100px">
            <el-form-item prop="phoneNumber" >
                <el-input v-model="formData.phoneNumber" clearable placeholder="请输入手机号" autocomplete="new-phoneNumber"></el-input>
            </el-form-item>
            <el-form-item class="code-form-item" prop="code">
                <el-input v-model="formData.code"  placeholder="请输入验证码"></el-input>
                <el-button link @click="sendCodeVerify" :disabled="codeButtonDisabled">{{ codeButtonDisabled ? codeTimeSecond + 's' : '发送验证码' }}</el-button>
            </el-form-item>
            <el-form-item prop="newPassword">
                <el-input v-model="formData.newPassword" type="password" placeholder="新密码（请输入8~16位长度密码）" autocomplete="new-password"></el-input>
            </el-form-item>
            <el-form-item prop="againNewPassword">
                <el-input v-model="formData.againNewPassword" type="password" placeholder="确认新密码"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button class="login-button" type="primary" size="large" @click="submitForm">立即登录</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { ref, nextTick } from 'vue';
import { ElMessage } from 'element-plus';
import commmonJs from '@/utils/common'
import { userInfoController } from '@/http/api'
export default {
    setup(props, {emit}) {
        const formRef = ref(null); // 创建对 form 元素的引用
        const formData = ref({
                phoneNumber: '',
                code: '',
                newPassword: '',
                againNewPassword: ''
            });
        const codeButtonDisabled = ref(false)
        const codeButtonText = ref('')
        const codeTimeSecond = ref(200)
        const timer = ref(null)

        const rules = ref({
            phoneNumber: [
                { required: true, message: '请输入手机号', trigger: 'blur' },
                { validator: checkPhoneNumber, trigger: 'blur' },
            ],
            code: [
                { required: true, message: '请输入验证码', trigger: 'blur' },
                { min: 6, max:6, message: '请输入6位数验证码', trigger: 'blur' },
            ],
            newPassword: [
                { required: true, message: '请输入密码', trigger: 'blur' },
                { min: 8, max:16, message: '请输入8~16位数字或字母组合密码', trigger: 'blur' },
                { validator: checkPassword, trigger: 'blur' },
            ],
            againNewPassword: [
                { required: true, message: '请再次输入密码', trigger: 'blur' },
                { validator: checkAgainPassword, trigger: 'blur' },
            ]
        });
        // methods
        const submitForm = async () => {
            // 表单提交逻辑
            // ...

            // 等待 nextTick 后再执行校验操作
            await nextTick();

            // 校验表单
            if (formRef.value) {
                formRef.value.validate(valid => {
                if (valid) {
                    // 校验通过，执行表单提交逻辑
                    // ...
                    resetPasswordHttp()
                }
                });
            }
        };

        // 重置密码
        function resetPasswordHttp() {
            userInfoController({
                type: 2,
                tel: formData.value.phoneNumber,
                code: formData.value.code,
                password: formData.value.newPassword
            }).then((res) => {
                initTimer()
                ElMessage({
                    message: '重置成功',
                    type: 'success',
                })
                emit('forgetPassword', 1)
            }).catch((err) => {
            })
        }

        /**
         * 验证手机号
         * @param {*} rule 
         * @param {*} value 
         * @param {*} callback 
         */
         function checkPhoneNumber(rule, value, callback) {
            if (!commmonJs.validatePhoneNumber(value)) {
                callback(new Error('请输入正确的手机号'));
            } else {
                callback()
            }
        }

        /**
         * 验证密码
         * @param {*} rule 
         * @param {*} value 
         * @param {*} callback 
         */
         function checkPassword(rule, value, callback) {
            if (!commmonJs.isAlphaNumericPhoneNumber(value)) {
                callback(new Error('请输入8~16位数字或字母组合密码'));
            } else {
                callback()
            }
        }

        /**
         * 验证二次密码
         * @param {*} rule 
         * @param {*} value 
         * @param {*} callback 
         */
         function checkAgainPassword(rule, value, callback) {
            if (!commmonJs.isAlphaNumericPhoneNumber(value)) {
                callback(new Error('请输入8~16位数字或字母组合密码'));
            } else if (value != formData.value.newPassword) {
                callback(new Error('密码不一致，请再次输入'));
            } else {
                callback()
            }
        }

        // 发送验证码验证
        function sendCodeVerify() {
            formRef.value.validateField('phoneNumber', (valid) => {
                if (valid) {
                    sendCodeHttp()
                } else {
                    // 手机号验证不通过，可以在这里处理错误提示等逻辑
                }
            });
        }

        /**
         * 发送验证码
         */
         function sendCodeHttp() {
            userInfoController({
                type: 1,
                tel: formData.value.phoneNumber,
                msgType: 1
            }).then((res) => {
                ElMessage({
                    message: '发送成功',
                    type: 'success',
                })
                timeCountDown()
            }).catch((err) => {
            })
        }

        /**
         * 倒计时
         */
        function timeCountDown() {
            codeButtonDisabled.value = true;
            if (codeTimeSecond.value === 0) {
                initTimer()
            } else {
                codeTimeSecond.value--;
                timer.value = setTimeout(() => {
                    timeCountDown();
                }, 1000);
            }
        }

        /**
         * 初始化倒计时
         */
        function initTimer() {
            codeTimeSecond.value = 300;
            codeButtonDisabled.value = false;
            clearInterval(timer.value);
        }
        return {
            formRef,
            formData,
            codeButtonDisabled,
            codeButtonText,
            codeTimeSecond,
            rules,
            submitForm,
            sendCodeVerify
        };
    }
}
</script>

<style lang="scss">
.login-forget-password-model {
    .code-form-item {
        position: relative;
        .el-form-item__content {
            .el-input {
                .el-input__inner {
                    padding-right: 100px !important;
                }
            }
            .el-button {
                position: absolute;
                right: 10px;
            }   
        }
    }
}
</style>