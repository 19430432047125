<template>
    <div class="login-password-model">
        <el-form :model="formData" :rules="rules" ref="formRef" label-width="100px">
            <el-form-item prop="phoneNumber" >
                <el-input v-model="formData.phoneNumber" clearable placeholder="请输入登录手机号" autocomplete="new-phoneNumber"></el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input v-model="formData.password" clearable type="password" placeholder="请输入密码" autocomplete="new-password" ></el-input>
            </el-form-item>

            <div class="forget-password"><span @click="forgetPassword">忘记密码？</span></div>

            <el-form-item>
                <el-button class="login-button" type="primary" size="large" @click="submitForm">立即登录</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { ref, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import commmonJs from '@/utils/common'
import { ElMessage } from 'element-plus';
import { userInfoController } from '@/http/api'
import { AuthService } from '@/utils/AuthService';

export default {
    setup(props, {emit}) {
        const store = useStore();
        const router = useRouter();
        const formRef = ref(null); // 创建对 form 元素的引用
        const formData = ref({
            phoneNumber: '',
                password: '',
            });

        const rules = ref({
            phoneNumber: [
                { required: true, message: '请输入手机号', trigger: 'blur' },
                { validator: checkPhoneNumber, trigger: 'blur' },
            ],
            password: [
                { required: true, message: '请输入密码', trigger: 'blur' },
                { min: 8, max: 16, message: '请输入8~16位长度密码', trigger: 'blur' },
            ],
        });
        // methods
        const submitForm = async () => {
            // 表单提交逻辑
            // ...

            // 等待 nextTick 后再执行校验操作
            await nextTick();

            // 校验表单
            if (formRef.value) {
                formRef.value.validate(valid => {
                if (valid) {
                    // 校验通过，执行表单提交逻辑
                    // ...
                    loginHttp()
                }
                });
            }
        };
        const forgetPassword = () => {
            emit('forgetPassword')
        };

        /**
         * 验证手机号
         * @param {*} rule 
         * @param {*} value 
         * @param {*} callback 
         */
         function checkPhoneNumber(rule, value, callback) {
            if (!commmonJs.validatePhoneNumber(value)) {
                callback(new Error('请输入正确的手机号'));
            } else {
                callback()
            }
        }

        // 登录
        function loginHttp() {
            userInfoController({
                type: 5,
                tel: formData.value.phoneNumber,
                password: formData.value.password
            }).then((res) => {
                ElMessage({
                    message: '登录成功',
                    type: 'success',
                })
                AuthService.login(res.data)
                store.commit('setUserAccount', res.data);
                const redirectTo = router.currentRoute.value.query.redirect || '/'; // 如果没有重定向信息，默认回到首页
                router.push(redirectTo);
            }).catch((err) => {
            })
        }
        // function checkUsername (rule, value, callback) {
        //     // 自定义用户名校验规则
        //     if (value !== 'admin') {
        //         callback(new Error('用户名必须为 admin'));
        //     } else {
        //         callback();
        //     }
        // }
        return {
            formRef,
            formData,
            rules,
            submitForm,
            forgetPassword,
        };
    }
}
</script>

<style lang="scss">
.login-password-model {
    .el-form {
        .forget-password {
            text-align: right;
            font-size: 14px;
            margin-bottom: 20px;
            cursor: pointer;
        }
    }
}
</style>