<template>
  <div class="application-record">
    <second-model-title :breadcrumbList="breadcrumbList" :type="2"></second-model-title>
    <evaluation-content-model :type="2"></evaluation-content-model>
  </div>
</template>

<script>
import { reactive } from 'vue'
import SecondModelTitle from '../components/SecondModelTitle.vue'
import EvaluationContentModel from '../evaluation/components/EvaluationContentModel.vue'
export default {
  components: { SecondModelTitle, EvaluationContentModel },
  setup() {
    const breadcrumbList = reactive([{
      title: `报考记录`,
      path: ''
    }])

    return {
        breadcrumbList,
    }
  }
}
</script>

<style lang="scss">
  .application-record {
    .record-content {
      min-height: 350px;
    }
  }
</style>