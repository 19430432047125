<template>
  <el-pagination 
    class="pagination-model ub ub-pc" 
    background 
    layout="prev, pager, next" 
    prev-text="上一页" 
    next-text="下一页"
    :total="total" 
    :page-size="size"
    @current-change="paginationChange"/>
</template>

<script>
export default {
    props: {
        total: { // 总条数
            type: Number,
            default: 0
        },

        size: { // 每页条数
            type: Number,
            default: 1
        },
        
        currPage: {
            type: Number,
            default: 1
        }
    },
    setup(props, {emit}) {
        function paginationChange(page) {
            emit('paginationChange', page)
        }
        return {
            paginationChange
        }
    }
}
</script>

<style lang="scss">
.pagination-model.is-background {
    width: 100%;
    padding: 100px 50px;
    box-sizing: border-box;
    button {
        background-color: #ffffff !important;
        border: 1px solid #4682fc;
        color: #4682fc;
    }
    button:disabled {
        color: #9cbbf8 !important;
        border-color: #9cbbf8;
    }
    .el-pager {
        .number {
            background-color: #ffffff;
            border: 1px solid #4682fc;
            color: #4682fc;
        }
        .number.is-active {
            background-color: #4682fc;
            color: #ffffff;
        }
    }
}
</style>