<template>
    <el-carousel class="the-benner">
      <el-carousel-item v-for="item in banners" :key="item" :style="{backgroundImage: 'url('+ item.img +')'}">
        
      </el-carousel-item>
    </el-carousel>
</template>

<script>
export default {
  setup() {
     const banners = [
      {
        img: require('../assets/image/banner1.jpg')
      },
      {
        img: require('../assets/image/banner2.jpg')
      },
      {
        img: require('../assets/image/banner3.jpg')
      },
      {
        img: require('../assets/image/banner4.jpg')
      }

     ]
     return {
      banners
     }
  }
}
</script>

<style lang="scss">
.the-benner {
    background-color: #2d449f;
    .el-carousel__container {
      height: 450px;
      .el-carousel__item {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 100%;
      }
    }
}
</style>