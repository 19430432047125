<template>
    <div class="evaluation-cell-body">
        <div :class="['evaluation-cell', stateTextClass]">
            <div class="cell-title ub ub-ac">{{ item.competitionName }}
                <span class="cell-state ub-f1 tx-r" v-if="!isMockExam">{{ stateText }}</span>
                <span class="cell-countdown" v-if="isCountdown">（考试倒计时：{{ formattedTime }}）</span>
            </div>
            <div :class="['cell-info ub ub-ac', isMockExam ? 'ub-pe' : ' ub-be']">
                <!-- 考试查看 -->
                <div class="ub ub-ver ub-pc" v-if="!isMockExam && userAccount.role == 0">
                    <div class="cell-date" v-if="isRegistrationData">报名时间：{{ item.enrollStartTime }}-{{ item.enrollEndTime }}</div>
                    <div class="cell-date">考试时间：{{ item.examinationStartTime }}</div>
                    <div class="cell-date" v-if="isOpenTime">考试结果公布时间：{{ item.openTime }}</div>
                </div>
                <!-- 评审者查看 -->
                <div class="cell-info-item ub ub-ac" v-if="userAccount.role == 1">
                    <div class="cell-date">考试时间：{{ item.startTime }}-{{ item.endTime }}</div>
                    <div class="cell-corrected">已批改：{{ item.correctedSum }}人</div>
                    <div class="cell-uncorrected">待批改：{{ item.uncorrectedSum }}人</div>
                </div>
                <div class="cell-buttons ub ub-ac">
                    <template v-if="isMockExam">
                        <!-- 模拟考试 -->
                        <el-dropdown>
                            <el-button color="#ff632d" round plain @click="examinationChange(item.examId, 1, true, item.competitionType)">模拟考试</el-button>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item v-for="(subItem) in mockExamData" :key="subItem.examId" @click="examinationChange(subItem.examId, 1, true, item.competitionType)">{{ subItem.examName }}</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </template>
                    <template v-if="!isMockExam && userAccount.role == 0">
                        <!-- 常规考试 -->
                        <el-button color="#0e9bee" round plain @click="registrationChange(item.competitionId, item.step)" v-if="isRegistrationBtn">去报名</el-button>
                        <el-button color="#0e9bee" round plain @click="examinationChange(item.examId, 1, false, item.competitionType)" v-if="isExaminationBtn">进入考试</el-button>
                        <download-ticket :competitionId="item.competitionId" v-if="isDownloadTicket" :type="1">下载准考证</download-ticket>
                        <download-ticket :certificateData="item.certificate" :type="2" v-if="isDownloadCertificate">下载证书</download-ticket>
                    </template>
                    <!-- 评审者查看 -->
                    <template v-if="userAccount.role == 1">
                        <el-button color="#0e9bee" round plain v-if="item.reviewStatus == 0" disabled>未开始</el-button>
                        <el-button color="#0e9bee" round plain @click="examinationChange(item.examId, 3)" v-if="item.reviewStatus == 1">去批改</el-button>
                        <el-button round plain v-if="item.reviewStatus == -1" disabled>已结束</el-button>
                    </template>
                </div>
            </div>
        </div>
        <div class="evaluation-cell-content ub ub-be" v-if="isOrder">
            <div class="ub ub-ver ub-pc">
                <div class="order-item">订单信息</div>
                <div class="order-item">订单号：{{ item.orderInfo.orderId }}</div>
                <div class="order-item">{{payText}}：￥{{ paymentAmount }}</div>
            </div>
            <div class="ub ub-ver ub-pc ub-ae ub-be">
                <div class="order-item" v-if="isPayCountdown">缴费倒计时 {{ payFormattedTime }}</div>
                <div class="order-item" v-if="item.orderInfo.refundStatus == 2">已退费</div>
                <div class="order-handle">
                    <!-- <span>详情</span> -->
                    <span v-if="isCancelApplication" @click="cancelApplication">取消报名</span>
                    <span v-if="isDeleteApplication" @click="deleteApplication">删除此条记录</span>
                    <span class="pay-button" v-if="isPayCountdown" @click="registrationChange(item.competitionId, item.step)">在线缴费</span>
                </div>
            </div>
        </div>

        <el-dialog
            class="check-device-dialog"
            v-model="deviceDialogVisible"
            title="设备检测"
            width="800"
            destroy-on-close
            @close="deviceDialogHandleClose"
        >
            <div class="content-title">摄像头检测<el-button type="primary" @click="checkMediaDevices">开始</el-button></div>
            <div class="content-model">
                <video :class="checkDeviceState != 1 ? 'hide' : ''" id="videoElement" autoplay playsinline muted></video>
                <div class="video-check-tip" v-if="checkDeviceState == 1">提示：请确保摄像头对准人脸，否则成绩无效</div>
                <div v-if="checkDeviceState == 2" class="device-err">请检查设备是否正常，或者权限是否开启！</div>
            </div>
            <!-- <div class="content-title">屏幕录屏设置<el-button type="primary" @click="setScreenDevice">开始</el-button></div>
            <div class="content-model">
            </div> -->
            <template #footer v-if="checkDeviceState == 1">
            <div class="dialog-footer">
                <el-button type="primary" @click="deviceDialogVisible = false">
                设备正常
                </el-button>
            </div>
            </template>
        </el-dialog>
  </div>
  
</template>

<script>
import { ref, computed, onMounted, onUnmounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import DownloadTicket from '@/pages/components/DownloadTicket.vue';
import store from '@/store';
import config from '@/http/config'
import { queryExamInfo, addRefundOrderInfo, deleteOrderInfo } from '@/http/api'
import { ElMessageBox, ElMessage } from 'element-plus';


export default {
  components: { DownloadTicket },
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        }
    }, 
    setup(props) {
        let mediaStream = null;
        let videoElement = null;
        const deviceDialogVisible = ref(false)
        const checkDeviceState = ref(0)
        const router = useRouter();
        const route = useRoute();
        const userAccount = computed(() => store.getters.userAccount)
        const stateText = computed(() => {
            switch (Number(props.item.enrollStats)) { // 报名状态(0未开始 1开始 -1结束)
                case 0:
                    return '报名未开始'
                case 1: 
                    switch (Number(props.item.applicationStatus)) { // 报名审核(-1审核中 0未通过审核 1通过（未缴费）2通过（已交费）)
                        case -1:
                            return '后台审核中'
                        case 0:
                            return '审核未通过'
                        case 1:
                            return '待缴费'
                        case 2:
                            switch (Number(props.item.userEnrollStats)) { // 用户报名状态(0未报名 1已报名)
                                case 1:
                                    // return '已报名，等待考试开始'
                                    switch (Number(props.item.orderInfo.refundStatus)) { // 用户是否退费(2已取消并退费 其他未知正常进行)
                                        case 2:
                                            return '已失效'
                                        default:
                                            return '已报名，等待考试开始'
                                    }
                                default:
                                    return '报名进行中';
                            }
                        default:
                            return '';
                    }
                case -1:
                    switch (Number(props.item.userEnrollStats)) { // 用户报名状态(0未报名 1已报名)
                        case 1:
                            switch (props.item.isContest) { // 是否参加考试（0未参加 1已参加）
                                case 0:
                                    switch (Number(props.item.examinationStats)) { // 考试是否开始状态(0未开始 1已开始 -1已结束)
                                        case 0:
                                            // return '已报名，等待考试开始'
                                            switch (Number(props.item.orderInfo.refundStatus)) { // 用户是否退费(2已取消并退费 其他未知正常进行)
                                                case 2:
                                                    return '已失效'
                                                default:
                                                    return '已报名，等待考试开始'
                                            }
                                        case 1:
                                            return '考试进行中'
                                        case -1:
                                            return '未参与考试'
                                        default: 
                                            return ''
                                    }
                                case 1:
                                    switch (Number(props.item.examinationStats)) { // 考试是否开始状态(0未开始 1已开始 -1已结束)
                                        case 0:
                                        case 1:
                                            return '考试已结束，等待公布结果'
                                        case -1:
                                            switch (props.item.openTimeStats) { // 成绩是否公开（0未公开 1已公开）
                                                case 0:
                                                    return '考试已结束，等待公布结果'
                                                case 1:
                                                    switch (props.item.passedOrNot) { // 是否通过开始 （0未通过 1已通过）
                                                        case 0:
                                                            return '未通过考试'
                                                        case 1:
                                                            return '通过考试'
                                                        default:
                                                            return '';
                                                    }
                                                default:
                                                    return '';
                                            }
                                        default:
                                            return ''
                                    }
                                default:
                                    return ''
                            }
                        default:
                            return '报名已结束';
                    }
                default:
                    return '';
            }
        })
        const stateTextClass = computed(() => {
            if (stateText.value == '报名未开始') {
                return 'info'
            } else if (stateText.value == '未参与考试') {
                return 'warning'
            } else if (stateText.value == '未通过考试') {
                return 'error'
            } else if (stateText.value == '通过考试') {
                return 'success'
            }
        })
        const mockExamData = reactive([])
        const isExaminationData = computed(() => {
            return props.item.userEnrollStats == 1 && props.item.examinationStats != -1 && props.item.isContest == 0
        })
        const isRegistrationData = computed(() => {
            return props.item.enrollStats != -1
        })
        const isDownloadTicket = computed(() => {
            return props.item.userEnrollStats == 1 
                && props.item.examinationStats != -1 
                && props.item.isContest == 0 
                && props.item.orderInfo.refundStatus != 2
                && props.item.admissionTime < 0 // 准考证下载限制，为负数才让下载
        })
        const isDownloadCertificate = computed(() => {
            return props.item.openTimeStats == 1 && props.item.passedOrNot == 1
        })
        const isMockExam = computed(() => { // 模拟考试 
            return props.item.testPaperType == 1 // 1模拟考试 2常规考试
        })
        const isRegistrationBtn = computed(() => { // 去报名按钮
            return props.item.enrollStats == 1 && props.item.userEnrollStats == 0 && props.item.applicationStatus != 2
        })
        const isExaminationBtn = computed(() => { // 去考试按钮
            return props.item.examinationStats == 1 && props.item.isContest == 0
        })
        const isOpenTime = computed(() => { // 是否显示成绩公布时间
            return props.item.examinationStats != 0 && props.item.openTime
        })
        const isOrder = computed(() => { // applicationStatus -1审核中 0 未通过审核 1通过未交费 2通过已缴费
            return props.item.applicationStatus == 1  ||  props.item.applicationStatus == 2
        })
        const isCountdown = computed(() => { // 考试倒计时
            let timeDifference = Number(props.item.timeDifference)
            return timeDifference && timeDifference > 0
        })
        const isPayCountdown = computed(() => { // 支付倒计时 有订单信息并且未报名并且考试未结束
            return isOrder.value && props.item.userEnrollStats == 0 && props.item.examinationStats != -1
        })
        const isCancelApplication = computed(() => {
            return props.item.enrollStats != -1 
                && ( props.item.applicationStatus == 1 || props.item.applicationStatus == 2 || props.item.userEnrollStats == 1) 
                && props.item.examinationStats == 0
                && props.item.orderInfo.refundStatus != 2
        })
        const isDeleteApplication = computed(() => {
            return props.item.orderInfo && props.item.orderInfo.refundStatus == 2
        })
        const payText = computed(() => {
            if (props.item.applicationStatus == 1) {
                return '应支付金额'
            } else if (props.item.applicationStatus == 2) {
                return '已支付'
            }
        })
        const paymentAmount = computed(() => {
            if (props.item.applicationStatus == 1) {
                return props.item.orderInfo.originalPrice
            } else if (props.item.applicationStatus == 2) {
                return props.item.orderInfo.paymentAmount
            }
        })
        // 考试倒计时
        const countdown = ref(props.item.timeDifference); // 设置倒计时的初始时间（秒）
        let timer; // 用于存储 setInterval 的 ID
        
        // 格式化倒计时时间为 mm:ss 格式
        const formattedTime = computed(() => {
            const day = Math.floor(countdown.value / (24 * 60 * 60));
            const hours = Math.floor((countdown.value % (24 * 60 * 60))  / 60 / 60);
            const minutes = Math.floor((countdown.value % 3600) / 60);
            const seconds = countdown.value % 60;
            return `${day > 0 ? day.toString().padStart(2, '0') + '天' : ''}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        });
        // 更新倒计时
        const updateCountdown = (type) => {
            if (countdown.value > 0) {
                countdown.value--;
            } else {
                location.reload();
                clearInterval(timer);
            }
        };

        // 支付倒计时
        let payTimer; // 支付倒计时
        const payCountdown = ref(isPayCountdown.value  ? props.item.orderInfo.effectiveTime : ''); // 设置倒计时的初始时间（秒）
        // 格式化倒计时时间为 mm:ss 格式
        const payFormattedTime = computed(() => {
            const hours = Math.floor(payCountdown.value / 60 / 60);
            const minutes = Math.floor((payCountdown.value % 3600) / 60);
            const seconds = payCountdown.value % 60;
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        });
        // 更新倒计时
        const payUpdateCountdown = () => {
            if (payCountdown.value > 0) {
                payCountdown.value--;
            } else {
                location.reload();
                clearInterval(payTimer);
            }
        };
        

        onMounted(() => {
            if (isCountdown.value) {
                timer = setInterval(updateCountdown, 1000);
            }
            if (isPayCountdown.value) {
                payTimer = setInterval(payUpdateCountdown, 1000);
            }
            if (isMockExam.value && config.type == 'pre') {
                getMockExamData()
            }
        })

        onUnmounted(() => {
            clearInterval(timer);
            clearInterval(payTimer);
        })

        function getMockExamData() {
            queryExamInfo({
                examType: 1
            }).then((res) => {
                mockExamData.length = 0
                mockExamData.push(...res.data);
            }).catch((err) => {
            })
        }
        
        
        function registrationChange(id, step) {
            if (step == 1) {
                router.push(`/examination-information?id=${id}&from=2`)
            } else {
                router.push(`/examination-registration?id=${id}&step=${step}&from=2`)
            }
        }

        /**
         * 进入考试/评分界面
         * @param {String} examId 试卷id
         * @param {Number} type 1 考试 3 批改
         * @param {Boolean} isMockExamType  是否是模拟考试 true 是 false 不是
         * @param {Number} competitionType  线上或线下 0 线上 1 线下
         */
        function examinationChange(examId, type, isMockExamType, competitionType) {
            console.log(type)
            console.log(isMockExamType)
            console.log(competitionType)
            if (type == 1 && !isMockExamType && competitionType == 0 && checkDeviceState.value != 1) {
                deviceDialogVisible.value = true
                checkDeviceState.value = 0
                return
            }
            const params = {
                examId: examId,
                type: type,
                uid: userAccount.value.userId,
                pageName: props.item.examinationType,
                routeName: route.name,
                competitionId: props.item.competitionId,
                isMockExamType: isMockExamType,
                competitionType: competitionType,
                role: userAccount.value.role
            }
            router.push({ name: 'ModelTest', params: params })
        }

        /**
         * 取消报名
         */
         function cancelApplication() {
            ElMessageBox.confirm(
                '是否确认取消该报名?',
                '提示',
                {
                confirmButtonText: '确认',
                cancelButtonText: '关闭',
                type: 'warning',
                }
            )
                .then(() => {
                    cancelApplicationHttp()
                })      
                .catch(() => {
                    
                })
         }

        //  取消报名请求
         function cancelApplicationHttp() {
            addRefundOrderInfo({
                orderId: props.item.orderInfo.orderId
            }).then((res) => {
                ElMessage({
                    message: '已取消',
                    type: 'success',
                })
                setTimeout(() => {
                    location.reload();
                }, 2000)
            }).catch((err) => {
            })
         }

         /**
          * 删除报名记录
          */
          function deleteApplication() {
            ElMessageBox.confirm(
                '是否确认取消该报名?',
                '提示',
                {
                confirmButtonText: '确认',
                cancelButtonText: '关闭',
                type: 'warning',
                }
            )
                .then(() => {
                    deleteApplicationHttp()
                })      
                .catch(() => {
                })
          }

         
        //  删除报名记录请求
        function deleteApplicationHttp() {
            deleteOrderInfo({
                orderId: props.item.orderInfo.orderId
            }).then((res) => {
                ElMessage({
                    message: '已删除',
                    type: 'success',
                })
                setTimeout(() => {
                    location.reload();
                }, 2000)
            }).catch((err) => {
            })
         };

         /**
          * 关闭设备检测弹窗
          */
          function deviceDialogHandleClose() {
            console.log('弹窗关闭')
            // 停止所有媒体轨道
            if (mediaStream) {
                mediaStream.getTracks().forEach(track => track.stop());
            }
            if (videoElement) {
                videoElement.pause();
                videoElement.srcObject = null;
            }
            
            deviceDialogVisible.value = false
          };

         //判断是否有可用的摄像头或者麦克风
         async function checkMediaDevices() {
            checkDeviceState.value = 0
            try {
                videoElement = document.getElementById('videoElement');
                mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                // 测试通过，显示视频流
                videoElement.srcObject = mediaStream;
                videoElement.play();
                console.log('成功打开视频流和音频流');
                checkDeviceState.value = 1


            } catch (error) {
                // 没有找到媒体设备或用户未授权
                checkDeviceState.value = 2
                console.error('获取媒体设备失败:', error);
            }
        };

        /* function setScreenDevice() {
            navigator.mediaDevices
            //屏幕录制
            .getDisplayMedia({
            video: true,
            audio: true,
            })
            .then((stream) => {
            //根据返回的流创建录制流程
            console.log(stream)
            });
        } */
        
        
        return {
            deviceDialogVisible,
            userAccount,
            stateText,
            stateTextClass,
            isRegistrationData,
            isExaminationData,
            isDownloadTicket,
            isDownloadCertificate,
            isMockExam,
            isOpenTime,
            isCountdown,
            isOrder,
            isCancelApplication,
            isDeleteApplication,
            isRegistrationBtn,
            isExaminationBtn,
            registrationChange,
            examinationChange,
            formattedTime,
            payFormattedTime,
            mockExamData,
            payText,
            paymentAmount,
            cancelApplication,
            deleteApplication,
            isPayCountdown,
            checkMediaDevices,
            checkDeviceState,
            deviceDialogHandleClose,
            // setScreenDevice
        }
    }
}
</script>

<style lang="scss">
.evaluation-cell-body {
    margin-bottom: 25px;
    background-color: #f3efec;
    border-radius: 15px;
    .evaluation-cell {
        border: 1px solid #acdefc;
        border-radius: 15px;
        overflow: hidden;
        background-color: #ffffff;
        .cell-title {
            height: 74px;
            padding: 0 24px;
            background-color: #e6f4fc;
            font-size: 24px;
            .cell-state {
                color: #0e9bee;
                font-size: 18px;
            }

            .cell-countdown {
                font-size: 14px;
                color: red;
            }
        }
        .cell-info {
            padding: 10px 24px;
            height: 80px;
            .cell-info-item {
            }
            .cell-date {
                font-size: 16px;
                line-height: 30px;
                margin-right: 10px;
                color: #666666;
            }
            .cell-corrected {
                margin-right: 20px;
            }
            .cell-buttons {
                .el-button {
                    margin: 24px 0;
                }
                .download-ticket {
                    margin-left: 20px;
                    line-height: 80px;
                }
            }
        }
    } 
    .evaluation-cell-content {
        height: 128px;
        padding: 20px 24px;
        font-size: 16px;
        line-height: 30px;
        margin-right: 10px;
        box-sizing: border-box;
        color: #666666;
        .order-handle {
            span {
                text-decoration: underline;
                margin-left: 12px;
                cursor: pointer;
            }
            span.pay-button {
                color: #0e9bee;
            }
        }
    }
}

.evaluation-cell.info {
    .cell-title { 
        .cell-state {
            color: #989a99;
        }
    }
}

.evaluation-cell.warning {
    .cell-title {
        .cell-state {
            color: #e6a23c;
        }
    }
    
}

.evaluation-cell.error {
    .cell-title {
        .cell-state {
            color: #f56c6c;
        }
    }
}
.evaluation-cell.success {
    .cell-title {
        .cell-state {
            color: #67c23a;
        }
    }
}

.check-device-dialog {
    .content-title {
        .el-button {
            margin-left: 20px;
        }
    }
    .content-model {
        display: flex;
        margin-top: 20px;

        #videoElement {
            width: 300px;
            height: 150px;
        }
        video.hide {
            width: 0 !important;
        }
        .video-check-tip {
            color: red;
        }
        .device-err {
            color: red;
        }
    }
}
</style>