<template>
  <div class="evaluation-python">
    <evaluation-content-model :id="2"></evaluation-content-model>
  </div>
</template>

<script>
import EvaluationContentModel from './components/EvaluationContentModel.vue'
export default {
  components: { EvaluationContentModel }
}
</script>

<style>

</style>