<template>
  <div class="user-info">
    <second-model-title :breadcrumbList="breadcrumbList" :type="2"></second-model-title>
    <privacy-info></privacy-info>
    <el-form
        class="form-data"
        label-position="top"
        :model="formData"
        :rules="rules"
        ref="formRef"
        status-icon
        label-width="120px">
        <el-form-item prop="imageUrl" class="upload-image" label="免冠照">
            <!-- <div class="el-form-item__label"></div> -->
            <!-- :action="config.url + '/'" -->
            <div class="ub ub-be ub-f1">
                <div class="ub ub-ver">
                    <el-upload
                        class="avatar-uploader"
                        :action="config.url + '/UserInfoController/uploadFile'"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                    >
                        <img v-if="formData.imageUrl" :src="formData.imageUrl" class="avatar" />
                        <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                    </el-upload>
                    <div class="upload-image-tip">标准2寸(蓝底或红底)，jpg格式，大小1M以内，分辨率范围在300~400之间</div>
                </div>
                <example-avatar></example-avatar>
            </div>
            
        </el-form-item>
        <div class="form-model-item form-address-item ub ub-pe" style="width: 100%">
            <el-form-item prop="province" label="所在地">
                <el-select v-model="formData.province" class="m-2" @change="handleProvinceChange" placeholder="省" size="large">
                    <el-option
                        v-for="item in provinces"
                        :key="item.code"
                        :label="item.name"
                        :value="item.name"
                    />
                </el-select>
            </el-form-item>
            <el-form-item prop="city" label="市">
                <el-select v-model="formData.city" class="m-2" @change="handleCityChange" placeholder="市" size="large">
                    <el-option
                    v-for="item in cities"
                        :key="item.code"
                        :label="item.name"
                        :value="item.name"
                    />
                </el-select>
            </el-form-item>
            <el-form-item prop="district" label="区">
                <el-select v-model="formData.district" class="m-2" placeholder="区" size="large">
                    <el-option
                        v-for="item in districts"
                        :key="item.code"
                        :label="item.name"
                        :value="item.name"
                    />
                </el-select>
            </el-form-item>
        </div>
        <div class="form-model-item ub ub-pe" style="width: 100%">
            <el-form-item prop="schoolName" label="就读学校">
                <el-input v-model="formData.schoolName" placeholder="" clearable />
            </el-form-item>
            <el-form-item prop="className" label="年级">
                <el-select v-model="formData.className" class="m-2" placeholder="年级" size="large">
                    <el-option
                    v-for="item in classList"
                        :key="item"
                        :label="item"
                        :value="item"
                    />
                </el-select>
            </el-form-item>
        </div>
        <div class="form-model-item ub ub-be" style="width: 100%">
            <el-form-item prop="email" label="邮箱">
                <el-input v-model="formData.email" placeholder="" clearable />
            </el-form-item>
            <el-form-item prop="parentName" label="家长姓名">
                <el-input v-model="formData.parentName" placeholder="" clearable />
            </el-form-item>
        </div>
        <div class="form-model-item ub ub-be">
            <el-form-item prop="emergencyContactName" label="紧急联系人">
                <el-input v-model="formData.emergencyContactName" placeholder="紧急联系人姓名" clearable />
            </el-form-item>
            <el-form-item prop="emergencyContactPhone" label="紧急联系人电话">
                <el-input v-model="formData.emergencyContactPhone" placeholder="紧急联系人电话" clearable />
            </el-form-item>
        </div>
    </el-form>
    <el-button class="save-button" type="primary" round @click="submitForm">保存</el-button>
  </div>
</template>

<script>
import SecondModelTitle from '../components/SecondModelTitle.vue'
import { ref, computed,onMounted, reactive, nextTick } from 'vue'
import areaData from '@/utils/pca-code.json'; // 导入省市区数据的 JSON 文件
import { useStore } from 'vuex';
import config from '@/http/config'
import commmonJs from '@/utils/common'
import { modifyUserInfo, uploadFile } from '@/http/api'
import { ElMessage } from 'element-plus';
import { AuthService } from '@/utils/AuthService';
import data from '@/utils/data'
import PrivacyInfo from '../components/PrivacyInfo.vue';
import { useRouter } from 'vue-router';
import ExampleAvatar from '@/components/ExampleAvatar.vue';
export default {
  components: { SecondModelTitle, PrivacyInfo, ExampleAvatar },
  setup() {
    const router = useRouter()
    const store = useStore()
    const userAccount = computed(() => store.getters.userAccount)
    const breadcrumbList = reactive([{
      title: `你好，${userAccount.value.userName}`,
      path: ''
    }])
    const provinces = reactive([]);
    const cities = reactive([]);
    const districts = reactive([]);
    const classList = ref(data.classList)
    const addressArr = userAccount.value.city.split('-')
    const formData = ref({
        province: addressArr.length > 0 ? addressArr[0] : '',
        city: addressArr.length > 0 ? addressArr[1] : '',
        district: addressArr.length > 0 ? addressArr[2] : '',
        schoolName: userAccount.value.attendingCampus,
        className: userAccount.value.userGrade,
        email: userAccount.value.userMailbox,
        parentName: userAccount.value.userParentName,
        imageUrl: userAccount.value.userImg,
        emergencyContactName: userAccount.value.contactName,
        emergencyContactPhone: userAccount.value.contactTel,
    })
    const imageData = ref('')
    const imageName = ref('')

    onMounted(() => {
      // 在组件挂载后，将 JSON 文件的内容赋值给 provinces
      provinces.push(...areaData);
    });

    /**
     * 选择省份
     * @param {*} value 
     */
    const handleProvinceChange = (value) => {
      // 在这里根据选择的省份 value 获取对应的城市数据，并更新 cities 变量
      let newCities = provinces.find((obj) => obj.name === value).children
      cities.length = 0
      cities.push(...newCities);
      formData.value.province = value;
      formData.value.city = ''; // 重置已选城市
      formData.value.district = ''; // 重置已选区县
    }; 

    /**
     * 选择区县
     * @param {x} value 
     */
    const handleCityChange = (value) => {
      // 在这里根据选择的城市 value 获取对应的区县数据，并更新 districts 变量
      // 假设 getDistrictByCityValue 是一个根据城市 value 获取区县数据的方法
      let newDistricts = cities.find((obj) => obj.name === value).children
      districts.length = 0
      districts.push(...newDistricts);
      formData.value.city = value;
      formData.value.district = ''; // 重置已选区县
    };

    const rules = ref({
        imageUrl: [
            { required: true, message: '请上传免冠照', trigger: 'blur' },
        ],
        province: [
            { required: true, message: '请选择地址', trigger: 'change' },
        ],
        city: [
            { required: true, message: '请选择地址', trigger: 'change' },
        ],
        district: [
            { required: true, message: '请选择地址', trigger: 'change' },
        ],
        schoolName: [
            { required: true, message: '请输入学校名称', trigger: 'blur' },
            { max:50, message: '最大字数50字', trigger: 'blur' },
        ],
        className: [
            { required: true, message: '请选择班级', trigger: 'change' },
        ],
        email: [
            { validator: checkEmail, trigger: 'blur' },
        ],
        parentName: [

        ],
        emergencyContactName: [
            { required: true, message: '紧急联系人姓名', trigger: 'blur' },
        ],
        emergencyContactPhone: [
            { required: true, message: '紧急联系人电话', trigger: 'blur' },
            { validator: checkPhoneNumber, trigger: 'blur' },
        ]
        
    });

    /**
     * 验证手机号
     * @param {*} rule 
     * @param {*} value 
     * @param {*} callback 
     */
     function checkPhoneNumber(rule, value, callback) {
        if (!commmonJs.validatePhoneNumber(value)) {
            callback(new Error('请输入正确的手机号'));
        } else {
            callback()
        }
    }

    /**
     * 验证邮箱
     * @param {*} rule 
     * @param {*} value 
     * @param {*} callback 
     */
        function checkEmail(rule, value, callback) {
        if (value && !isValidEmail(value)) {
            callback(new Error('请输入正确的邮箱'));
        } else {
            callback()
        }
    }

    function isValidEmail(email) {
        // 定义邮箱地址的正则表达式
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        // 使用正则表达式的 test 方法进行验证
        return emailRegex.test(email);
    }
    
    const formRef = ref(null); // 创建对 form 元素的引用
        
    // methods
    const submitForm = async () => {
        // 表单提交逻辑
        // ...

        // 等待 nextTick 后再执行校验操作
        await nextTick();
        // 校验表单
        if (formRef.value) {
            formRef.value.validate(valid => {
                if (valid) {
                    // 校验通过，执行表单提交逻辑
                    // ...
                    imageUploadhttp()
                    
                }
            });
        }
    };

    /**
     * 上传图片
     */
     function imageUploadhttp() {
        if (!imageData.value) {
            imageName.value = userAccount.value.userImg
            editUserInfoHttp()
            return
        }
        uploadFile({
            file: imageData.value,
            fileType: 1
        }).then((res) => {
            imageName.value = res.data
            editUserInfoHttp()
        }).catch((err) => {
        })
     }

    /**
     * 保存信息接口
     */
    function editUserInfoHttp() {
        let userImgArr = imageName.value.split('/')
        let userImg = userImgArr[userImgArr.length - 1]
        modifyUserInfo({
            attendingCampus: formData.value.schoolName,
            city: `${formData.value.province}-${formData.value.city}-${formData.value.district}`,
            sex: userAccount.value.sex, // 0位置 1男 2女
            state: 0,
            userGrade: formData.value.className,
            userId: userAccount.value.userId,
            userImg: userImg,
            userMailbox: formData.value.email,
            userParentName: formData.value.parentName,
            contactName: formData.value.emergencyContactName,
            contactTel: formData.value.emergencyContactPhone,
        }).then((res) => {
            ElMessage({
                message: '修改成功',
                type: 'success',
            })
            userAccount.value.attendingCampus = formData.value.schoolName
            userAccount.value.city = `${formData.value.province}-${formData.value.city}-${formData.value.district}`
            userAccount.value.userGrade = formData.value.className
            userAccount.value.userImg = imageName.value
            userAccount.value.userMailbox = formData.value.email
            userAccount.value.userParentName = formData.value.parentName
            userAccount.value.contactName = formData.value.emergencyContactName
            userAccount.value.contactTel = formData.value.emergencyContactPhone
            AuthService.login(userAccount.value)
            store.commit('setUserAccount', userAccount.value)
        }).catch((err) => {
        })
    }

    /**
     * 上传头像成功
     */
    function handleAvatarSuccess(res, file) {
        imageData.value = file.raw
        formData.value.imageUrl = URL.createObjectURL(file.raw);
    }

    /**
     * 上传头像前限制
     */
    function beforeAvatarUpload(rawFile) {

        // 获取上传文件的宽度和高度
        return new Promise((resolve, reject) => {
            if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
                ElMessage.error('请上传png或者jpg格式图片！')
                reject()
            } else if (rawFile.size / 1024 / 1024 > 2) {
                ElMessage.error('图片大小为2M以内！')
                reject()
            }
            const img = new Image();
            img.src = window.URL.createObjectURL(rawFile);
            img.onload = () => {
                const width = img.width;
                const height = img.height;
                
                if (width === 413 && height === 579) {
                    resolve(rawFile); // 符合尺寸要求，允许上传
                } else {
                    ElMessage.error('上传图片的尺寸必须为2寸（413x579像素）')
                    reject();
                }
            };
        });

    }

    return {
        userAccount,
        breadcrumbList,
        provinces,
        cities,
        districts,
        classList,
        formRef,
        formData,
        handleProvinceChange,
        handleCityChange,
        rules,
        submitForm,
        config,
        handleAvatarSuccess,
        beforeAvatarUpload
    }
  }
}
</script>

<style lang="scss">
.user-info {
    .privacy-info {
        margin-bottom: 40px;

        span {
            display: inline-block;
            margin-right: 60px;
            color: #666666;
        }
        span.id-card {
            margin-right: 0;
        }
    }
    .form-data {
        .el-form-item {
            width: 414px;
            margin-right: 8px;
            .el-form-item__label {
                color: #0e9bee;
            }
            .el-form-item__content {
                .el-input {
                    .el-input__wrapper {
                        padding: 0;
                        border-radius: 5px;
                        overflow: hidden;
                        box-shadow: inset 0 0 8px 2px #ededed;
                        .el-input__inner {
                            height: 40px;
                            line-height: 40px;
                            font-size: 16px;
                            padding: 0 10px;
                        }
                        .el-input__suffix {
                            padding-right: 5px;
                        }
                    }
                }
                .el-select {
                    width: 100%;
                }
            }
        }

        .upload-image {
            display: flex;
            align-items: center;
            width: 100% !important;
            .el-form-item__label {
                margin-bottom: 0 !important;
            }
            .avatar-uploader {
                img {
                    max-width: 112px;
                }
                .avatar-uploader-icon {
                    width: 37px;
                    height: 37px;
                    border: 3px dashed #999999;
                    border-radius: 5px;
                    color: #999999;
                    font-weight: bold;
                    font-size: 18px;
                }
            }
            .upload-image-tip {
                color: #a0a0a0;
            }
        }

        .form-address-item {
            .el-form-item {
                .el-form-item__label {
                    opacity: 0;
                }
            }

            .el-form-item:first-child {
                .el-form-item__label {
                    opacity: 1;
                }
            }
        }
    }
    .save-button {
        width: 240px;
        height: 40px;
        font-size: 18px;
        margin: 67px auto 0;
        display: block;
    }
}
</style>