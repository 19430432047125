<template>
  <div class="evaluation-registration">
    <second-model-2>
        <template v-slot:model-content>
            <second-model-title :breadcrumbList="breadcrumbList"></second-model-title>
            <div class="steps ub ub-ac ub-be">
              <div :class="['step-item ub ub-ver ub-ac', index <= stepActive ? 'act' : '', 'step-item' + (index +1)]" v-for="(item, index) in stepList" :key="index">
                <div class="step-item-icon ub ub-ac ub-pc"></div>
                <div class="step-item-title ub ub-ac" v-html="item.title"></div>
              </div>
            </div>
            <div class="registration-step-model">
              <el-carousel
               ref="carouselModel"
                :autoplay="false" 
                :initial-index="stepActive" 
                @change="stepChange"
                :loop="false"
                indicator-position="none"
                >
                <el-carousel-item v-for="(item) in stepList" :key="item">
                  <component :is="item.component" @stepChange="stepChange" :competitionId="id" :currentNum="stepActive" :item="item"></component>
                </el-carousel-item>
                
              </el-carousel>
            </div>
        </template>
    </second-model-2>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue'
import SecondModel2 from '../components/SecondModel2.vue'
import SecondModelTitle from '../components/SecondModelTitle.vue'
import StepRealName from './components/StepRealName.vue'
import StepImproveUserinfo from './components/StepImproveUserinfo.vue'
import StepApplicationInfo from './components/StepApplicationInfo.vue'
import StepReview from './components/StepReview.vue'
import StepPay from './components/StepPay.vue'
import StepRegistrationSuccess from './components/StepRegistrationSuccess.vue'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router'
export default {
  components: { SecondModel2, SecondModelTitle, StepRealName, StepImproveUserinfo, StepApplicationInfo, StepRegistrationSuccess, StepReview, StepPay },
  setup() {
    const queryString = window.location.hash.split('?')[1]; // 获取锚点后的查询参数部分
    const params = new URLSearchParams(queryString);
    const breadcrumbList = ref([{
      title: '在线测评',
      path: '/evaluation'
    }, {
      title: '考试报名',
      path: ''
    }])
    const from = params.get('from');
    if (from == 2) {
        breadcrumbList.value[0] = {
            title: '报考记录',
            path: '/personal/application-record'
        }
    }
    const router = useRouter()
    const stepRealNameComponent = ref(StepRealName);
    const stepImproveUserinfoComponent = ref(StepImproveUserinfo);
    const stepApplicationInfoComponent = ref(StepApplicationInfo);
    const stepReviewComponent = ref(StepReview);
    const stepPayComponent = ref(StepPay);
    const stepRegistrationSuccessComponent = ref(StepRegistrationSuccess);

    const stepActive = ref(0)
    const stepList = reactive([{
      icon: '',
      title: '实名认证',
      component: stepRealNameComponent
    }, {
      icon: '',
      title: '完善信息',
      component: stepImproveUserinfoComponent
    }, {
      icon: '',
      title: '确认<br/>报考信息',
      component: stepApplicationInfoComponent
    }, {
      icon: '',
      title: '资料审核',
      component: stepReviewComponent
    }, {
      icon: '',
      title: '完成缴费',
      component: stepPayComponent
    }, {
      icon: '',
      title: '报名成功',
      component: stepRegistrationSuccessComponent
    }])
    const carouselModel = ref(null)
    const userAccount = computed(() => store.getters.userAccount)
    
    const step = params.get('step');
    const id = params.get('id');
    onMounted(() => {
      if (step) {
        setStep(Number(step) - 2)
        return
      }

      if (userAccount.value.userIdCard) {
        setStep(1)
      }
    })
    function setStep(index) {
      stepActive.value = index
      carouselModel.value.setActiveItem(index)
    }
    function stepChange(e) {
      setStep(e)
    }
    return {
      id,
      breadcrumbList,
      stepActive,
      stepList,
      stepRealNameComponent,
      stepImproveUserinfoComponent,
      stepApplicationInfoComponent,
      stepRegistrationSuccessComponent,
      stepChange,
      carouselModel
    }
  }
}
</script>

<style lang="scss">
.evaluation-registration {
  .second-model2 {
    .second-model-title {
      margin-bottom: 0;
    }
    .steps {
      height: 225px;
      padding: 0 17px;
      background-color: #e6f5fc;
      .step-item {
        &::before {
          content: '';
          width: 37px;
          height: 31px;
          background-image: url('../../assets//image/step-icon.png');
          background-repeat: no-repeat;
          background-size: 100%;
          position: absolute;
          right: -50px;
          top: 50px;
        }
        .step-item-icon {
          width: 126px;
          height: 126px;
          background-color: #ffffff;
          border-radius: 50%;
          position: relative;
          /* &::before, &::after {
            content: '';
            border-radius: 50%;
          }
          &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            border: 1px dashed #0f9aed;
            left: 0;
            top: 0;
          }
          &::after {
            content: '';
            display: block;
            width: 108px;
            height: 108px;
            border: 1px solid #0f9aed;
          } */
        }
        .step-item-title {
          text-align: center;
          height: 44px;
          margin-top: 14px;
        }
      }
      .step-item:last-child {
        &::before {
          display: none;
        }
      }
      .step-item.act {
        .step-item-icon {
          /* &::after {
            background-color: #0f9aed;
          } */
        }
      }
      .step-item1 {
        .step-item-icon {
          background-image: url('../../assets/image/step1.png');
        }
      }
      .step-item1.act {
        .step-item-icon {
          background-image: url('../../assets/image/step1-act.png');
        }
      }
      .step-item2 {
        .step-item-icon {
          background-image: url('../../assets/image/step2.png');
        }
      }
      .step-item2.act {
        .step-item-icon {
          background-image: url('../../assets/image/step2-act.png');
        }
      }
      .step-item3 {
        .step-item-icon {
          background-image: url('../../assets/image/step3.png');
        }
      }
      .step-item3.act {
        .step-item-icon {
          background-image: url('../../assets/image/step3-act.png');
        }
      }
      .step-item4 {
        .step-item-icon {
          background-image: url('../../assets/image/step4.png');
        }
      }
      .step-item4.act {
        .step-item-icon {
          background-image: url('../../assets/image/step4-act.png');
        }
      }
      .step-item5 {
        .step-item-icon {
          background-image: url('../../assets/image/step5.png');
        }
      }
      .step-item5.act {
        .step-item-icon {
          background-image: url('../../assets/image/step5-act.png');
        }
      }
      .step-item6 {
        .step-item-icon {
          background-image: url('../../assets/image/step6.png');
        }
      }
      .step-item6.act {
        .step-item-icon {
          background-image: url('../../assets/image/step6-act.png');
        }
      }
    }
    .registration-step-model {
      padding: 0 104px;
      .el-carousel {
        padding-top: 60px;
        .el-carousel__container {
          height: 700px;
          .el-carousel__arrow {
            display: none;
          }
          .el-form {
            .el-form-item {
              width: 414px;
              margin-right: 8px;
              .el-form-item__label {
                font-size: 16px;
                line-height: 40px;
                color: #666666;
              }
              .el-form-item__content {
                  .el-input {
                      .el-input__wrapper {
                          padding: 0;
                          border-radius: 5px;
                          overflow: hidden;
                          box-shadow: inset 0 0 8px 2px #ededed;
                          .el-input__inner {
                              height: 40px;
                              line-height: 40px;
                              font-size: 16px;
                              padding: 0 10px;
                          }
                          .el-input__suffix {
                              padding-right: 5px;
                          }
                      }
                  }
                  .el-select {
                      width: 100%;
                  }
              }
            }
          }  
        }
      }
    }
  }
}
</style>