<template>
    <div class="privacy-info ub" v-if="userAccount.userIdCard">
        <span>姓名：{{ userAccount.userName }}</span>
        <span>性别：{{ userSex }}</span>
        <span>生日：{{ userAccount.birthday }}</span>
        <span class="id-card ub-f1">{{userIdCardType}}：{{ userAccount.userIdCard }}</span>
    </div>
</template>

<script>
import { computed } from 'vue'
import store from '@/store'
import data from '@/utils/data'
export default {
    setup() {
        const userAccount = computed(() => store.getters.userAccount)
        const userSex = data.gender.find(item => item.id == userAccount.value.sex).name
        const userIdCardType = data.idCardType.find(item => item.id == userAccount.value.idCardType).name
        
        return {
            userAccount,
            userSex,
            userIdCardType
        }
    }
}
</script>

<style lang="scss">
.privacy-info {
    margin-bottom: 40px;

    span {
        display: inline-block;
        margin-right: 60px;
        color: #666666;
    }
    span.id-card {
        margin-right: 0;
    }
}
</style>