<template>
  <div class="modify-password">
    <second-model-2>
        <template v-slot:model-content>
            <second-model-title :breadcrumbList="breadcrumbList"></second-model-title>
            <privacy-info></privacy-info>
            <el-form class="modify-form" :model="formData" :rules="rules" ref="formRef" label-position="top"  label-width="100px">
                <el-form-item prop="oldPassword" label="旧密码">
                    <el-input v-model="formData.oldPassword" type="password" placeholder="旧密码" autocomplete="old-password"></el-input>
                </el-form-item>
                <el-form-item prop="newPassword" label="新密码">
                    <el-input v-model="formData.newPassword" type="password" placeholder="新密码（请输入8~16位长度密码）" autocomplete="new-password"></el-input>
                </el-form-item>
                <el-form-item prop="againNewPassword" label="确认新密码">
                    <el-input v-model="formData.againNewPassword" type="password" placeholder="确认新密码"></el-input>
                </el-form-item>
            </el-form>
            <el-button class="save-button" type="primary" round @click="submitForm">确认修改</el-button>
        </template>
    </second-model-2>
  </div>
</template>

<script>
import SecondModel2 from '../components/SecondModel2.vue'
import SecondModelTitle from '../components/SecondModelTitle.vue'
import { ref, reactive, nextTick, computed } from 'vue';
import commmonJs from '@/utils/common'
import PrivacyInfo from '../components/PrivacyInfo.vue';
import { modifyPassWord } from '@/http/api'
import store from '@/store';
import { ElMessage } from 'element-plus';
export default {
  components: { SecondModel2, SecondModelTitle, PrivacyInfo },
  setup() {
    const breadcrumbList = reactive([{
      title: '修改密码',
      path: ''
    }])
    const userAccount = computed(() => store.getters.userAccount)
    const formData = ref({
                oldPassword: '',
                newPassword: '',
                againNewPassword: ''
            });
    const rules = ref({
        oldPassword: [
            { required: true, message: '请输入旧密码', trigger: 'blur' },
            { min: 8, max:16, message: '请输入8~16位数字或字母组合密码', trigger: 'blur' },
            { validator: checkPassword, trigger: 'blur' },
        ],
        newPassword: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 8, max:16, message: '请输入8~16位数字或字母组合密码', trigger: 'blur' },
            { validator: checkPassword, trigger: 'blur' },
        ],
        againNewPassword: [
            { required: true, message: '请再次输入密码', trigger: 'blur' },
            { validator: checkAgainPassword, trigger: 'blur' },
        ]
    });
    const formRef = ref(null); // 创建对 form 元素的引用


    // methods
    const submitForm = async () => {
        // 表单提交逻辑
        // ...

        // 等待 nextTick 后再执行校验操作
        await nextTick();

        // 校验表单
        if (formRef.value) {
            formRef.value.validate(valid => {
            if (valid) {
                // 校验通过，执行表单提交逻辑
                // ...
                modifyPasswordHttp()
            }
            });
        }
    };
    /**
     * 验证密码
     * @param {*} rule 
     * @param {*} value 
     * @param {*} callback 
     */
        function checkPassword(rule, value, callback) {
        if (!commmonJs.isAlphaNumericPhoneNumber(value)) {
            callback(new Error('请输入8~16位数字或字母组合密码'));
        } else {
            callback()
        }
    }

    /**
     * 验证二次密码
     * @param {*} rule 
     * @param {*} value 
     * @param {*} callback 
     */
        function checkAgainPassword(rule, value, callback) {
        if (!commmonJs.isAlphaNumericPhoneNumber(value)) {
            callback(new Error('请输入8~16位数字或字母组合密码'));
        } else if (value != formData.value.newPassword) {
            callback(new Error('密码不一致，请再次输入'));
        } else {
            callback()
        }
    }

    /**
     * 修改密码
     */
     function modifyPasswordHttp() {
        modifyPassWord({
            userId: userAccount.value.userId,
            newPassWord: formData.value.newPassword,
            pastPassWord: formData.value.oldPassword
        }).then((res) => {
            ElMessage({
                message: '修改成功',
                type: 'success',
            })
            setTimeout(() => {
                window.location.reload();
            }, 1500)
        }).catch((err) => {
        })
     }
    return {
        breadcrumbList,
        formData,
        rules,
        formRef,
        submitForm
    }
  }
}
</script>

<style lang="scss">
.modify-password {
    .privacy-info {
        margin-bottom: 40px;

        span {
            display: inline-block;
            margin-right: 60px;
            color: #666666;
        }
        span.id-card {
            margin-right: 0;
        }
    }

    .modify-form {
        width: 405px;
        .el-form-item__label {
            color: #0e9bee;
        }
        .el-form-item__content {
            .el-input {
                .el-input__wrapper {
                    padding: 0;
                    border-radius: 5px;
                    overflow: hidden;
                    box-shadow: inset 0 0 8px 2px #ededed;
                    .el-input__inner {
                        height: 40px;
                        line-height: 40px;
                        font-size: 16px;
                        padding: 0 10px;
                    }
                    .el-input__suffix {
                        padding-right: 5px;
                    }
                }
            }
            .el-select {
                width: 100%;
            }
        }
    }

    .save-button {
        width: 240px;
        height: 40px;
        font-size: 18px;
        margin-top: 37px;
    }
}
</style>