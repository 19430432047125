<template>
  <div :class="['second-model-title ub ub-ac', 'second-model-title' + type, breadcrumbList.length == 1 ? 'bold': '']">
    <el-breadcrumb separator=">>">
        <el-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="index" :to="{ path: item.path }" >{{ item.title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- {{ title }} -->
  </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        breadcrumbList: {
            type: Array,
            default: () => {
                return []
            }
        },
        type: {
            type: Number,
            default: 1
        }
    },
    setup() {
        return  {
            
        }
    }
}
</script>

<style lang="scss">
.second-model-title {
    height: 76px;
    line-height: 76px;
    border-bottom: 1px solid #cccccc;
    position: relative;
    padding-left: 25px;
    margin-bottom: 25px;
    font-weight: bold;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -7px;
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 13px solid #0e9bee; /* 这里设置三角形的宽度和颜色 */
    }
}

.second-model-title.bold {
    .el-breadcrumb__item {
        .el-breadcrumb__inner,
        .el-breadcrumb__inner:hover {
            font-weight: bold;
            color: #000000;
        }
    }
}

.second-model-title2 {
    padding-left: 0;
    &::before {
        display: none;
    }
}
</style>