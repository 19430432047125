<template>
    <div class="login-model login-code-model">
        <el-form :model="formData" :rules="rules" ref="formRef" label-width="100px">
            <el-form-item prop="phoneNumber" >
                <el-input v-model="formData.phoneNumber" clearable  placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item class="code-form-item" prop="code">
                <el-input v-model="formData.code"  placeholder="请输入验证码"></el-input>
                <el-button link @click="sendCodeVerify" :disabled="codeButtonDisabled">{{ codeButtonDisabled ? codeTimeSecond + 's' : '发送验证码' }}</el-button>
            </el-form-item>

            <el-form-item>
                <el-button class="login-button" type="primary" size="large" @click="submitForm">立即登录</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { ref, nextTick } from 'vue';
import { ElMessage } from 'element-plus';
import commmonJs from '@/utils/common'
import { userInfoController } from '@/http/api'
import { AuthService } from '@/utils/AuthService';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
    setup() {
        const store = useStore();
        const router = useRouter();
        const formRef = ref(null); // 创建对 form 元素的引用
        const formData = ref({
                phoneNumber: '',
                code: '',
            });
        const codeButtonDisabled = ref(false)
        const codeButtonText = ref('')
        const codeTimeSecond = ref(300)
        const timer = ref(null)

        const rules = ref({
            phoneNumber: [
                { required: true, message: '请输入手机号', trigger: 'blur' },
                { validator: checkPhoneNumber, trigger: 'blur' },
            ],
            code: [
                { required: true, message: '请输入验证码', trigger: 'blur' },
                { min: 6, max:6, message: '请输入6位数验证码', trigger: 'blur' },
            ]
        });
        const submitForm = async () => {
            // 表单提交逻辑
            // ...

            // 等待 nextTick 后再执行校验操作
            await nextTick();

            // 校验表单
            if (formRef.value) {
                formRef.value.validate(valid => {
                    if (valid) {
                        // 校验通过，执行表单提交逻辑
                        // ...
                        codeLoginHttp()
                    }
                });
            }
        };

        // 发送验证码验证
        function sendCodeVerify() {
            formRef.value.validateField('phoneNumber', (valid) => {
                if (valid) {
                    sendCodeHttp()
                } else {
                    // 手机号验证不通过，可以在这里处理错误提示等逻辑
                }
            });
        }

        // 验证码登录
        function codeLoginHttp() {
            userInfoController({
                type: 6,
                tel: formData.value.phoneNumber,
                code: formData.value.code
            }).then((res) => {
                initTimer()
                ElMessage({
                    message: '登录成功',
                    type: 'success',
                })
                AuthService.login(res.data)
                store.commit('setUserAccount', res.data);
                const redirectTo = router.currentRoute.value.query.redirect || '/'; // 如果没有重定向信息，默认回到首页
                router.push(redirectTo);
            }).catch((err) => {
            })
        }

        /**
         * 倒计时
         */
         function timeCountDown() {
            codeButtonDisabled.value = true;
            if (codeTimeSecond.value === 0) {
                initTimer()
            } else {
                codeTimeSecond.value--;
                timer.value = setTimeout(() => {
                    timeCountDown();
                }, 1000);
            }
        }

        /**
         * 发送验证码
         */
         function sendCodeHttp() {
            userInfoController({
                type: 1,
                tel: formData.value.phoneNumber,
                msgType: 5
            }).then((res) => {
                ElMessage({
                    message: '发送成功',
                    type: 'success',
                })
                timeCountDown()
            }).catch((err) => {
            })
        }

        /**
         * 验证手机号
         * @param {*} rule 
         * @param {*} value 
         * @param {*} callback 
         */
         function checkPhoneNumber(rule, value, callback) {
            if (!commmonJs.validatePhoneNumber(value)) {
                callback(new Error('请输入正确的手机号'));
            } else {
                callback()
            }
        }

        /**
         * 初始化倒计时
         */
         function initTimer() {
            codeTimeSecond.value = 300;
            codeButtonDisabled.value = false;
            clearInterval(timer.value);
        }
        return {
            formRef,
            formData,
            codeButtonDisabled,
            codeButtonText,
            codeTimeSecond,
            rules,
            submitForm,
            sendCodeVerify,
            sendCodeHttp
        };
    }
}
</script>

<style lang="scss">
.login-code-model {
    .code-form-item {
        position: relative;
        .el-form-item__content {
            .el-input {
                .el-input__inner {
                    padding-right: 100px !important;
                }
            }
            .el-button {
                position: absolute;
                right: 10px;
            }   
        }
    }
}
</style>