<template>
    <que-button class="finish-button" :isAct="true" :isButtonLoading="isButtonLoading" :text="buttonText" @buttonClick="submitPaper" v-if='getQuesitionData.pageType == 1'></que-button>
</template>

<script>
import { examInfoConfigPost } from '@/http/api'
import { mapGetters } from 'vuex'
import QueButton from './QueButton.vue'
export default {
    components: {
        QueButton
    },

    data() {
        return {
            isButtonLoading: false
        }
    },

    computed: {
        ...mapGetters([
            'getQuesitionData'
        ]),

        buttonText() {
            return `提交答题卡${this.getQuesitionData.answerSheetIndex + 1}`
        },

        takeTime() {
            return this.getQuesitionData.quesitionInfoData.time * 60 - this.getQuesitionData.currentTime
        }
    }, 

    methods: {
        submitPaper () {
            let tip = ''
            if (this.getQuesitionData.isMockExamType) {
                if (this.getQuesitionData.answerSheetIndex + 1 == this.getQuesitionData.quesitionData.length) {
                    this.$message({
                        message: "模拟考试，不能提交",
                        type: "warning",
                        showClose: true
                    });
                } else {
                    let newPaperParams = JSON.parse(sessionStorage.getItem('newPaperParams'))
                    let answerSheetIndex = newPaperParams.answerSheetIndex ? newPaperParams.answerSheetIndex : 0
                    answerSheetIndex = answerSheetIndex + 1
                    this.$store.dispatch('setActQueData', this.getQuesitionData.quesitionData[answerSheetIndex][0].list[0])
                    this.$store.dispatch('setAnswerSheetIndex', answerSheetIndex) 
                }
                return
            }
            if (this.getQuesitionData.progress != 100) {
                tip = `还有试题未答完，确认提交答题卡${this.getQuesitionData.answerSheetIndex + 1}吗？一旦提交就不能再作答或者修改答案啦！`
            } else {
                tip = `确定提交答题卡${this.getQuesitionData.answerSheetIndex + 1}吗？一旦提交就不能再作答或者修改答案啦！`
            }
            this.$messageBox.confirm(tip, '提示', {
                confirmButtonText: '确认提交',
                cancelButtonText: '继续答题',
                type: 'warning',
                customClass: 'default-dialog submit-message'
                // custom-class: 'default-dialog'
            }).then(() => {
                let userAnswer = {}
                // 遍历外层数组
                for (const outerItem of this.getQuesitionData.quesitionData) {
                    // 遍历内层数组
                    for (const innerItem of outerItem) {
                        // 遍历 list 数组
                        for (const listItem of innerItem.list) {
                            // 将 id 和 num 放入 resultObject
                            userAnswer[listItem.questionId] = listItem.userAnswer ? listItem.userAnswer : '';
                        }
                    }
                }
                this.requestPaper(userAnswer)
            }).catch(() => {
                // 继续答题
            })
        },

        requestPaper(userAnswer) {
            let formData = new FormData();
            //参数
            formData.append("type", 2);
            formData.append("answer", JSON.stringify(userAnswer));
            formData.append("userId", this.getQuesitionData.uid);
            formData.append("examId", this.getQuesitionData.examId);
            formData.append("answerTime", this.takeTime);
            formData.append("role", this.getQuesitionData.role);
            this.isButtonLoading = true
            examInfoConfigPost(formData).then(res => {
                this.isButtonLoading = false
                this.$message({
                    message: "提交成功！",
                    type: "success",
                    showClose: true
                });
                setTimeout(() => {
                    if (this.getQuesitionData.answerSheetIndex + 1 == this.getQuesitionData.quesitionData.length) {
                        sessionStorage.removeItem('ticketNumber')
                        this.$router.replace({name: this.getQuesitionData.routeName})
                    } else {
                        let newPaperParams = JSON.parse(sessionStorage.getItem('newPaperParams'))
                        let answerSheetIndex = newPaperParams.answerSheetIndex ? newPaperParams.answerSheetIndex : 0
                        answerSheetIndex = answerSheetIndex + 1
                        newPaperParams.answerSheetIndex = answerSheetIndex
                        sessionStorage.setItem('newPaperParams', JSON.stringify(newPaperParams))
                        this.$store.dispatch('setActQueData', this.getQuesitionData.quesitionData[answerSheetIndex][0].list[0])
                        this.$store.dispatch('setAnswerSheetIndex', answerSheetIndex) 
                    }
                }, 1000)
            }).catch(() => {
                this.isButtonLoading = false
            })
        }
    }
}
</script>

<style lang="scss">
.submit-message {
    padding-bottom: 30px;
    .el-message-box__header {
        position: relative;
        height: 36px;

        .el-message-box__title {
            display: none;
        }

        .el-message-box__headerbtn {
            .el-message-box__close {
                font-size: 32px;
            }
        }
    }

    .el-message-box__content {
        padding-top: 30px;
        min-height: 95px;
        word-wrap: break-word;
        overflow: hidden;
        padding: 10px 15px;
        font-size: 16px;
        color: #000;

        .el-icon {
            display: none;
        }
    }

    .el-message-box__btns {
        text-align: center;
        justify-content: space-between;
        padding: 5px 30px 0;

        .el-button {
            width: 170px;
            height: 42px;
            box-sizing: border-box;
            border-radius: 100px;
            font-size: 16px;
            background-color: var(--el-color-primary);
            border-color: var(--el-color-primary);
            color: #fff;
        }

        .el-button--primary {
            color: var(--el-color-primary);
            border-width: 2px;
            border-color: var(--el-color-primary);
            color: var(--el-color-primary);
            background-color: #fff;
            margin: 0;
        }
    }
}
</style>