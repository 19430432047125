<template>
  <div class="ub ub-ver example-avatar">
        <el-image :src="require('../assets/image/example_avatar.png')" />
        <div class="ub ub-be ">
            <div>示例图</div>
            <div class="tutorial" @click="tutorialTap">教程</div>
        </div>
    </div>
    <el-dialog
        v-model="tutorialDialogVisible"
        title=""
        width="50%"
        destroy-on-close
        center
        custom-class="tutorial-dialog"
        @close="cancelTipDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        append-to-body
        >

        <el-carousel class="tutoria-swiper" :autoplay="false">
          <el-carousel-item v-for="item in tutorialStep" :key="item">
            <div class="model-title">{{ item.title }}</div>
            <el-image :src="item.src" fit="cover"/>
          </el-carousel-item>
        </el-carousel>
        <template #footer>
            <span class="dialog-footer">
                <!-- <el-button @click="tutorialDialogVisible = false">关闭</el-button> -->
            </span>
        </template>
    </el-dialog>
</template>

<script>
import { ref } from 'vue'
export default {
    setup() {
        const tutorialDialogVisible = ref(false)
        const tutorialStep = [{
            title: '步骤一：开始制作报名照片,手机找到报名电子照助手',
            src: require('../assets/image/tutorial_step1.png')
        }, {
            title: '步骤二',
            src: require('../assets/image/tutorial_step2.png')
        }, {
            title: '步骤三',
            src: require('../assets/image/tutorial_step3.png')
        }, {
            title: '步骤四',
            src: require('../assets/image/tutorial_step4.png')
        }]
        /**
         * 上传图片示例教程
         */
        function tutorialTap() {
            tutorialDialogVisible.value = true
        }
        return {
            tutorialDialogVisible,
            tutorialStep,
            tutorialTap
        }
    }
}
</script>

<style lang="scss">
.example-avatar {
    color: #a0a0a0;
    .tutorial {
        color: #0e9bee;
        text-decoration: underline;
        cursor: pointer;
    }
}
.tutorial-dialog {
    background-color: inherit !important;
    box-shadow: none !important;
    .el-dialog__header {
        .el-dialog__close {
            font-size: 40px;
            color: #ffffff;
        }
    }
    .tutoria-swiper {
    .el-carousel__container {
        height: 626px;
        .el-carousel__arrow {
            width: 45px;
            height: 92px;
            background-color: initial;
            background-image: url('../assets/image/swiper_right_btn_bg.png');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 0;
                i {
                    display: none;
                }
        }
        .el-carousel__arrow--left {
            transform: translateY(-50%) rotate(180deg);
            left: 0;
        }
        .el-carousel__arrow--right {
            right: 0;
        }
        .el-carousel__item {
            text-align: center;
            .model-title {
                color: #ffffff;
                margin-bottom: 25px;
            }
            .el-image {
                max-width: 300px;
            }
        }
    }
    .el-carousel__indicators {
        bottom: 10px;
        .el-carousel__indicator {
            .el-carousel__button {
                background-color: #e2e2e2;
                opacity: 1;
                width: 12px;
                height: 12px;
                border-radius: 30px;
            }
        }
        .el-carousel__indicator.is-active {
            .el-carousel__button  {
                width: 45px;
                height: 12px;
            }
        }
    }
    }
}
</style>