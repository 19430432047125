import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import './assets/icon/iconfont.css'
import './assets/css/default.css'
import './assets/css/common.css'

const loggedInUser = JSON.parse(localStorage.getItem('userAccount'));

if (loggedInUser) {
  store.commit('setUserAccount', loggedInUser);
}

import TheHeader from './components/TheHeader'
const app = createApp(App)
app.use(router)
app.use(store)
app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.component('the-header', TheHeader);
app.mount('#app')