<template>
    <div class="query-exam">
        <SecondModel2>
            <template v-slot:model-content>
                <SecondModelTitle :breadcrumbList="breadcrumbList"></SecondModelTitle>
                <el-form class="query-form" :model="formData" :rules="rules" ref="formRef" label-position="top"  label-width="100px" v-if="isQueryExam">
                    <el-form-item prop="ticketNumber" label="准考证号">
                        <el-input v-model="formData.ticketNumber" clearable placeholder="请输入准考证号" autocomplete="new-ticketNumber"></el-input>
                    </el-form-item>
                    <el-button class="submit-button" type="primary" round @click="submitForm">确认</el-button>
                </el-form>
                <div class="exam-countdown ub ub-ac ub-ver" v-else>
                    <div class="title">考试倒计时</div>
                    <div class="countdown-time">{{formattedTime}}</div>
                    <div class="button-handle ub">
                        <el-button class="submit-button" plain type="primary" round @click="refreshPage">刷新页面</el-button>
                        <el-button :disabled="isExamBtnDisabled" class="submit-button" type="primary" round @click="queryHttp">进入答题</el-button>
                    </div>
                    <div class="tip">倒计时结束后，如果未自动进入答题页面，手动点击【刷新页面】后再点击【进入答题】按钮可进入答题。</div>
                </div>
            </template>
        </SecondModel2>
    </div>
</template>
<script>
import { reactive, ref, nextTick, computed, onBeforeUnmount, onMounted } from 'vue'
import SecondModel2 from '../components/SecondModel2.vue'
import SecondModelTitle from '../components/SecondModelTitle.vue'
import { queryAdmissionNumber } from '@/http/api'
import { useRoute, useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';

export default {
  components: { SecondModel2, SecondModelTitle },
  setup() {
    const router = useRouter()
    const route = useRoute();
    const breadcrumbList = reactive([{
        title: '进入考试',
        path: ''
    }])
    const isExamBtnDisabled = ref(true)
    const formRef = ref(null); // 创建对 form 元素的引用
    const formData = ref({
        ticketNumber: ''
    });
    const rules = reactive({
        ticketNumber: [
            { required: true, message: '请输入准考证号', trigger: 'blur' }
        ]
    });
    const isQueryExam = ref(true)
    // 考试倒计时
    const countdown = ref(0); // 设置倒计时的初始时间（秒）
    let timer; // 用于存储 setInterval 的 ID
    
    // 格式化倒计时时间为 mm:ss 格式
    const formattedTime = computed(() => {
        const day = Math.floor(countdown.value / (24 * 60 * 60));
        const hours = Math.floor((countdown.value % (24 * 60 * 60))  / 60 / 60);
        const minutes = Math.floor((countdown.value % 3600) / 60);
        const seconds = countdown.value % 60;
        return `${day > 0 ? day.toString().padStart(2, '0') + '天' : ''}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    });
    // 更新倒计时
    const updateCountdown = () => {
        if (countdown.value > 0) {
            countdown.value--;
        } else {
            clearInterval(timer);
            queryHttp()
        }
    };

    onMounted(() => {
        let ticketNumber = sessionStorage.getItem('ticketNumber')
        if (ticketNumber) {
            formData.value.ticketNumber = ticketNumber
            queryHttp()
        }
    })

    
    // 在组件即将被卸载前执行的逻辑
    onBeforeUnmount(() => {
      if (!isQueryExam.value) {
        // 在倒计时界面
      } else {
        sessionStorage.removeItem('ticketNumber')
      }
      // 在这里执行一些清理工作或发送请求，确保在组件销毁前完成
    });
    const submitForm = async () => {
        // 表单提交逻辑
        // ...

        // 等待 nextTick 后再执行校验操作
        await nextTick();

        // 校验表单
        if (formRef.value) {
            formRef.value.validate(valid => {
                if (valid) {
                    // 校验通过，执行表单提交逻辑
                    // ...
                    queryHttp()
                }
            });
        }
    };

    function queryHttp() {
        queryAdmissionNumber({
            admission: formData.value.ticketNumber
        }).then((res) => {
            clearInterval(timer);
            if (res.data.timeDifference > 0) {
                if (res.data.timeDifference > 600) {
                    // 距离开始考试大于10分钟不让进
                    ElMessage({
                        message: '未到考试时间，请稍后再试',
                        type: 'warning',
                    })
                    return
                }
                if (isQueryExam) {
                    sessionStorage.setItem('ticketNumber', formData.value.ticketNumber)
                }
                
                isQueryExam.value = false
                countdown.value = res.data.timeDifference
                timer = setInterval(updateCountdown, 1000);
                
            } else {
                isExamBtnDisabled.value = false
                toExam(res.data)
            }
        }).catch((err) => {
        })
    }

    function toExam(data) {
        const params = {
            examId: data.examId,
            type: 1,
            uid: data.orderInfo.userId,
            pageName: data.examinationType,
            routeName: route.name,
            competitionId: data.competitionId,
            isMockExamType: '',
            role: data.role,
        }
        router.push({ name: 'ModelTest', params: params })
    }

    /**
     * 刷新界面 
     */
    function refreshPage() {
        window.location.reload();
    }
    return {
        breadcrumbList,
        formRef,
        formData,
        rules,
        submitForm,
        isQueryExam,
        formattedTime,
        queryHttp,
        refreshPage,
        isExamBtnDisabled
    }
  }
}
</script>
<style lang="scss">
    .query-exam {
        .query-form {
            width: 405px;
            margin: 80px auto 0;
            .el-form-item {
                margin-bottom: 40px;
                .el-form-item__label {
                    color: #0e9bee; 
                    font-size: 22px;
                    font-weight: bold;
                    margin-bottom: 30px !important;
                }
                .el-form-item__content {
                    .el-input {
                        .el-input__wrapper {
                            padding: 0;
                            border-radius: 5px;
                            overflow: hidden;
                            box-shadow: inset 0 0 8px 2px #ededed;
                            .el-input__inner {
                                height: 40px;
                                line-height: 40px;
                                font-size: 16px;
                                padding: 0 10px;
                            }
                            .el-input__suffix {
                                padding-right: 5px;
                            }
                        }
                    }
                }
            }
            .submit-button {
                width: 120px;
                height: 40px;
                font-size: 18px;
                margin: 37px auto 0;
                display: block;
            }
        }
        .exam-countdown {
            margin-top: 80px;
            .title {
                font-size: 22px;
                font-weight: bold;
            }
            .countdown-time {
                font-size: 28px;
                font-weight: bold;
                color: #ff632d;
                margin: 50px;
            }
            .tip {
                font-size: 14px;
                color: #ff632d;
                margin-top: 90px;
            }
        }
    }
</style>