<template>
<!-- 判断题 -->
   <div class="que-option type3">
      <el-radio-group class="ub ub-ver" v-model="getAnswer" @change="changeSelect" :disabled="getQuesitionData.pageType != 1">
        <div class="option-item" v-for="(item, index) in getQuesitionData.actQueData.optionList" :key="index">
            <el-radio :label="item.serialNum">

                <div class="judge-item">
                           <span v-if="index!=1" class="iconfont icon-zhengque zhengque-img"></span>
                            <span v-if="index!=0" class="iconfont icon-cuowu cuowu-img"></span>
                </div>
            </el-radio>
        </div>
      </el-radio-group>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
   
    };
  },
  created() {

  },
 computed: {
        ...mapGetters([
        'getQuesitionData'
        ]),
        
       getAnswer: {
           get: function () {
               if (this.getQuesitionData.pageType == 1) {
                   return this.getQuesitionData.actQueData.userAnswer
               } else if (this.getQuesitionData.pageType == 2){
                   return this.getQuesitionData.actQueData.useAnswerRecord
               } else if (this.getQuesitionData.pageType == 4) {
                   return this.getQuesitionData.actQueData.optionAnswer
               } else {
                 return ''
               }
           },

           set: function (val) {
               this.getQuesitionData.actQueData.userAnswer = val
           }
       }
   },
  methods: {
    changeSelect(e) {
    },
  },
};
</script>

<style lang="scss">
.que-option {

  .option-item{
    margin-top: 16px;

    .el-radio {
      .judge-item {
        display: inline-block;
        padding: 15px 15px 15px 5px;
        color: #000;
        margin: 0px 0;
            .zhengque-img {
              margin-left: 8px;
              font-size: 30px;
              color: #1FAB2E;
            }
            .cuowu-img {
              margin-left: 8px;
              font-size: 30px;
              color: #E70012;
            }
      }
    }

    .el-radio.is-checked {
      .judge-item {
        // background-color: var(--el-color-second);
        border-radius: 3px;
      }
      
    }
   
  }
}
</style>