<template>
  <div class="step-application-info ub ub-ver">
    <div class="ub">
      <el-image :src="userAccount.userImg" fit="contain" />
      <div class="user-info" v-if="dataInfo">
        <div class="user-cell">姓名：{{ userAccount.userName }}</div>
        <div class="user-cell">性别：{{ dataInfo.sex }}</div>
        <div class="user-cell">证件类型及号码：{{ dataInfo.idCardType }} {{ dataInfo.userIdCard }}</div>
        <div class="user-cell">就读学校：{{ userAccount.attendingCampus }}</div>
        <div class="user-cell">年级：{{ userAccount.userGrade }}</div>
        <div class="user-cell">紧急联系人：{{ dataInfo.contactName }}</div>
        <div class="user-cell">紧急联系电话：{{ dataInfo.contactTel }}</div>
        <div class="user-cell">报考科目：{{ dataInfo.competitionName
 }}</div>
        <div class="user-cell">考点名称：{{ dataInfo.keynoteName }}</div>
        <div class="user-cell">考试时间：{{ dataInfo.time }}</div>
        <div class="tip">注：如需更改个人信息，请先去个人中心修改</div> 
      </div>
    </div>
    <div class="buttons ub ub-be">
      <el-button class="prev-button" type="primary" round plain @click="submitPrev">上一步</el-button>
      <el-button class="save-button" type="primary" round @click="submitSave" v-if="dataInfo">确认无误，提交报考信息</el-button>
    </div>
    
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import store from '@/store'
import { upDetermineInfo, exhibitionConfirMenter } from '@/http/api'
import { ElMessage } from 'element-plus';
import dataJs from '@/utils/data'
export default {
  props: {
    competitionId: {
        type: Number,
        default: null
    },
    currentNum: {
      type: Number,
      default: null
    }
  },
  setup(props, {emit}) {
    const dataInfo = ref(null)
    const userAccount = computed(() => store.getters.userAccount)

    /**
     * 使用 watch 监听 props 的变化
     */
    watch(props, (newProps) => {
      if (newProps.currentNum == 2) {
        getData()
      }
    });

    function submitSave() {
      upDetermineInfo({
          userId: userAccount.value.userId,
          competitionId: props.competitionId
      }).then((res) => {
          ElMessage({
            message: '确认成功',
            type: 'success',
          })
          setTimeout(() => {
            emit('stepChange', 3)
          }, 2000)
      }).catch((err) => {
      })

    };

    /**
     * 获取报名数据
     */
     function getData() {
      exhibitionConfirMenter({
          userId: userAccount.value.userId,
          competitionId: props.competitionId
      }).then((res) => {
          const resData = res.data
          const userSex = dataJs.gender.find(item => item.id == resData.sex).name;
          const userCardType = dataJs.idCardType.find(item => item.id == resData.idCardType).name;
          resData.sex = userSex
          resData.idCardType = userCardType
          dataInfo.value = resData
      }).catch((err) => {
      })
     }

    /**
     * 上一步
     */
    function submitPrev() {
      emit('stepChange', 1)
    }
    return {
      dataInfo,
      userAccount,
      submitPrev,
      submitSave
    }

  }
}
</script>

<style lang="scss">
.step-application-info {
  width: 620px;
  margin: 0 auto;
  .el-image {
    width: 120px;
    height: 156px;
    margin-right: 85px;
  }
  .user-info {
    line-height: 24px;
    color: #707070;
    .user-cell {
      margin-bottom: 25px;
    }
    .tip {
      color: #ff632d;
      font-size: 14px;
      margin-top: 40px;
    }
  }
  .buttons {
    padding: 0 40px;
    margin-top: 78px;
    .el-button {
      height: 40px;
      width: 241px;
    }
  }
}
</style>