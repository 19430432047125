// AuthService.js

const ACCOUNT_KEY = 'userAccount';

export const AuthService = {
  login(userAccount) {
    // 将账号数据保存到本地缓存
    this.saveAccountToLocalStorage(userAccount);
    
    return userAccount;
  },
  
  saveAccountToLocalStorage(account) {
    // 使用JSON.stringify将JavaScript对象转换为字符串，并存储在localStorage中
    localStorage.setItem(ACCOUNT_KEY, JSON.stringify(account));
  },
  
  getAccountFromLocalStorage() {
    // 从localStorage中获取存储的账号数据
    const accountJSON = localStorage.getItem(ACCOUNT_KEY);
    return accountJSON ? JSON.parse(accountJSON) : null;
  },
  
  logout() {
    // 清除本地缓存中的账号数据
    localStorage.removeItem(ACCOUNT_KEY);
  }
};
