/**
 * 验证是否为空
 * @param {*} value 
 */
 const isDefine = (value) => {
    if (value == null || value == "" || value == "undefined" || value == undefined || value == "null" || value == "(null)" || value == 'NULL' || typeof(value) == 'undefined') {
      return false;
    } else {
      value = value + "";
      value = value.replace(/\s/g, "");
      if (value == "") {
        return false;
      }
      return true;
    }
}

/**
 * 时间戳转换
 * @param {*} times 时间戳数据
 * @param {*} type  返回格式 1 带时分 2 不带时分 3显示年月日格式
 * @param {String} symbol 符号
 * @returns 
 */
const formateDateTimesToZh = (times, type, symbol) => {
    symbol = symbol || '-'
  if (!times) {
    return '-'
  }
  let date = new Date(times) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let year = date.getFullYear()
  let mounth = date.getMonth() + 1
  mounth = mounth > 9 ? mounth : '0' + mounth
  let day = date.getDate()
  day = day > 9 ? day : '0' + day
  let timeStr = '' 
  if (type == 1) {
    let hours = date.getHours()
    hours = hours > 9 ? hours : '0' + hours
    let mimutes = date.getMinutes()
    mimutes = mimutes > 9 ? mimutes : '0' + mimutes
    timeStr = `${year}${symbol}${mounth}${symbol}${day} ${hours}:${mimutes}`
  } 
  else if (type==2){
    timeStr = `${year}${symbol}${mounth}${symbol}${day}`
  }
  else if(type==3){
    timeStr = `${year}年${mounth}月${day}日`
  }
  return timeStr
}
   
/**
 * 删除字符串左右空格
 */
const trim = (str) => {
  return str.replace(/(^\s*)|(\s*$)/g, "");
}

/**
 * 验证手机号是否正确（包含港澳台）
 * @param {String} phoneNumber 
 * @returns 
 */
const validatePhoneNumber = (phoneNumber) => {
    // 去除字符串两端的空格
    phoneNumber = phoneNumber.trim();
  
    // 中国大陆手机号码正则表达式
    const chinaMainlandRegex = /^(?:(?:\+|00)86)?1\d{10}$/;
  
    // 香港手机号码正则表达式
    const hongKongRegex = /^(?:(?:\+852)|(?:852))\d{8}$/;
  
    // 澳门手机号码正则表达式
    const macauRegex = /^(?:(?:\+853)|(?:853))\d{8}$/;
  
    // 台湾手机号码正则表达式
    const taiwanRegex = /^(?:(?:\+886)|(?:886))\d{9,10}$/;
  
    // 验证中国大陆手机号码
    if (chinaMainlandRegex.test(phoneNumber)) {
      return '中国大陆手机号码验证通过';
    }
  
    // 验证香港手机号码
    if (hongKongRegex.test(phoneNumber)) {
      return '香港手机号码验证通过';
    }
  
    // 验证澳门手机号码
    if (macauRegex.test(phoneNumber)) {
      return '澳门手机号码验证通过';
    }
  
    // 验证台湾手机号码
    if (taiwanRegex.test(phoneNumber)) {
      return '台湾手机号码验证通过';
    }
  
    // 手机号码验证不通过
    return false;
}

/**
 * 验证密码是否是数组与字母组合
 * @param {String} phoneNumber 
 * @returns 
 */
const isAlphaNumericPhoneNumber = (phoneNumber) => {
    // 去除字符串两端的空格
    phoneNumber = phoneNumber.trim();
  
    // 定义正则表达式，匹配由数字和字母组成的手机号码
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    // 执行正则匹配
    return regex.test(phoneNumber);
}

  export default {
    isDefine,
    formateDateTimesToZh,
    trim,
    validatePhoneNumber,
    isAlphaNumericPhoneNumber
}

