<template>
  <div class="step-pay">
    <div class="pay ub ub-ver" v-if="dataInfo && dataInfo.status == 0">
        <h2 class="ub ub-pc ub-ac"><span class="ub ub-ac ub-pc"><el-icon><SuccessFilled /></el-icon></span>资料审核已通过，请在规定时间内完成缴费</h2>
        <step-pay-order-info :dataInfo="dataInfo"></step-pay-order-info>
        <div class="pay-type ub">
            <el-radio-group v-model="payTypeRadio">
                <el-radio :label="1" size="large">
                    <div class="ub ub-ac">
                        <svg class="icon" aria-hidden="true"> 
                            <use xlink:href="#icon-zhifubaozhifu"></use> 
                        </svg>
                        支付宝支付
                    </div>
                </el-radio>
                <el-radio :label="2" size="large">
                    <div class="ub ub-ac">
                        <svg class="icon" aria-hidden="true"> 
                            <use xlink:href="#icon-weixin"></use> 
                        </svg>
                        微信支付
                    </div>
                </el-radio>
            </el-radio-group>
        </div>
        <div class="ub ub-pe ub-ac pay-button">
            <el-checkbox v-model="isAgree" label="我已阅读并同意" size="large" /><span class="contract" href="" @click="contractTap">合同条款</span>
            <el-button class="" type="primary" round @click="payTap">确认支付</el-button>
        </div>
    </div>
    <div class="pay-success ub ub-ver ub-ac" v-if="dataInfo && dataInfo.status == 1">
        <h2 class="ub ub-pc ub-ac"><span class="ub ub-ac ub-pc"><el-icon><SuccessFilled /></el-icon></span>支付成功</h2>
        <step-pay-order-info :dataInfo="dataInfo"></step-pay-order-info>
        <el-button class="" type="primary" round @click="nextButton">下一步</el-button>
    </div>
    <div class="pay-fail ub ub-ver ub-ac" v-if="dataInfo && dataInfo.status == -2">
        <h2 class="ub ub-pc ub-ac"><span class="ub ub-ac ub-pc"><el-icon><WarningFilled /></el-icon></span>订单已失效，如需重新报考，请在规定时间内重新报名并完成缴费流程</h2>
        <step-pay-order-info :dataInfo="dataInfo"></step-pay-order-info>
        <el-button class="" type="primary" round><router-link to="/personal/application-record">返回首页</router-link></el-button>
    </div>
    <!-- 微信支付二维码 -->
    <el-dialog
        v-model="payDialogVisible"
        :title="payTypeTitle"
        width="30%"
        :before-close="handleClose"
    >
        <div id="qrCode" v-if="payTypeRadio == 2"></div>
        <div class="pay-title">{{ payTypeTip }}</div>
        <template #footer>
            <span class="dialog-footer tx-c">
                <el-button @click="payDialogVisible = false">取消支付</el-button>
                <el-button type="primary" @click="payment">
                已支付
                </el-button>
            </span>
        </template>
    </el-dialog>
    <!-- 合同条款 -->
    <el-dialog
        v-model="contractDialogVisible"
        title=""
        width="80%"
        append-to-body
        custom-class="contract-dialog"
        :before-close="handleClose"
    >
        <div class="content">
            <h1>支付协议（合同条款）</h1>

            <p class="title">本协议由以下双方签署：</p>

            <p>甲方：青少年人工智能素养与创新能力测评（以下简称“测评中心”）</p>

            <p>乙方：（以下简称“考生”）</p>

            <p>鉴于考生参加由青少年人工智能素养与创新能力测评中心举办的编程能力等级考试，并需支付相应的考试费用，现双方经友好协商，就相关费用支付事宜达成如下协议：</p>

            <p class="title">一、服务内容</p>
            <p class="indent">测评中心将为考生提供计算机能力等级考试报名服务。</p>

            <p class="title">二、服务费用</p>
            <p class="indent">考生应向测评中心支付的服务费用为________元人民币。</p>

            <p class="title">三、支付方式：</p>
            <p class="indent">考生需在报名信息审核通过后XXXX个小时内完成支付，测评中心接受微信和支付宝等线上支付方式。</p>

            <p class="title">四、考试时间及地点</p>
            <p class="indent">1.测评中心根据实际情况安排考试时间及地点，并通过官方网站等方式通知考生。</p>
            <p class="indent">2.考生应按照测评中心通知的要求，准时到达考试地点参加考试。</p>

            <p class="title">五、保密协议</p>
            <p class="indent">1.测评中心对考生的个人信息及考试内容、评分标准等信息严格保密。</p>
            <p class="indent">2.未经测评中心同意，考生不得泄露考试内容及评分标准等信息。</p>

            <p class="title">六、违约责任</p>
            <p class="indent">1.如考生未按时缴纳考试费用，则视为自动放弃考试资格。</p>
            <p class="indent">2.如考生在考试过程中违反考试规定，测评中心有权取消其考试资格。</p>
            <p class="indent">3.如考生泄露考试内容及评分标准等信息，测评中心有权取消其考试成绩，并保留追究法律责任的权利。</p>

            <p class="title">七、考生须知</p>
            <p class="indent">1.考生在缴费前，需慎重核对报考信息和报考科目，确认无误后，再进行网上支付。</p>
            <p class="indent">2.考生本人理解并同意，网上支付系统由支付平台所有并运营，在付费过程中由于支付平台系统问题导致的任何异常情况，本平台不承担直接或间接责任。</p>
            <p class="indent">3.考生本人理解并同意，在报名成功后，因本网站系统出现问题影响考生本人参加考试的，本网站可能提供的补偿仅限于重新安排报名，不承担任何其他连带责任。</p>
            <p class="indent">4.考生本人理解并同意，在缴费完成后，考生不能修改个人信息及报考信息或退考。</p>
            <p class="indent">5.考生本人理解并同意，在报名成功后，由于考生个人原因而无法参加考试的，由考生个人承担相关责任，本平台不进行退费。</p>

            <p class="title">八、争议解决</p>
            <p class="indent">本协议的签订、执行和解释以及与本协议有关的争议，应适用中华人民共和国的法律。任何一方对本协议的违约或本协议的解释有争议的，应首先通过友好协商解决；协商不成的，任何一方均有权向甲方所在地的人民法院提起诉讼。</p>

            <p class="title">九、其他</p>
            <p class="indent">本协议自考生勾选之日起生效，有效期至甲方完成考试之日止。</p>
        </div>
        <template #footer>
            <span class="dialog-footer tx-c">
                <el-button @click="contractDialogVisible = false">关闭</el-button>
            </span>
        </template>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { ElMessage } from 'element-plus'
import '../../../assets/icon/iconfont.js'
import { computed, ref, watch } from 'vue'
import StepPayOrderInfo from './StepPayOrderInfo.vue'
import { wxPay, aliPay, selectOrderInfo } from '@/http/api'
import config from '../../../http/config'
import store from '@/store/index.js'

export default {
  components: { StepPayOrderInfo },
    props: {
        competitionId: {
            type: Number,
            default: null
        },
        currentNum: {
            type: Number,
            default: null
        }
    },
    setup(props, {emit}) {
        const payDialogVisible = ref(false)
        const payTypeRadio = ref(null)
        const isAgree = ref(false)
        const dataInfo = ref(null)
        const userAccount = computed(() => store.getters.userAccount)
        const contractDialogVisible = ref(false)
        const isReadContract = ref(false)

        /**
         * 使用 watch 监听 props 的变化
         */
         watch(props, (newProps) => {
            if (newProps.currentNum == 4) {
                getOrderData()
            }
        })

        const payTypeTitle = computed(() => {
            return payTypeRadio.value == 1 ? '支付宝支付' : payTypeRadio.value == 2 ? '微信支付' : ''
        })
        const payTypeTip = computed(() => {
            return payTypeRadio.value == 1 ? '请在支付宝界面完成支付' : payTypeRadio.value == 2 ? '微信扫码支付' : ''
        })
        
        function getOrderData() {
            selectOrderInfo({
                userId: userAccount.value.userId,
                competitionId: props.competitionId
            }).then((res) => {
                dataInfo.value = res.data
            })
        }

        function payTap() {
            if (!payTypeRadio.value) {
                ElMessage({
                    message: '请选择支付方式',
                    type: 'warning',
                })
                return 
            }

            if (!isAgree.value) {
                ElMessage({
                    message: '请阅读并同意合同条款',
                    type: 'warning',
                })
                return 
            }
            let pay
            if (payTypeRadio.value == 1) {
                payDialogVisible.value = true
                window.open(`${config.aliPay}?orderId=${dataInfo.value.orderId}`, '_blank')
                return
            } else {
                pay = wxPay ({
                    orderId: dataInfo.value.orderId
                })
            }

            pay.then((res) => {
                payDialogVisible.value = true
                setTimeout(() => {
                    //确保这里的dom能访问到
                    let qrCode = document.getElementById('qrCode')
                    qrCode.innerHTML = ''
                    new QRCode(qrCode, {
                        width: 200,
                        height: 200,
                        text: res.data.code_url,
                    })
                })
            }).catch((err) => {
            })

        }

        function nextButton() {
            emit('stepChange', 5)
        }

        function payment() {
            location.reload();
        }

        function contractTap() {
            contractDialogVisible.value = true
            isReadContract.value = true
        }
        return {
            payDialogVisible,
            payTypeRadio,
            isAgree,
            dataInfo,
            payTap,
            payTypeTitle,
            payTypeTip,
            nextButton,
            payment,
            contractTap,
            contractDialogVisible
        }
    }
}
</script>

<style lang="scss">
.step-pay {
    width: 813px;
    margin: 0 auto;
    h2 {
        text-align: center;
        font-weight: bold;
        margin-bottom: 40px;
        span {
            width: 47px;
            height: 47px;
            line-height: 47px;
            font-size: 40px;
            font-weight: bold;
            border-radius: 50%;
            color: #0e9bee;
            margin-right: 10px;
        }
    }
    .pay-type {
        margin-top: 35px;
        svg {
            width: 35px;
            height: 35px;
            margin-right: 15px;
            margin-left: 5px;
        }
    }
    .pay-button {
        width: 100%;
        margin-top: 50px;
        color: #666666;
        font-size: 14px;
        .el-checkbox {
            margin-right: 8px;
        }
        .contract {
            font-size: 14px;
            color: #0e9bee;
            font-weight: bold;
            cursor: pointer;
        }
        .el-button {
            height: 40px;
            width: 114px;
            margin-left: 40px;
            a {
                color: #ffffff;
            }
        }
    }

    .pay-success {
        h2 {
            span {
                color: #67c23a;
            }
        }
        .pay-order-info {
            .info-cell.tip {
                display: none;
            }
            .countdown {
                display: none;
            }
        }
        .el-button {
            height: 40px;
            width: 241px;
            margin-top: 87px;
            a {
                color: #ffffff;
            }
        }
    }

    .pay-fail {
        h2 {
            span {
                color: #ff632d;
            }
        }
        .pay-order-info {
            &::before {
                content: '';
                width: 144px;
                height: 64px;
                background-image: url("../../../assets/image/invalid_bg.png");
                background-size: 100%;
                position: absolute;
                right: 52px;
                top: 76px;
                transform: rotate(15deg);
            }
            .info-bottom {
                .countdown {
                    display: none;
                }
            }
        }

        .el-button {
            height: 40px;
            width: 241px;
            margin-top: 87px;
            a {
                color: #ffffff;
            }
        }
    }
    .el-overlay {
        .el-dialog {
            .el-dialog__body {
                #qrCode {
                    img {
                        margin: 0 auto;
                    }
                }
                .pay-title {
                    text-align: center;
                    font-size: 18px;
                    font-weight: bold;
                    margin-top: 10px;
                }
            }
            .el-dialog__footer {
                text-align: center;
            }
        }
    }
}

.contract-dialog {
    h1 {
        text-align: center;
    }
    p {
        margin: 10px 0;
    }
    p.title {
        font-weight: bold;
    }
    p.indent {
        text-indent: 2em;
    }
}
</style>