// store.js

import { createStore } from 'vuex';

// Create a new Vuex store instance
const store = createStore({
  state() {
    return {
        userAccount: '',
        quesition: {
          pageType: 1, // 1 考试 2 解析 3 批改 4 考情
          pageName: '',
          routeName: '',
          examId: 20101,
          competitionId: '',
          classId: 132,
          role: 1, // 1 学生 2 老师
          progress: 0,
          currentTime: 0,
          actQueData: {},
          quesitionInfoData: {},
          quesitionData: [],
          uid:'', // 批改试卷参数 用户id
          correctUsers: 0, // 待批改人数,
          answerSheetIndex: 0 // 答题卡
        } 
    };
  },
  mutations: {
    setUserAccount(state, userInfo) {
        localStorage.setItem('userAccount', JSON.stringify(userInfo))
        state.userAccount = userInfo
    },
    // quesition
    setPaperParams (state, params) {
      state.quesition.pageType = params.type
      state.quesition.examId = params.examId
      state.quesition.competitionId= params.competitionId
      state.quesition.classId = params.classId
      state.quesition.uid = params.uid
      state.quesition.role = params.role
      state.quesition.pageName = params.pageName,
      state.quesition.routeName = params.routeName,
      state.quesition.progress = 0,
      state.quesition.isMockExamType = params.isMockExamType,
      state.quesition.competitionType = params.competitionType,
      state.quesition.answerSheetIndex = params.answerSheetIndex ? params.answerSheetIndex : 0
    },
    setCurrentTime (state, num) {
        state.quesition.currentTime = num
    },

    setActQueData (state, data) {
        state.quesition.actQueData = data ? data : {}
    },

    setQueInfoData (state, data, seartchQueData) {
        if (seartchQueData) {
            state.quesition.quesitionInfoData.scratchQuestionData = seartchQueData
            return 
        }
        state.quesition.quesitionInfoData = data
    },

    setQueData (state, data) {
        console.log(data)
        state.quesition.quesitionData = data
    },

    setProgress (state, num) {
        const total = state.quesition.quesitionData[state.quesition.answerSheetIndex].reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.totalQuestionCount);
          }, 0);
        state.quesition.progress = parseInt(num / total * 100)
    },

    setUserCorrectOrNot (state) {
        let userList = state.quesition.quesitionInfoData.userList
        let index = userList.findIndex((value) => value.userId === state.quesition.uid)
        if (index != -1) {
            userList[index].correctOrNot = 1
            state.quesition.quesitionInfoData.userList = userList
        }
        this.dispatch('setCorrectUsers')
    },

    setUid (state, uid) {
        state.quesition.uid = uid
    },

    setCorrectUsers (state) {
        let num = 0;
        for (let i = 0; i < state.quesition.quesitionInfoData.userList.length; i++) {
            if (state.quesition.quesitionInfoData.userList[i].correctOrNot == 0) {
                num += 1
            }
        }
        state.quesition.correctUsers = num
    },

    setAnswerSheetIndex(state, index) {
        state.quesition.answerSheetIndex = index
    }
  },
  actions: {
    // quesition
    setPaperParams(context, info) {
        context.commit('setPaperParams', info)
    },

    setCurrentTime(context, num) {
        context.commit('setCurrentTime', num)
    },

    setActQueData(context, data) {
        context.commit('setActQueData', data)
    },

    setQueInfoData(context, data, seartchQueData) {
        context.commit('setQueInfoData', data, seartchQueData)
    },

    setQueData(context, data) {
        context.commit('setQueData', data)
    },

    setProgress(context, data) {
        context.commit('setProgress', data)
    },

    setUserCorrectOrNot(context, data) {
        context.commit('setUserCorrectOrNot', data)
    },

    setCorrectUsers(context) {
        context.commit('setCorrectUsers')
    },

    setUid(context, uid) {
        context.commit('setUid', uid)
    },

    setAnswerSheetIndex(context, index) {
        context.commit('setAnswerSheetIndex', index)
    }
  },
  getters: {
    userAccount: state => state.userAccount,
    // quesition
    getQuesitionData: state => state.quesition
  },
});

export default store;
