<template>
  <div class="download-file">
    <second-model-2>
        <template v-slot:model-content>
            <second-model-title :breadcrumbList="breadcrumbList"></second-model-title>
            <file-cell v-for="(item) in downloadList" :key="item.version" :item="item"></file-cell>
            <div v-if="downloadList.length == 0">暂无数据</div>
        </template>
    </second-model-2>
    <the-footer></the-footer>
  </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import SecondModel2 from '../components/SecondModel2.vue'
import SecondModelTitle from '../components/SecondModelTitle.vue'
import FileCell from './components/FileCell.vue'
import TheFooter from '@/components/TheFooter.vue'
import { downloadInfo } from '@/http/api'
export default {
  components: { SecondModel2, SecondModelTitle, FileCell, TheFooter },
  setup() {
    const breadcrumbList = reactive([{
      title: '下载专区',
      path: ''
    }])
    const downloadList = reactive([])
    onMounted(() => {
      getData()
    })
    function getData() {
      downloadInfo({
      }).then((res) => {
          downloadList.length = 0
          downloadList.push(...res.data);
      }).catch((err) => {
      })
    }
    return {
        breadcrumbList,
        downloadList
    }
  }
}
</script>

<style>

</style>