<template>
  <div class="the-footer">
    <div class="container ub ub-be">
        <div class="footer-menu">
            <ul>
                <li class="title"><router-link to="/evaluation">在线测评</router-link></li>
                <li><router-link to="/evaluation/scratch">Scratch</router-link></li>
                <li><router-link to="/evaluation/python">Python</router-link></li>
                <li><router-link to="/evaluation/c++">C++</router-link></li>
            </ul>
        </div>
        <div class="qrcode ub ub-ac ub-ver">
            <el-image :src="qrcodeUrl" />
            <div class="title">青少年人工智能素养与创新能力测评</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    setup() {
        const qrcodeUrl = require('../assets/image/qrcode_bg.png')
        return {
            qrcodeUrl
        }
    }
}
</script>

<style lang="scss">
.the-footer {
    background-color: #ffffff;
    margin-top: 30px;
    padding: 36px 0;
    .container {
        padding: 0 45px;
        box-sizing: border-box;
        .footer-menu {
            ul {
                li {
                    line-height: 32px;
                    font-size: 16px;
                    a {
                        color: #707070;
                    }
                }

                li.title {
                    font-size: 18px;
                    margin-bottom: 5px;
                    a {
                        color: #000000;
                    }
                }
            }
        }
        .qrcode {
            .title {
                font-size: 14px;
                margin-top: 15px;
                color: #707070;
            }
        }
    }
}
</style>