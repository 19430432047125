<template>
  <div class="second-model">
    <the-header></the-header>
    <div class="second-container container ub">
        <div class="second-left-content">
            <left-menu></left-menu>
        </div>
        <div class="second-right-content ub-f1">
            <slot name="right-content"></slot>
        </div>
    </div>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import LeftMenu from './LeftMenu.vue'
export default {
  components: { TheHeader, LeftMenu },

}
</script>

<style lang="scss">
.second-model {
    .second-container {
        margin-top: 15px;
        margin-bottom: 15px;
        min-height: 800px;
        .second-left-content {
            width: 245px;
            background-color: #ffffff;
            margin-right: 15px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        .second-right-content {
           background-color: #ffffff;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
           padding: 12px 40px;
           box-sizing: border-box;
        }
    }
}
</style>