<template>
 <div class="quesition-select">
     <div class="quesition-type-title">{{title}}</div>
     <div class="quesition-type-info">{{subTitle}}</div>
     <QueSelectList :actQueList="actQueList"></QueSelectList>
 </div>
</template>

<script>
import QueSelectList from './QueSelectList.vue'
import { mapGetters } from 'vuex'
 export default {
   props: ['title', 'subTitle', 'actQueList'],
   data () {
     return {
       
     }
   },
   components: {
    QueSelectList
   },

   computed: {
     ...mapGetters([
        'getQuesitionInfo'
      ])
   }
 }
</script>

<style lang="scss">
.quesition-select {
    text-align: center;
    padding: 0 15px;
    box-sizing: border-box;

    .quesition-type-title {
        margin-top: 13px;
    }

    .quesition-type-info {
        font-size: 12px;
        line-height: 40px;
    }
}
 
</style>
