<template>
  <div :class="['pay-order-info ub ub-ver']" v-if="dataInfo">
    <div class="info-title">订单信息</div>
    <div class="info-cell tip">订单提交成功，请尽快付款</div>
    <div class="info-cell">订单号：{{ dataInfo.orderId }}</div>
    <div class="info-cell">考试名称：{{ dataInfo.orderName }}</div>
    <div class="info-cell">考试科目：{{ dataInfo.competitionName }}</div>
    <div class="info-bottom ub ub-be ub-ac">
        <div class="price">金额：<span>￥{{ dataInfo.salePrice }}</span></div>
        <div class="countdown">缴费倒计时：<span>{{ payFormattedTime }}</span></div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
export default {
    props: {
        dataInfo: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    setup(props, {emit}) {
        // payCountdown.value = Number(res.data.effectiveTime)
        // 支付倒计时
        let payTimer;
        // 格式化倒计时时间为 mm:ss 格式
        const payCountdown = ref(props.dataInfo.effectiveTime); // 设置倒计时的初始时间（秒）
        const payFormattedTime = computed(() => {
            const hours = Math.floor(payCountdown.value / 60 / 60);
            const minutes = Math.floor((payCountdown.value % 3600) / 60);
            const seconds = payCountdown.value % 60;
            return `${hours.toString().padStart(2, '0')}小时${minutes.toString().padStart(2, '0')}分${seconds.toString().padStart(2, '0')}秒`;
        });
        // 更新倒计时
        const payUpdateCountdown = () => {
            if (payCountdown.value > 0) {
                payCountdown.value--;
            } else {
                location.reload();
                clearInterval(payTimer);
            }
        };
        onMounted(() => {
            if (Number(props.dataInfo.effectiveTime) > 0 && props.dataInfo.status == 0) {
                payTimer = setInterval(payUpdateCountdown, 1000);
            }
        })
        return {
            payFormattedTime
        }
    }
}
</script>

<style lang="scss">
.pay-order-info {
    width: 813px;
    height: 319px;
    box-sizing: border-box;
    border-top: 5px solid #0e9bee;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    padding: 45px 43px;
    .info-title {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 13px;
    }
    .info-cell {
        line-height: 28px;
        color: #666666;
    }
    .info-bottom {
        margin-top: 30px;
        span {
            color: red;
        }
        .price {
            font-size: 24px;
            color: #707070;
            font-weight: bold;
            span {
                font-weight: 500;
            }
        }
        .countdown {
            color: #666666;
            font-weight: bold;
        }
    }
}
</style>