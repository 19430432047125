<template>
  <div class="file-cell ub">
    <el-image class="ub ub-ac ub-pc" :src="item.downloadImg" fit="cover"/>
    <div class="ub ub-ver ub-pc">
        <div class="file-title">{{ item.downloadName }} <span>版本号：{{ item.downloadVersion }}</span></div>
        <el-button type="primary" round @click="downloadTap">立即下载</el-button>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    setup(props) {
        function downloadTap() {
            const url = props.item.downloadUrl; // 替换为你想要下载的文件的 URL
            window.location.href = url;
        }
        return {
            downloadTap
        }
    }
}
</script>

<style lang="scss">
.file-cell {
    margin-bottom: 20px;
    .el-image {
        width: 122px;
        height: 122px;
        border: 1px solid #0e9bee;
        border-radius: 10px;
        margin-right: 40px;
        img {
            width: 92px;
            height: 92px;
            border-radius: 50%;
        }
    }
    .file-title {
        font-size: 18px;
        margin-bottom: 20px;
        span {
            margin-left: 20px;
            font-size: 16px;
            color: #a2a2a2;
        }
    }
    .el-button {
        width: 146px;
        height: 40px;
        font-size: 16px;
    }
}
</style>