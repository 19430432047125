<template>
  <div class="modify-account">
    <second-model-2>
        <template v-slot:model-content>
            <second-model-title :breadcrumbList="breadcrumbList"></second-model-title>
            <div class="current-tel">当前登录手机号：{{userAccount.userTel}}</div>
            <el-form class="modify-form" :model="formData" :rules="rules" ref="formRef" label-position="top"  label-width="100px">
                <el-form-item prop="phoneNumber" label="新登录手机号">
                    <el-input v-model="formData.phoneNumber" clearable placeholder="请输入登录手机号" autocomplete="new-phoneNumber"></el-input>
                </el-form-item>
                <el-form-item class="code-form-item" prop="code" label="验证码">
                    <el-input v-model="formData.code"  placeholder="请输入验证码"></el-input>
                    <el-button link @click="sendCodeVerify" :disabled="codeButtonDisabled">{{ codeButtonDisabled ? codeTimeSecond + 's' : '发送验证码' }}</el-button>
                 </el-form-item>
                <el-form-item prop="password" label="登录密码">
                    <el-input v-model="formData.password" clearable type="password" placeholder="请输入原密码" autocomplete="new-password" ></el-input>
                </el-form-item>
            </el-form>
            <el-button class="save-button" type="primary" round @click="submitForm">确认修改</el-button>
        </template>
    </second-model-2>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import { reactive, ref, computed, nextTick } from 'vue'
import SecondModel2 from '../components/SecondModel2.vue'
import SecondModelTitle from '../components/SecondModelTitle.vue'
import PrivacyInfo from '../components/PrivacyInfo.vue';
import commmonJs from '@/utils/common'
import { userReplacetel, userInfoController } from '@/http/api'
import { AuthService } from '@/utils/AuthService';
import store from '@/store';
export default {
  components: { SecondModel2, SecondModelTitle, PrivacyInfo },
    setup() {
    const breadcrumbList = reactive([{
      title: '修改账号',
      path: ''
    }])
    const userAccount = computed(() => store.getters.userAccount)
    const formRef = ref(null); // 创建对 form 元素的引用
    const codeButtonDisabled = ref(false)
    const codeButtonText = ref('')
    const codeTimeSecond = ref(300)
    const timer = ref(null)
    const formData = ref({
      phoneNumber: '',
      code: '',
      password: ''
    });
    const rules = reactive({
      phoneNumber: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        { validator: checkPhoneNumber, trigger: 'blur' },
      ],
      code: [
        { required: true, message: '请输入验证码', trigger: 'blur' },
      ],
      password: [
        { required: true, message: '请输入原密码', trigger: 'blur' },
        { min: 8, max:16, message: '请输入8~16位数字或字母组合密码', trigger: 'blur' },
        { validator: checkPassword, trigger: 'blur' },
      ]
    });
    function checkPhoneNumber(rule, value, callback) {
      if (!commmonJs.validatePhoneNumber(value)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback()
      }
    }
     /**
     * 验证密码
     * @param {*} rule 
     * @param {*} value 
     * @param {*} callback 
     */
    function checkPassword(rule, value, callback) {
      if (!commmonJs.isAlphaNumericPhoneNumber(value)) {
        callback(new Error('请输入8~16位数字或字母组合密码'));
      } else {
        callback()
      }
    }

    /**
     * 倒计时
     */
    function timeCountDown() {
        codeButtonDisabled.value = true;
        if (codeTimeSecond.value === 0) {
            initTimer()
        } else {
            codeTimeSecond.value--;
            timer.value = setTimeout(() => {
                timeCountDown();
            }, 1000);
        }
    }

    /**
     * 初始化倒计时
     */
    function initTimer() {
        codeTimeSecond.value = 300;
        codeButtonDisabled.value = false;
        clearInterval(timer.value);
    }


    // 发送验证码验证
    function sendCodeVerify() {
        formRef.value.validateField('phoneNumber', (valid) => {
            if (valid) {
                sendCodeHttp()
            } else {
                // 手机号验证不通过，可以在这里处理错误提示等逻辑
            }
        });
    }

    /**
     * 发送验证码
     */
    function sendCodeHttp() {
        userInfoController({
            type: 1,
            tel: formData.value.phoneNumber,
            msgType: 3 //    验证码类型 修改账户
        }).then((res) => {
            ElMessage({
                message: '发送成功',
                type: 'success',
            })
            timeCountDown()
        }).catch((err) => {
        })
    }

    const submitForm = async () => {
        // 表单提交逻辑
        // ...

        // 等待 nextTick 后再执行校验操作
        await nextTick();

        // 校验表单
        if (formRef.value) {
            formRef.value.validate(valid => {
                if (valid) {
                    // 校验通过，执行表单提交逻辑
                    // ...
                    modifyAccountHttp()
                }
            });
        }
    };

    /**
     * 修改密码
     */
     function modifyAccountHttp() {
        userReplacetel({
            userId: userAccount.value.userId,
            tel: formData.value.phoneNumber,
            code: formData.value.code,
            passWord: formData.value.password
        }).then((res) => {
            ElMessage({
                message: '修改成功',
                type: 'success',
            })
            userAccount.value.userTel = formData.value.phoneNumber
            AuthService.login(userAccount.value)
            store.commit('setUserAccount', userAccount.value)
            setTimeout(() => {
                window.location.reload();
            }, 1500)
        }).catch((err) => {
        })
     }
    return {
      formRef,
      userAccount,
      breadcrumbList,
      codeButtonDisabled,
      codeButtonText,
      codeTimeSecond,
      sendCodeVerify,
      formData,
      rules,
      submitForm
    }
  }
}
</script>

<style lang="scss">
.modify-account {
    .current-tel {
        margin-bottom: 20px;
    }
    .modify-form {
        width: 405px;
        .el-form-item__label {
            color: #0e9bee;
        }
        .el-form-item__content {
            .el-input {
                .el-input__wrapper {
                    padding: 0;
                    border-radius: 5px;
                    overflow: hidden;
                    box-shadow: inset 0 0 8px 2px #ededed;
                    .el-input__inner {
                        height: 40px;
                        line-height: 40px;
                        font-size: 16px;
                        padding: 0 10px;
                    }
                    .el-input__suffix {
                        padding-right: 5px;
                    }
                }
            }
            .el-select {
                width: 100%;
            }
        }
        .code-form-item {
            position: relative;
            .el-form-item__content {
                .el-input {
                    .el-input__inner {
                        padding-right: 100px !important;
                    }
                }
                .el-button {
                    position: absolute;
                    right: 10px;
                }   
            }
        }
    }
    .save-button {
        width: 240px;
        height: 40px;
        font-size: 18px;
        margin-top: 37px;
    }
}
</style>