<template>
  <div class="introduce-cell">
    <div class="introduce-title"><a :href="item.levelUrl" target="_blank">{{ item.levelName }}</a></div>
    <div class="introduce-date">{{ date }}</div>
  </div>
</template>

<script>
import commmonJs from '@/utils/common'
import { computed } from 'vue'
export default {
    props: {
        item: {
            type: Object,
            default: () =>{
                return {}
            }
        }
    },
    setup(props) {
        const date = computed(() => {
            return commmonJs.formateDateTimesToZh(props.item.createTime, 2, '.')
        })
        return {
            date
        }
    }
}
</script>

<style lang="scss">
.introduce-cell {
    padding: 10px 0;
    border-bottom: 1px dashed #cccccc;
    .introduce-title {
        font-weight: bold;
        a {
            display: inline-block;
            width: 100%;
        }
        a:hover {
            text-decoration: underline;
            color: #0e9bee;
            cursor: pointer;
        }
    }
    .introduce-date {
        color: #999999;
        margin-top: 8px;
    }
}
</style>