<template>
  <div class="step-real-name">
    <el-form :model="formData" :rules="rules" ref="formRef" label-width="100px">
        <el-form-item prop="userName" label="用户姓名">
            <el-input v-model="formData.userName" clearable placeholder="用户姓名（请与身份证上的姓名保持一致）" autocomplete="new-userName"></el-input>
        </el-form-item>
        <el-form-item prop="idCardType" label="证件类型">
            <el-select v-model="formData.idCardType" class="m-2" placeholder="证件类型" size="large">
                <el-option
                v-for="item in idCardTypes"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
            </el-select>
        </el-form-item>
        <el-form-item prop="idCard" label="证件号码">
            <el-input v-model="formData.idCard" clearable placeholder="证件号码" autocomplete="new-idCard" ></el-input>
        </el-form-item>
        <el-button type="primary" size="large" @click="submitForm">实名认证</el-button>
    </el-form>
  </div>
</template>

<script>
import { ref, nextTick, computed } from 'vue'
import { identityCheck } from '@/http/api'
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
import data from '@/utils/data'
export default {
    props: {
        currentNum: {
            type: Number,
            default: null
        }
    },
    setup(props, {emit}) {
        const store = useStore()
        const userAccount = computed(() => store.getters.userAccount)
        const idCardTypes = ref(data.idCardType)
        const formData = ref({
            userName: '',
            idCardType: '',
            idCard: ''
        })

        const rules = ref({
            userName: [
                { required: true, message: '请输入姓名', trigger: 'blur' },
            ],
            idCardType: [
                { required: true, message: '请选择证件类型', trigger: 'change' },
            ],
            idCard: [
                { required: true, message: '请输入证件号码', trigger: 'blur' },
            ],
            
        });

        const formRef = ref(null); // 创建对 form 元素的引用
        
        // methods
        const submitForm = async () => {
            // 表单提交逻辑
            // ...
            // 等待 nextTick 后再执行校验操作
            await nextTick();
            // 校验表单
            if (formRef.value) {
                formRef.value.validate(valid => {
                    if (valid) {
                        // 校验通过，执行表单提交逻辑
                        // ...
                        realNameHttp()
                    }
                });
            }
        };

        /**
         * 实名认证
         */
        function realNameHttp() {
            identityCheck({
                userId: userAccount.value.userId,
                userName: formData.value.userName,
                idCardType: formData.value.idCardType,
                userIdCard: formData.value.idCard
            }).then((res) => {
                ElMessage({
                    message: '身份证验证成功',
                    type: 'success',
                })
                setTimeout(() => {
                    userAccount.value = res.data
                    store.commit('setUserAccount', userAccount.value)
                    emit('stepChange', 1)
                }, 300)
            }).catch((err) => {
            })
        }
        return {
            idCardTypes,
            formData,
            rules,
            formRef,
            submitForm
        }
    }
}
</script>

<style lang="scss">
.step-real-name {
    .el-form {
    }
}
</style>