<template>
  <div class="home">
    <the-header></the-header>
    <!-- banner -->
    <the-banner></the-banner>
    <!-- banner end-->
    <div class="container">
      <div class="test-process ub ub-be">
        <test-process-cell v-for="(item, index) in testProcess" :key="item.title" :item="item" :index="index + 1"></test-process-cell>
      </div>
      <div class="content-model test-type">
        <h1 class="model-title">青少年人工智能教育软件编程等级考试</h1>
        <div class="test-type-model ub ub-ver ub-pc">
          <div :class="['test-type-item ub ub-ac', 'test-type-item'+index]" v-for="(item, index) in testTypes" :key="item">
            <!-- <el-image :src="require('../../assets/image/'+item.image+'')" fit="cover"/> -->
            <div class="ub ub-ver ub-pc test-type-item-info">
              <div class="test-type-item-title"><span>{{item.title}}</span></div>
              <div class="test-type-item-summary">{{ item.summary }}</div>  
            </div>
          </div> 
        </div>
      </div>
      <div class="content-model test-content">
        <el-carousel class="test-content-swiper" :autoplay="false">
          <el-carousel-item v-for="item in testContent" :key="item">
            <h1 class="model-title">{{ item.title }}</h1>
            <el-image :src="require('../../assets/image/'+item.image+'')" fit="cover"/>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="content-model about-nct">
        <h1 class="model-title">青少年人工智能素养与创新能力测评</h1>
        <div class="about-nct-content ub ub-ac">
          <el-image :src="require('../../assets/image/about_nct.png')" fit="cover"/>
          <div class="about-nct-summary ub-f1-item">
            <span>青少年人工智能素养与创新能力测评</span>目前涵盖了青少年图形化编程（1-6级）、Python编程（1-6级）、C++编程（1-6级）多维度、发展性的综合性测评实现全面检验孩子学习能力和掌握水平的途径、展示青少年在人工智能基础教育阶段的学习成果、全
面促进提升中国青少年的科学素养和学习能力的发展。
          </div>
        </div>
      </div>
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheBanner from '@/components/TheBanner.vue'
import TestProcessCell from './components/TestProcessCell.vue'
import TheFooter from '@/components/TheFooter.vue'
export default {
  components: {TestProcessCell, TheBanner, TheFooter},
  setup() {
    const testProcess = [{
      icon: 'icon-zhucetianjiahaoyou',
      title: '新用户注册',
      summary: '初次参加考试的需注册&已有账户的用户直接登录',
    }, {
      icon: 'icon-yanzhengma',
      title: '个人资料验证',
      summary: '确认个人信息无误之后再报名',
    }, {
      icon: 'icon-xinxueyuanbaoming',
      title: '考试报名',
      summary: '选择报考专业和考点报名&报名后24小时内完成缴费',
    }, {
      icon: 'icon-kaoshi',
      title: '测试与考试',
      summary: '考前参与模拟测试的流程&完成考试并通过颁发证书',
    }]
    const testTypes = [{
      image: 'test_item_scratch_bg.png',
      title: '图形化人工智能与创新能力测评',
      summary: '本标准基于Scratch编程环境，主要以实践应用能力为主，考查学生图形化软件编程能力，分为6个级别。',
    }, {
      image: 'test_item_python_bg.png',
      title: 'Python人工智能与创新能力测评',
      summary: '主要以考查基础语法、算法和实践应用能力为主。考查学生Python编程能力，分为6个级别。',
    }, {
      image: 'test_item_c_bg.png',
      title: 'C++人工智能与创新能力测评',
      summary: '主要以考察学生的计算思维、逻辑思维和编程能力为核心目标，考查学生C++编程能力，分为6个级别。',
    }]
    const testContent = [{
      image: 'test_content_scratch_bg.png',
      title: '图形化编程',
    }, {
      image: 'test_content_python_bg.png',
      title: 'Python编程',
    }, {
      image: 'test_content_c_bg.png',
      title: 'C++编程',
    }]
    return {testProcess, testTypes, testContent}
  }
  
}
</script>

<style lang="scss">
.home {
  .test-process {
    margin: 25px 0 30px;
  }
  .content-model {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 0 45px;
    box-shadow: 0 0 10px #ebe8e5;
    h1{
      height: 110px;
      line-height: 110px;
      font-size: 28px;
      margin: 0;
      text-align: center  ;
    }
  }
  .test-type {
    .test-type-model {
      .test-type-item {
        background-color: #f3f3f3;
        height: 184px;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 20px;
        box-sizing: border-box;
        padding: 0 20px 0 280px;
        background-color: #f5f1ee;
        .test-type-item-info {
          margin-left: 20px;
          padding-left: 30px;
          height: 100%;
          .test-type-item-title {
            margin-bottom: 10px;
            padding: 8px 0;
            color: #5d5e64;
            font-weight: bold;
          }
          .test-type-item-summary {
            font-size: 14px;
            color: #999999;
          }
          &::before {
            content: '';
            width: 8px;
            height: 77px;
            position: absolute;
            left: 0;
            top: 0;
          }
          &::after {
            content: '';
            width: 0;
            height: 0;
            border-left: 7px solid transparent; /* 左边透明 */
            border-right: 7px solid transparent; /* 右边透明 */
            position: absolute;
            left: -3px;
            top: 73px;
            transform: rotate(90deg);
          }
        }
        &::before {
          content: '';
          width: 280px;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      .test-type-item0 { 
        &::before {
          background-color: #c5c5c5;
          background-image: url('../../assets/image/test_item_scratch_bg.png');
          background-position: right bottom;
          background-repeat: no-repeat;
        }
        .test-type-item-info {
          &::before {
            background-color: #878787;
          }
          &::after {
            border-bottom: 8px solid #878787; /* 底部为三角形形状 */
          }
          .test-type-item-title {
            color: #878787;
          }
        }
      }
      .test-type-item1 { 
        &::before {
          background-color: #3771a1;
          background-image: url('../../assets/image/test_item_python_bg.png');
          background-position: right bottom;
          background-repeat: no-repeat;
        }
        .test-type-item-info {
          &::before {
            background-color: #184f7d;
          }
          &::after {
            border-bottom: 8px solid #184f7d; /* 底部为三角形形状 */
          }
          .test-type-item-title {
            color: #184f7d;
          }
        }
      }
      .test-type-item2 { 
        &::before {
          background-color: #75d6cc;
          background-image: url('../../assets/image/test_item_c_bg.png');
          background-position: right bottom;
          background-repeat: no-repeat;
        }
        .test-type-item-info {
          &::before {
            background-color: #42afa4;
          }
          &::after {
            border-bottom: 8px solid #42afa4; /* 底部为三角形形状 */
          }
          .test-type-item-title {
            color: #42afa4;
          }
        }
      }
    }
  }
  .test-content {
    margin: 25px 0;
    .test-content-swiper {
        .el-carousel__container {
          height: 626px;
          .el-carousel__arrow {
            width: 45px;
            height: 92px;
            background-color: initial;
            background-image: url('../../assets/image/swiper_right_btn_bg.png');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 0;
            i {
              display: none;
            }
          }
          .el-carousel__arrow--left {
            transform: translateY(-50%) rotate(180deg);
            left: 0;
          }
          .el-carousel__arrow--right {
            right: 0;
          }
          .el-carousel__item {
            text-align: center;
          }
        }
        .el-carousel__indicators {
          bottom: 10px;
          .el-carousel__indicator {
            .el-carousel__button {
              background-color: #e2e2e2;
              opacity: 1;
              width: 12px;
              height: 12px;
              border-radius: 30px;
            }
            
          }
          .el-carousel__indicator.is-active {
            .el-carousel__button  {
              width: 45px;
              height: 12px;
            }
          }
        }
    }
  }
  .about-nct {
    padding-bottom: 25px;
    .about-nct-content {
      overflow: hidden;
      height: 184px;  
      background-color: #f5f1ee;
      border-radius: 10px;
      padding-right: 20px;
      .el-image {
        width: 283px;
        height: 100%;
      }
      .about-nct-summary {
        padding: 0 20px;
        font-size: 14px;
        color: #999999;
        line-height: 24px;
        span {
          color: #1ba0ef;
        }
      }
    }
  }
}
</style>