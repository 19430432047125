<template>
  <div class="personal-center">
    <second-model>
        <template v-slot:right-content>
            <router-view></router-view>
        </template>
    </second-model>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import SecondModel from '../components/SecondModel.vue'
import store from '@/store';
export default {
  components: { SecondModel },
  setup() {
    const router = useRouter();
    const userAccount = computed(() => store.getters.userAccount)

    // 组件挂载后监听路由变化
    onMounted(() => {
      if (userAccount.value.role == 1) {
        router.replace('/personal/userInfo')
      } else {
        router.push('/personal/application-record')
      }
    });

    // 监听路由变化
    router.beforeEach((to) => {
      if (to.path == '/personal') {
        if (userAccount.value.role == 1) {
          router.replace('/personal/userInfo')
        } else {
          router.push('/personal/application-record')
        }
      }
      // 执行其他操作...
    });
  }
}
</script>

<style>

</style>