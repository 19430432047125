<template>
  <div class="examination-information">
    <second-model-2>
        <template v-slot:model-content>
            <second-model-title :breadcrumbList="breadcrumbList"></second-model-title>
            <div class="exam-model">
                <div class="exam-title">{{ examData.competitionName }}</div>
                <div class="exam-info">
                    <span>考试期次：{{ examData.examPeriod }}</span>|
                    <span>考试时长：{{examData.recommendTime}}分钟</span>|
                    <span>报名日期：{{examData.enrollStartTime}}-{{ examData.enrollEndTime }}</span>
                </div>
                <form-model>
                    <el-form :model="formData" :rules="rules" ref="formRef" label-width="200px" label-position="top">
                        <el-form-item prop="examPoint" label="请选择考点">
                            <el-select v-model="formData.examPoint" @change="handlePointChange" class="m-2" placeholder="请选择考点" size="large">
                                <el-option
                                v-for="item in examPoints"
                                    :key="item.examPointsId"
                                    :label="item.examPointsNmae"
                                    :value="item.examPointsId"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item class="form-item-radio" prop="examTime" label="请选择考试时间">
                            <el-radio-group class="ub" v-model="formData.examTime">
                                <el-radio :label="item.rowId" size="large" border v-for="(item) in examTimes" :key="item.rowId">
                                    <div class="time">{{ item.newTime }}</div>
                                    <div class="date ub ub-ac"><el-icon><Clock /></el-icon>考试时间：{{ item.yearsTime }}</div>
                                </el-radio>
                            </el-radio-group>
                            <div class="tip" v-if="tipText">{{ tipText }}</div>
                        </el-form-item>
                        
                    </el-form>
                    <div class="ub ub-pe">
                        <el-button type="primary" size="large" @click="submitForm">立即报名</el-button>
                    </div>
                </form-model>
            </div>
        </template>
    </second-model-2>
  </div>
</template>

<script>
import { ref, reactive, nextTick, onMounted, computed } from 'vue'
import SecondModel2 from '../components/SecondModel2.vue'
import SecondModelTitle from '../components/SecondModelTitle.vue'
import router from '@/router'
import { exhibitionApplication, saveExhibitionApplication } from '@/http/api'
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import FormModel from '../components/FormModel.vue'
export default {
  components: { SecondModel2, SecondModelTitle, FormModel },
  setup() {
    const store = useStore()
    const userAccount = computed(() => store.getters.userAccount)
    const queryString = window.location.hash.split('?')[1]; // 获取锚点后的查询参数部分
    const params = new URLSearchParams(queryString);
    const breadcrumbList = reactive([{
      title: '在线测评',
      path: '/evaluation'
    }, {
      title: '考试报名',
      path: ''
    }])
    const examData = ref('')
    const examPoints = reactive([])
    const examTimes = reactive([])
    const formData = ref({
        examPoint: '',
        examTime: ''
    })
    const rules = ref({
        examPoint: [
            { required: true, message: '请选择考点', trigger: 'change' },
        ],
        examTime: [
            { required: true, message: '请选择考试时间', trigger: 'change' },
        ],
    });
    const id = params.get('id');
    const tipText = computed(() => {
        if (!formData.value.examPoint) {
            return '请先选择考点'
        }

        if (examTimes.length == 0) {
            return '该考点考位已满，请选择其他考点'
        }

        return ''
    })
    onMounted(() => {
        if (id) {
            getData()
        } else {
            ElMessage({
                message: '缺少参数',
                type: 'warning',
            })
            setTimeout(() => {
                router.replace('/evaluation');
            }, 2000)
        }
    })

    /**
     * 获取数据
     */
     function getData() {
        exhibitionApplication({
            userId: userAccount.value.userId,
            competitionId: id
        }).then((res) => {
            console.log(res)
            examData.value = res.data
            examPoints.length = 0
            examPoints.push(...res.data.mapList)
        }).catch((err) => {
        })
     }

    const formRef = ref(null); // 创建对 form 元素的引用
    
    // methods
    const submitForm = async () => {
        // 表单提交逻辑
        // ...

        // 等待 nextTick 后再执行校验操作
        await nextTick();
        // 校验表单
        if (formRef.value) {
            formRef.value.validate(valid => {
                if (valid) {
                    // 校验通过，执行表单提交逻辑
                    // ...
                    registrationHttp()
                }
            });
        }
    };

    /**
     * 选择考点
     */
     function handlePointChange(id) {
        // 在这里根据选择的省份 value 获取对应的城市数据，并更新 cities 变量
        let newExamTimes = examPoints.find((obj) => obj.examPointsId === id).examTimeInfoList
        examTimes.length = 0
        examTimes.push(...newExamTimes);
     }

    /**
     * 报名登记
     */
    function registrationHttp() {
        saveExhibitionApplication({
            competitionId: id,
            examPointsId: formData.value.examPoint,
            examTimeId: formData.value.examTime,
            userId: userAccount.value.userId
        }).then((res) => {
            router.replace(`/examination-registration?id=${id}`)
        }).catch((err) => {
        })
    }
    return {
        breadcrumbList,
        formData,
        rules,
        formRef,
        examData,
        examPoints,
        examTimes,
        tipText,
        submitForm,
        handlePointChange
    }
  }
}
</script>

<style lang="scss">
.examination-information {
    .exam-model {
        padding: 20px 25px 25px;
        background-color: #f5f1ee;
        border-radius: 15px;
        color: #676664;
        .exam-title {
            font-size: 24px;
        }
        .exam-info {
            margin: 10px 0 20px;
            font-size: 14px;
            span {
                display: inline-block;
                margin: 0 10px;
            }
            span:first-child {
                margin-left: 0;
            }
        }
        .el-form {
            .el-form-item {
                width: 414px;
                .el-form-item__content {
                    .el-select {
                        width: 100%;
                    }
                    .el-radio-group {
                        .el-radio {
                            height: auto;
                            background-color: #ffffff;
                            padding: 16px 22px;
                            width: calc(50% - 10px); /* 设置每个元素宽度为父容器宽度的一半减去10像素的间隙 */
                            margin: 5px; /* 设置每个元素的间隙为5像素 */
                            .el-radio__input {
                                display: none;
                            }
                            .el-radio__label {
                                .time {
                                    font-size: 20px;
                                }
                                .date {
                                    font-size: 14px;
                                    .el-icon {
                                        font-size: 16px;
                                        margin-right: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .form-item-radio {
                width: 100%;
                .el-form-item__content {
                    width: 100%;
                    .el-radio-group {
                        width: 100%;
                    }
                }
                .tip {
                    margin-left: 10px;
                    color: #a1a1a1;
                }
            }
        }

        .el-button {
            width: 240px;
            margin-right: 5px;
            border-radius: 30px;
            font-size: 16px;
            margin-top: 30px;
        }
    }
}
</style>