<template>
  <div class="left-menu">
    <el-menu
        default-active="1"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
      >
        <el-menu-item :class="['el-menu-item' + (index + 1)]" :index="index + 1 + ''" v-for="(item, index) in menuList" :key="index">
            <router-link :to="item.path">{{ item.meta.title }}</router-link>
        </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router';
export default {
    setup() {
        function handleOpen() {

        }

        function handleClose() {

        }

        onMounted(() => {
            getMenu()
        })

        const menuList = ref([]);

        function getMenu() {
            const router = useRouter();
            const route = useRoute();
            const allRoutes = router.getRoutes();
            let path = route.matched[0].path
            for (let i = 0; i < allRoutes.length; i++) {
                if (allRoutes[i].path == path) {
                    menuList.value = allRoutes[i].children.filter((item) => {
                        return item.meta.isSecondMenu
                    })
                }
            }
        }

        return {
            handleOpen,
            handleClose,
            menuList
        }
    }
}
</script>

<style lang="scss">
.left-menu {
    .el-menu {
        width: 245px;
        padding: 15px;
        box-sizing: border-box;
        background-color: #ffffff;
        border: 0px;
        .el-menu-item{
            padding: 0 !important;
            height: 45px;
            a {
                display: block;
                width: 100%;
                height: 100%;
                color: #000000 !important;
                line-height: 45px;
                box-sizing: border-box;
                padding: 0 20px 0 62px;
                font-weight: bold;
            }
            a.router-link-exact-active {
                background-color: #e6f4fc;
            }
        }
    }
}
</style>