<template>
 <div class="main-info ub ub-ver">
   <QueTitle :title='title' title-type="mainInfo"></QueTitle>
   <div class="main-con ub ub-ver ub-f1" >
    <QueCorrect :title='title' title-type="mainInfo" v-if="getQuesitionData.pageType == 3"></QueCorrect>
    <div class="ub ub-ver ub-f1" v-else>
      <QueTips :type="1" :showBorder="isShowBorder"></QueTips>
      <div class="ub ub-f1">
        <el-scrollbar ref="scrollbar">
          <QueSelect v-for="(item, index) in getQuesitionData.quesitionData[getQuesitionData.answerSheetIndex]" :key="index" :title="getTitle(item)" :subTitle="getQueTypeInfo(item)" :actQueList="item"></QueSelect>
        </el-scrollbar>
      </div>
      <que-submit></que-submit>
    </div>
   </div>
 </div>
</template>

<script>
import { mapGetters } from 'vuex'
import QueTips from './QueTips.vue'
import QueTitle from './QueTitle.vue'
import QueSelect from './QueSelect.vue'
import QueCorrect from './QueCorrect.vue'
import QueSubmit from './QueSubmit.vue'
import que from '@/utils/que.js'

 export default {
   data () {
     return {
       title: '',
       isShowBorder: false,
       isButtonLoading: false
     }
   },
   components: {
      QueTips,
      QueTitle,
      QueSelect,
      QueSubmit,
      QueCorrect
   },

   mounted() {
     setTimeout(() =>{
       if (this.getQuesitionData.pageType == 3) {
         this.title = '操作题批改'
       } else {
         this.title = `答题卡${this.getQuesitionData.answerSheetIndex + 1}/${this.getQuesitionData.quesitionData
.length}<span>（共${this.getQuesitionData.quesitionInfoData.totalQuestionCount ? this.getQuesitionData.quesitionInfoData.totalQuestionCount : 0}题，满分${this.getQuesitionData.quesitionInfoData.totalScoreCount ? this.getQuesitionData.quesitionInfoData.totalScoreCount: 0}分）</span>`
       }
     }, 200)
   },

   computed: {
     ...mapGetters([
        'getQuesitionData'
      ]),

     getQueTypeInfo () {
       return function (item) {
          return item ? `（共${item.totalQuestionCount}题，共${item.totalScoreCount}分）` : '' 
       }
     },

     getTitle () {
       return function (item) {
         return `${que.getQueTitle(item.questionType)}`
       }
     }
   },

   methods: {
     submitPaper () {
       this.$messageBox.confirm(
        'proxy will permanently delete the file. Continue?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(() => {

        })
     },

     handleScroll () {
       if (this.$refs.scrollbar) {
        let scrollbarEl = this.$refs.scrollbar.wrap
        scrollbarEl.onscroll = () => {
          if(scrollbarEl.scrollTop > 20) {
            this.isShowBorder = true
          } else {
            this.isShowBorder = false
          }
        }
       }
     }
   }
 }
</script>

<style lang="scss">
.main-info {
  .main-title {
    padding: 0 15px;
  }
  .main-con {
    box-sizing: border-box;
    .el-scrollbar {
      width: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
  }

  .finish-button {
    margin-top: 30px;
    margin-bottom: 32px;
    text-align: center;
  }
}
 
</style>
