import { cryptoUtil } from "./crypto";
export const HMAC = function (hasher, message, key, options) {
  // Allow arbitrary length keys
  key =
    key.length > hasher._blocksize * 4
      ? hasher(key, { asBytes: true })
      : cryptoUtil.stringToBytes(key);

  // XOR keys with pad constants
  var okey = key,
    ikey = key.slice(0);
  for (var i = 0; i < hasher._blocksize * 4; i++) {
    okey[i] ^= 0x5c;
    ikey[i] ^= 0x36;
  }

  var hmacbytes = hasher(
    cryptoUtil.bytesToString(okey) +
      hasher(cryptoUtil.bytesToString(ikey) + message, { asString: true }),
    { asBytes: true }
  );
  return options && options.asBytes
    ? hmacbytes
    : options && options.asString
    ? cryptoUtil.bytesToString(hmacbytes)
    : cryptoUtil.bytesToHex(hmacbytes);
};
