<template>
  <div class="form-model">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.form-model {
    .el-form {
        .el-form-item {
            margin-right: 8px;
            .el-form-item__label {
                font-size: 14px;
                color: #676664;
            }
            .el-form-item__content {
                .el-input {
                    .el-input__wrapper {
                        padding: 0;
                        border-radius: 7px;
                        overflow: hidden;
                        box-shadow: inset 0 0 2px 1px #ededed;
                        .el-input__inner {
                            height: 40px;
                            line-height: 40px;
                            font-size: 16px;
                            padding: 0 10px;
                        }
                        .el-input__suffix {
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
        
    }
    .el-button {
        width: 240px;
        height: 40px;
        border-radius: 30px;
        font-size: 16px;
        margin: 0 auto;
        display: block;
    }
}
</style>