<template>
 <div :class="['main ub ub-ver reload']">
     <!-- <record-model v-if="!newPaperParams.isMockExamType"></record-model> -->
     <!-- 头部 -->
     <div class="main-header ub " v-if="queData">
         <div class="ub" v-if='getQuesitionData.pageType == 1'>
             <div>{{queData.subName}}</div>
         </div>
         <el-breadcrumb separator-class="el-icon-arrow-right" class="ub-f1" v-else>
            <el-breadcrumb-item :to="{ path: '/evaluation' }">在线测评</el-breadcrumb-item>
            <el-breadcrumb-item>{{getBreadcrumb}}</el-breadcrumb-item>
         </el-breadcrumb> 
         <div class="ub-f1 paper-name">{{getQuesitionData.examName}}</div>
         <div class="paper-time student" v-if='getQuesitionData.pageType == 4' @click="showStudents()"><span>作答学生</span></div>
         <div class="paper-time student" v-else-if='getQuesitionData.pageType == 3'></div>
         <div class="paper-time" v-else>考试用时：<span>{{formattedTime}}</span></div>
         <div class="ub ub-ac" @click="goBack" v-if="newPaperParams.isMockExamType"><el-button type="danger">退出考试</el-button></div>
         <div class="video-icon" v-if="isCameraRecording"><el-icon><VideoCameraFilled /></el-icon></div>
         <div class="video-icon" v-if="isScreenRecording"><el-icon><Platform /></el-icon></div>
     </div>
     <div class="main-header ub " v-else>
         <el-breadcrumb separator-class="el-icon-arrow-right" class="ub-f1">
            <el-breadcrumb-item :to="{ path: '/evaluation' }">在线测评</el-breadcrumb-item>
            <el-breadcrumb-item>{{getBreadcrumb}}</el-breadcrumb-item>
         </el-breadcrumb> 
     </div>
     <!-- 头部 end -->
     <div class="main-body ub ub-f1">
        <QueInfoArea class="main-left" :queData="queData" v-if="queData"></QueInfoArea>
        <div class="ub-f1" v-if="queData">
            <el-scrollbar style="height:100%" ref="scrollbar" class="content-scrollBar">
                <div class="ub ub-f1" style="height: 100%">
                    <QueStemArea class="main-center"></QueStemArea>
                    <QueOptionArea class="main-right" :pageName="paperParams.pageName" :competitionId="paperParams.competitionId"></QueOptionArea>
                </div>
            </el-scrollbar>
        </div>
        <el-empty :description="isGetDataErrTip" v-if="isGetDataErr"></el-empty>
        <!-- <Loading v-if="isGetDataLoading" :isLoading="isLeverLoading" loadText="信息加载中..."></Loading> -->
     </div>

     <el-dialog
        title="提示"
        custom-class="default-dialog big-img-dialog"
        :model-value="bigImgDialogVisible"
        :before-close="handleClose">
        <img :src="bigImgSrc" alt="">
    </el-dialog>

    <el-dialog
        :title="openDeviceDialogTitle"
        custom-class="default-dialog open-device-dialog"
        :close-on-click-modal="false"
        :model-value="isOpenDeviceDialogVisible"
        :before-close="openDeviceDialogHandleClose">
        <div class="tip" v-if="isCameraRecording && !isScreenRecording">屏幕录制请选择“整个屏幕”</div>
        <div class="ub ub-ac ub-pc">
            <el-button type="primary" v-if="!isCameraRecording" @click="openCameraRecord">开启</el-button>
            <el-button type="primary" v-if="isCameraRecording && !isScreenRecording" @click="openScreenRecord">开启</el-button>
        </div>
    </el-dialog>
 </div>
</template>

<script>
import { questionInfoConfig, recordEnterExamTime, examInfoConfigPost } from '@/http/api'
import { mapGetters } from 'vuex'
//引入阿里云oss
import OSS from "ali-oss";
import RecordRTC from "recordrtc";
import { getOssInfo } from "@/utils/upload/config";
 import QueInfoArea from './QueInfoArea.vue'
 import QueStemArea from './QueStemArea.vue'
 import QueOptionArea from './QueOptionArea.vue'
 import queJs from '@/utils/que.js'
 import $ from 'jquery'

//阿里云OSS配置信息
const OSSInfo = {
  region: "oss-cn-qingdao",
  bucket: "ycsj2",
};

//屏幕录制追加文件到阿里云OSS的位置
let nextScreenAppendPosition = 0; // 追加位置

//屏幕录制时保存上传保存的文件名,这里需要动态生成,按用户的ID或者名称来生成文件夹
let objecScreentName = ""
//   "screen/screen_" + Math.floor(Math.random() * 10000000) + ".webm"; // 文件名


//摄像头录制追加文件到阿里云OSS的位置
let nextCameraAppendPosition = 0; // 追加位置

//摄像头保存上传的文件名
let objCameraName = ""
//   "camera/camera_" + Math.floor(Math.random() * 10000000) + ".webm"; // 文件名

//屏幕录制对象
let sreenRecorder = null;

//摄像头录制对象
let cameraRecorder = null;

//阿里云对象
let client = null;

 export default {
    props: ['paperParams'],
   data () {
     return {
         newPaperParams: '',
         bigImgDialogVisible: false,
         bigImgSrc: '',
         queData: null,
         isGetDataLoading: false,
         isGetDataErr: false,
         isGetDataErrTip: '',
        //  倒计时
         timer: null,
         countdown: 0,
        //  录屏录像
        isOpenDeviceDialogVisible: false,
        openDeviceDialogTitle: '',
        cameraStream: null,
        screenStream: null,
        isScreenRecording: false,
        isCameraRecording: false
     }
   },
   components: {
       QueInfoArea,
       QueStemArea,
       QueOptionArea
   },

   computed: {
    ...mapGetters([
      'getUserInfo',
      'getQuesitionData'
    ]),

    getBreadcrumb () {
        return queJs.getBreadcrumbName(this.getQuesitionData.pageType)
    },
    formattedTime() {
        const hours = Math.floor(this.countdown / 60 / 60);
        const minutes = Math.floor((this.countdown % 3600) / 60);
        const seconds = this.countdown % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    takeTime() {
        console.log(this.getQuesitionData.quesitionInfoData.time * 60)
        console.log(this.getQuesitionData.currentTime)
        return this.getQuesitionData.quesitionInfoData.time * 60 - this.getQuesitionData.currentTime
    }
   },

    created () {
        /* this.paperParams = {
            examId: "20426",
            projectUrl: "https://pbl.zdw000.com/question/ycsj_questions_bank/api/",
            type: 1,
            classId: 1800314,
            uid: 2000002345,
            role: '1'
        } */
        /* this.paperParams = {
            examId: "10102",
            projectUrl: "https://pbl.zdw000.com/question/ycsj_questions_bank/api/",
            type: 2,
            classId: 1800311,
            uid: 366,
            role: '1'
        } */
        /* this.paperParams = {
            examId: "10102",
            projectUrl: "https://pbl.zdw000.com/question/ycsj_questions_bank/api/",
            type: 4,
            classId: 1800311,
            uid: 2000002345,
            role: '1'
        } */
        if (this.paperParams.examId) {
            this.newPaperParams = this.paperParams
            sessionStorage.setItem('newPaperParams', JSON.stringify(this.paperParams))
        } else {
            this.newPaperParams = JSON.parse(sessionStorage.getItem('newPaperParams'))
        }
        objecScreentName = "screen/screen_" + this.newPaperParams.examId + "_" + this.newPaperParams.uid + "_" + Math.floor(Math.random() * 10000000) + ".webm"
        objCameraName = "camera/camera_" + this.newPaperParams.examId + "-" + this.newPaperParams.uid + "_" + Math.floor(Math.random() * 10000000) + ".webm"
        this.$store.dispatch('setPaperParams', this.newPaperParams)
        this.getData()
    },

    mounted () {
        this.listenPage()

        window.addEventListener('click', e => {
            if (e.target.localName == 'img' && e.target.currentSrc.indexOf('https://pc.zdw000.com/') != -1) {
                this.bigImgSrc = e.target.currentSrc
                this.bigImgDialogVisible = true
            }
         },  false);
    },

    unmounted () {
        clearInterval(this.timer);
        window.onbeforeunload = null
        // 清除录制
        this.stopRecord()
    },

    methods: {
        goBack() {
            this.$router.replace({ path: `/evaluation/${this.newPaperParams.routeName.toLowerCase()}`})
        },
        getData () {
            let params = {}
            params.type = this.getQuesitionData.pageType
            params.examId =  this.getQuesitionData.examId
            localStorage.removeItem('isReload')
            if (this.getQuesitionData.pageType == 1) { // 做题
                localStorage.setItem('isReload', true)
            } else if (this.getQuesitionData.pageType == 2) { // 解析
                params.type = 3,
                params.userId = this.getQuesitionData.uid
            } else if (this.getQuesitionData.pageType == 3) {
                params.type = 5
                params.userId = this.getQuesitionData.uid
            } else if (this.getQuesitionData.pageType == 4) {
                // ...
            }
            questionInfoConfig(params).then(res => {
                console.log(res)

                let data = res.data
                this.queData = data
                // queState.setQueInfoData(data)
                this.$store.dispatch('setQueInfoData', data)
                let quesitionData = []

                for (let i in data) {
                    if (i.indexOf('QuestionData') != -1) {
                        quesitionData.push(data[i])
                    }
                }
                quesitionData.sort(this.soryBy('questionType'))
                this.$store.dispatch('setActQueData', {})

                // 初始化一个二维数组，用于存放分组后的数据
                const groupedData = [[], []];
                
                console.log(quesitionData)
                // 遍历原始数据，并根据 questionType 分组
                quesitionData.forEach(item => {
                if (item.questionType >= 1 && item.questionType <= 3) {
                    groupedData[0].push(item);
                } else if (item.questionType == 4) {
                    groupedData[1].push(item);
                }
                });

                console.log(groupedData)

                // 过滤掉空数组
                const filteredGroupedData = groupedData.filter(group => group.length > 0);
                this.$store.dispatch('setQueData', filteredGroupedData)
                this.$store.dispatch('setActQueData', filteredGroupedData[this.getQuesitionData.answerSheetIndex][0].list[0])
                console.log(filteredGroupedData)
                console.log(filteredGroupedData[this.getQuesitionData.answerSheetIndex][0].list[0])

                if (this.getQuesitionData.pageType == 1) {
                    // 获取考试倒计时
                    this.getRecordEnterExamTime()
                } else if (this.getQuesitionData.pageType == 2) {
                    this.countdown = data.answerTime
                } else if (this.getQuesitionData.pageType == 3) {
                    this.$store.dispatch('setUserCorrectOrNot')
                }
                
            }).catch((err) => {
                this.isGetDataErr = true
                this.isGetDataErrTip = err && err.data.msg ? err.data.msg : '加载失败！'
            })
        },

        // 获取考试倒计时时间
        getRecordEnterExamTime() {
            recordEnterExamTime({
                userId: this.getQuesitionData.uid,
                competitionId: this.getQuesitionData.competitionId
            }).then(res => {
                console.log(res)
                // res.data = 36000
                if (res.data < 0) {
                    this.$message({
                        message: "考试时间已到，考试已结束！",
                        type: "warning",
                        showClose: true
                    });
                    setTimeout(() => {
                        this.$router.replace({name: this.getQuesitionData.routeName})
                    }, 1000)
                } else {
                    this.countdown = res.data
                    this.timer = setInterval(this.updateCountdown, 1000);
                    if (this.newPaperParams.competitionType == '0') {
                        this.getTemporaryInfo()
                        this.isOpenDeviceDialogVisible = true
                        this.openDeviceDialogTitle = '开启摄像头录制'
                    }
                    
                }
            })
        },

        soryBy () {
            return function (a, b) {
                return a.questionType - b.questionType
            }
        },
        // 更新倒计时
        updateCountdown() {
            if (this.countdown > 0) {
                this.countdown--;
                this.$store.dispatch('setCurrentTime', this.countdown)
            } else {
                // location.reload();
                this.submitHandpaper()
                clearInterval(this.timer);
            }
        },

        /**
         * 显示已答该题学生 
         */
        showStudents () {
            let questionStudent = this.getQuesitionData.actQueData.questionStudent
            this.$alert(questionStudent.length > 0 ? questionStudent.join(',') : '暂无答题学生！', '提示', {
                confirmButtonText: '确定',
                customClass: 'messageBox-default explain',
                callback: () => {
                }
            });
        },

        listenPage() {
            if (this.getQuesitionData.pageType == 1) {
                window.onbeforeunload = function (e) {
                    e = e || window.event;
                    if (e) {
                        e.returnValue = '关闭提示';
                    }
                    return '关闭提示';
                };
            } else {
                window.onbeforeunload = null
            }
        },

        handleClose() {
            this.bigImgDialogVisible = false
        },

        submitHandpaper() {
            this.$messageBox.confirm('考试时间到，请提交试卷！', '提示', {
                    confirmButtonText: '确认提交',
                    type: 'warning',
                    showClose: false,
                    showCancelButton: false,
                    center: true,
                    closeOnClickModal: false,
                    customClass: 'default-dialog submit-message'
                    // custom-class: 'default-dialog'
                }).then(() => {
                    console.log('aaaa')
                    console.log(this.newPaperParams.isMockExamType)
                    console.log(this.newPaperParams.isMockExamType == true)
                    console.log(typeof(this.newPaperParams.isMockExamType))
                    this.stopRecord()
                    if (this.newPaperParams.isMockExamType) {
                        setTimeout(() => {
                            this.$router.replace({name: this.getQuesitionData.routeName})
                        }, 1000)
                    } else {
                        this.requestPaper()
                    }
                }).catch(() => {
                    // 继续答题
                })
        },

        requestPaper() {
            let userAnswer = {}
            // 遍历外层数组
            for (const outerItem of this.getQuesitionData.quesitionData) {
                // 遍历内层数组
                for (const innerItem of outerItem) {
                    // 遍历 list 数组
                    for (const listItem of innerItem.list) {
                        // 将 id 和 num 放入 resultObject
                        userAnswer[listItem.questionId] = listItem.userAnswer ? listItem.userAnswer : '';
                    }
                }
            }
            console.log()
            let formData = new FormData();
            //参数
            formData.append("type", 2);
            formData.append("answer", JSON.stringify(userAnswer));
            formData.append("userId", this.getQuesitionData.uid);
            formData.append("examId", this.getQuesitionData.examId);
            formData.append("answerTime", this.takeTime);
            formData.append("role", this.getQuesitionData.role);
            examInfoConfigPost(formData).then(res => {
                this.$message({
                    message: "试卷提交成功！",
                    type: "success",
                    showClose: true
                });
                setTimeout(() => {
                    this.$router.replace({name: this.getQuesitionData.routeName})
                }, 1000)
            }).catch(() => {
                this.submitHandpaper()
            })
        },

        /**
         * 录屏录像弹窗关闭
         */
         openDeviceDialogHandleClose() {
            if (!this.isCameraRecording || (this.isCameraRecording && !this.isScreenRecording)) {
                this.$message({
                    message: '请先开启设备录制',
                    type: 'warning'
                });
            }
         },

         /**
          * 开启摄像头录制 
          */
         openCameraRecord() {
            navigator.mediaDevices
            //屏幕录制
            .getUserMedia({
            video: true,
            audio: true,
            })
            .then((stream) => {
                //根据返回的流创建录制流程
                this.createRecord(stream, 2);
                console.log('成功打开视频流和音频流');
            }).catch((error) => {
                this.$message({
                    message: '请检查设备或开启设备权限',
                    type: 'warning'
                });
                console.error('获取媒体设备失败:', error);
            });
         },

         /**
          * 开启屏幕录制
          */
          openScreenRecord() {
            navigator.mediaDevices
            //屏幕录制
            .getDisplayMedia({
            video: true,
            audio: true,
            })
            .then((stream) => {
                //根据返回的流创建录制流程
                this.createRecord(stream, 1);
            });
          },

         /**
          * 创建屏幕录制流程 
          */
        async createRecord(stream, type){
            if (type == 1) {
                //屏幕录制
                stream.getVideoTracks()[0].onended = () => {
                    //监听以后的处理逻辑……
                    // console.log(`监听到手动触发停止共享的事件了`);
                    //调用停止录制功能
                    if (sreenRecorder.state != "destroyed") {
                        stopRecord();
                    }
                };

                sreenRecorder = RecordRTC(stream, {
                    type: "video",

                    //视频类型
                    mimeType: "video/webm",

                    //分片时间
                    timeSlice: 20000,

                    //该回调函数必须和上面的的timeSlice分片时间配合使用
                    ondataavailable: async (blob) => {
                        console.log(`录屏分片时间内录制的数据是:${blob.size}`);
                        //追加上传文件
                        this.appenFile(blob, type);
                    },

                    //获取时间片段的时间戳
                    onTimeStamp: function (timestamp) {},

                    //录制的码率
                    bitsPerSecond: 3840000,
                });

                //开始录制
                sreenRecorder.startRecording();
                this.isScreenRecording = true
                this.isOpenDeviceDialogVisible = false
            } else if (type == 2) {
                cameraRecorder = RecordRTC(stream, {
                    type: "video",

                    //视频类型
                    mimeType: "video/webm",

                    //分片时间
                    timeSlice: 30000,

                    //该回调函数必须和上面的的timeSlice分片时间配合使用
                    ondataavailable: async (blob) => {
                        console.log(`摄像头分片时间内录制的数据是:${blob.size}`);
                        //追加上传文件
                        this.appenFile(blob, type);
                    },

                    //获取时间片段的时间戳
                    onTimeStamp: function (timestamp) {},

                    //录制的码率
                    bitsPerSecond: 3840000,
                });

                //开始录制
                cameraRecorder.startRecording();
                console.log('开始录制')
                this.isCameraRecording = true
                this.openDeviceDialogTitle = '开启屏幕录制'
            }
        },

        /**
         * 追加上传阿里云oss的方法 
         */
        async appenFile(blob, type){
            if (blob && blob.size > 0) {
                const buffer = await this.blobToUint8Array(blob);

                const args = [
                    type == 1 ? objecScreentName : objCameraName,
                    OSS.Buffer.from(buffer),
                ];

                if (nextScreenAppendPosition > 0 || nextCameraAppendPosition > 0) {
                    // nextScreenAppendPosition === 0 时说明是新建
                    //追加时额外添加position参数
                    args.push({
                        position:
                        type == 1 ? nextScreenAppendPosition : nextCameraAppendPosition,
                    });
                }
                console.log(client)
                const result = await client.append(...args);

                console.log(`${type == 1 ? "屏幕录制" : "摄像头录制"}:`, result);

                if (type == 1) {
                    nextScreenAppendPosition += buffer.byteLength; // 追加位置更新
                } else {
                    nextCameraAppendPosition += buffer.byteLength; // 追加位置更新
                }

                // sliceArr.push(blob);
            }
        },

        /**
         * 请求临时凭证 
         */
         async getTemporaryInfo() {
            let res = await getOssInfo();
            console.log(res)
            let { AccessKeyId, AccessKeySecret, SecurityToken } = res.data;
            client = new OSS({
                region: OSSInfo.region,
                accessKeyId: AccessKeyId,
                accessKeySecret: AccessKeySecret,
                stsToken: SecurityToken,
                bucket: OSSInfo.bucket,
            });
        },

        /**
         * 将流转化为arrayBuffer方法 
         */
        async blobToUint8Array(blob){
            return new Promise((resolve, reject) => {
                var reader = new FileReader();

                reader.onload = function (event) {
                    // 'result' will contain the ArrayBuffer representation of the Blob
                    var arrayBuffer = event.target.result;

                    // Create a Uint8Array from the ArrayBuffer
                    var uint8Array = new Uint8Array(arrayBuffer);
                    resolve(uint8Array);
                };

                    reader.onerror = function (error) {
                    reject(error);
                };

                reader.readAsArrayBuffer(blob);
            });
        },

        //停止录制
        async stopRecord() {
            if (!this.isScreenRecording || !this.isCameraRecording) {
                return;
            }

            //关闭屏幕录制对象
            if (sreenRecorder) {
                sreenRecorder.stopRecording();
            }

            //改变录制状态
            this.isScreenRecording = false;

            //关闭摄像头录制对象
            if (cameraRecorder) {
                cameraRecorder.stopRecording();
            }

            //改变录制状态
            this.isCameraRecording = false;
        }
    },

   watch: {
       'getQuesitionData.actQueData' (newVal, oldVal) {
            if (newVal == oldVal) {
                return
            }
            $('.que-option-body').css('opacity', '0')
            $('.que-stem-body').css('opacity', '0')
            setTimeout(() => {
                // 选项图片缩小
                if ($('.que-option-body img').length > 0 &&  newVal.questionType != 4) {
                    $('.option-item img').each(function() {
                        let imgWidth = $(this).width()
                        $(this).css({'width': `${imgWidth * newVal.subjectValue}`})
                        $(this).parents('.que-option-body').css('opacity', '1')
                    })
                } else {
                    $('.que-option-body').css('opacity', '1')
                }

                // 题干图片缩小
                if ($('.que-stem-body img').length > 0) {
                    $('.que-satem img').each(function() {
                        let imgWidth = $(this).width()
                        $(this).css('width', `${imgWidth * newVal.titleValue}`)
                        $(this).parents('.que-stem-body').css('opacity', '1')
                    })
                } else {
                    $('.que-stem-body').css('opacity', '1')
                }
            }, 300)
       }
   }
 }
</script>
<style lang="scss">
.main {
    padding: 0 4px;
    height: 100%;
    box-sizing: border-box;
    background-color: #eff3f8;
    text-align: left;
    font-size: 18px;

    .main-header {
        height: 68px;
        line-height: 68px;
        font-size: 24px;
        box-sizing: border-box;
        .paper-name {
            margin-left: 42px;
            margin-right: 10px;
        }
        .paper-time {
            font-size: 16px;
            padding-right: 15px;
            span {
                font-weight: bold;
            }
        }

        .paper-time.student {
            color: var(--el-color-primary);
            cursor: pointer;
        }

        .el-breadcrumb {
            line-height: 68px;
            font-size: 16px;

            .el-breadcrumb__item {
                .el-breadcrumb__separator,
                .el-breadcrumb__inner {
                    color: #000;
                    font-weight: 700;
                }
            }

            .el-breadcrumb__item:last-child {
                .el-breadcrumb__inner {
                    color: var(--el-color-primary);
                }
            }
        }
        .video-icon {
            margin-left: 10px;
            animation: videoIconAnimation 0.7s ease-out infinite;
        }

        @keyframes videoIconAnimation {
            from {
                color: #000;
            }
            to {
                color: red;
            }
        }
    }

    .el-scrollbar__view {
        height: 100%;
    }

    .main-left, .main-center, .main-right {
        background-color: #fff;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .main-left {
        width: 266px;
        min-width: 266px;
    }

    .content-scrollBar {
        .el-scrollbar__wrap {
            overflow-x: hidden;

            .main-center {
                width: 50%;
                min-width: 458px;
                margin: 0 4px;
            }

            .main-right {
                width: 50%;
                min-width: 458px;
            }
        }
    }

    .el-empty {
        width: 100%;
    }

    .big-img-dialog {
        background-color: unset;
        box-shadow: unset;
        width: 100%;
        text-align: center;

        .el-dialog__header {
            background: unset;
            .el-dialog__title {
                display: none;
            }

            .el-dialog__headerbtn {
                i {
                    color: #fff;
                }

                i::before {
                    color: #fff;
                    font-size: 50px;
                }
            }
        }

        .el-dialog__body {
            img {
                max-height: 100%;
                max-width: 100%;
            }
        }

        .el-dialog__footer {
            display: none;
        }
    }

    .open-device-dialog {
        .el-dialog__body {
            .tip {
                text-align: center;
                margin-bottom: 30px;
                color: red;
                font-size: 14px;
            }
        }
    }
}
 
</style>
