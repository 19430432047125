<template>
  <div class="login ub">
    <div class="login-left">
        <el-image :src="loginBg" fit="cover"></el-image>
        <div class="login-text ub ub-ver ub-ac ub-pc" @click="goBack">
            <h2>Nice to meet you</h2>
            <h1>WELCOME</h1>
        </div>
    </div>
    <div class="login-info ub-f1">
        <h1>{{ loginText }}</h1>
        <login-password-model v-if="loginType == 1" @forgetPassword="loginTypeChange(4)"></login-password-model>
        <login-code-model v-if="loginType == 2"></login-code-model>
        <login-register-model v-if="loginType == 3" @registerChange="loginTypeChange(1)"></login-register-model>
        <login-forget-password-model v-if="loginType == 4" @forgetPassword="loginTypeChange(1)"></login-forget-password-model>
        <div class="login-type">
            <span @click="loginTypeChange(3)" v-if="loginType != 3">账号注册</span>
            <span @click="loginTypeChange(1)" v-if="loginType != 1">密码登录</span>
            <span @click="loginTypeChange(2)" v-if="loginType != 2">验证码登录</span>
        </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import LoginPasswordModel from './components/LoginPasswordModel.vue'
import LoginCodeModel from './components/LoginCodeModel.vue'
import LoginForgetPasswordModel from './components/LoginForgetPasswordModel.vue'
import LoginRegisterModel from './components/LoginRegisterModel.vue'
import { useRoute, useRouter } from 'vue-router';
export default {
  components: { LoginPasswordModel, LoginCodeModel, LoginForgetPasswordModel, LoginRegisterModel },
    setup() {
        const loginBg = ref(require('../../assets/image/login_bg.png'))
        const loginType = ref(1)
        // computed
        const loginText = computed(() => {
            switch (Number(loginType.value)) {
                case 1:
                    return '密码登录'
                case 2:
                    return '验证码登录'
                case 3:
                    return '在线注册'
                case 4:
                    return '找回密码'
            
                default:
                    return '';
            }
        })
        // methods
        const loginTypeChange = (type) => {
            loginType.value = type
        }

        const route = useRoute();
        const router = useRouter()
        
        onMounted(() => {
            let type = route.params.type
            if (type) {
                loginTypeChange(type)
            }
        })

        function goBack() {
            router.replace('/');
        }

        return {
            loginBg,
            loginType,
            loginText,
            loginTypeChange,
            goBack
        }
    }
}
</script>

<style lang="scss">
.login {
    width: 1190px;
    height: 714px;
    margin: 0 auto;
    padding: 120px 0 0;
    overflow: hidden;
    .login-left {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        width: 767px;
        overflow: hidden;
        position: relative;
        .el-image {
            width: 100%;
        }
        .login-text {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            color: #fff;
            h1 {
                font-size: 60px;
                margin: 0;
            }
        }
    }
    .login-info {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 0 35px;
        background-color: #ffffff;
        h1 {
            color: #0e9bee;
            margin: 45px 0 40px;
        }
        .el-form {
            .el-form-item {
                margin-bottom: 20px;
                .el-form-item__content {
                    margin-left: 0 !important;
                    .el-input {
                        .el-input__wrapper {
                            padding: 0;
                            border-radius: 5px;
                            overflow: hidden;
                            box-shadow: inset 0 0 8px 2px #ededed;
                            .el-input__inner {
                                height: 40px;
                                line-height: 40px;
                                font-size: 16px;
                                padding: 0 10px;
                            }
                            .el-input__suffix {
                                padding-right: 5px;
                            }
                        }
                    }
                    .login-button {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
        .login-type {
            text-align: right;
            span {
                display: inline-block;
                padding: 0 20px;
                font-size: 14px;
                position: relative;
                cursor: pointer;
                &::before {
                    content: '';
                    width: 1px;
                    height: 15px;
                    background-color: #000000;
                    position: absolute;
                    right: 0;
                    top: 2px;
                }
                &:last-child {
                    padding-right: 0;
                }
                &:last-child::before {
                    display: none;
                }
            }
        }
    }
}
</style>