<template>
  <div class="test-process-cell ub ub-ac ub-ver">
    <div :class="['test-process-icon ub ub-ac ub-pc iconfont', item.icon]"></div>
    <div class="test-process-title">{{ item.title }}</div>
    <div class="test-process-summary" v-html="summaryValue(item.summary)"></div>
    <div :class="['test-process-index ub-f1', 'test-process-index' + index]">
        <span class="num"><i>{{ index }}</i></span>
        <div class='triangle'>
            <el-icon v-if="index == 4"><Select /></el-icon>
            <div class="ub ub-be" v-else>
                <span class="iconfont icon-jiantou_shang_mianxing"></span>
                <span class="iconfont icon-jiantou_shang_mianxing"></span>
                <span class="iconfont icon-jiantou_shang_mianxing"></span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
export default {
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        },

        index: {
            type: Number,
            default: 0
        }
    },
    setup() {
        const summaryValue = computed(() => {
            return (value) => {
                return value.replace(/&/g, "<br/>");
            }
        })
        return {
            summaryValue
        }
    }
}
</script>

<style lang="scss">
.test-process-cell {
    width: 23.5%;
    height: 275px;
    background-color: #ffffff;
    border-radius: 15px;
    box-sizing: 0 0 10px 10px #ccc;
    box-sizing: border-box;
    padding: 25px 0 0;
    overflow: hidden;
    .test-process-icon {
        width: 104px;
        height: 104px;
        background-color: #cfebfc;
        border-radius: 50%;
        &::before {
            font-size: 48px;
            color: #0e9bee;
            z-index: 10;
        }
        &::after {
            content: '';
            width: 78px;
            height: 78px;
            border: 1px solid #1ba0ef;
            position: absolute;
            background-color: #ffffff;
            border-radius: 50%;
        }
    }
    .icon-kaoshi {
        &::before {
            font-size: 42px;
            color: #0e9bee;
            z-index: 10;
        }
    }
    .test-process-title {
        font-size: 18px;
        font-weight: bold;
        margin: 15px 0 15px;
    }

    .test-process-summary {
        color: #999999;
        font-size: 14px;
    }

    .test-process-index {
        width: 100%;
        position: relative;
        span.num {
            position: absolute;
            bottom: -40px;
            left: -40px;
            display: inline-block;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            text-align: right;
            box-sizing: border-box;
            padding-right: 17px;
            padding-top: 8px;
            font-size: 24px;
            font-weight: bold;
            color: #fff;
            background-color: #ff632d;
            z-index: 3;
        }
        &::before {
            content: '';
            width: 176px;
            height: 0;
            position: absolute;
            bottom: 20px;
            left: 40px;
            border: 1px dashed #ff632d;
        }
        .triangle {
            width: 50px;
            height: 20px;
            position: absolute;
            right: 5px;
            bottom: 10px;
            span.icon-jiantou_shang_mianxing {
                display: inline-block;
                transform: rotate(90deg);
                color: #ff632d;
            }
        }
    }

    .test-process-index4 {
        &::before {
            width: 200px;
        }
        .triangle {
            width: 24px;
            .el-icon {
                font-weight: bold;
                font-size: 24px;
                color: #ff632d;
            }
        }
    }
}
</style>