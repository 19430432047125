
// 引入vue-router对象
import { createRouter, createWebHashHistory } from "vue-router";
import { AuthService } from '@/utils/AuthService';
import store from "@/store";

// 1. 定义路由加载模式
const history = createWebHashHistory()

// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [{
        path: '/',
        name: 'Index',
        component: require('@/pages/home/home-page').default,
        meta: {
            title: '首页',
            isHeaderMenu: true, // 是否显示在顶部导航
            isFooterMenu: false, // 是否显示在底部导航
        }
      }, {
        path: '/grading-test-introduce',
        name: 'GradingTestIntroduce',
        component: require('@/pages/grading/grading-test-introduce').default,
        meta: {
            title: '考级介绍',
            isHeaderMenu: true
        }
      }, {
        path: '/examination-place',
        name: 'ExaminationPlace',
        component: require('@/pages/place/examination-place').default,
        meta: {
            title: '考点分布',
            isHeaderMenu: true
        }
      }, {
        path: '/evaluation',
        name: 'Evaluation',
        component: require('@/pages/evaluation/evaluation-page').default,
        meta: {
            title: '在线测评',
            isHeaderMenu: true,
            login: true
        },
        children: [{
          path: '/evaluation/scratch',
          component: require('@/pages/evaluation/evaluation-scratch').default,
          name: 'Scratch',
          meta: {
            title: 'Scratch',
            isSecondMenu: true,
            login: true
          }
        }, {
          path: '/evaluation/python',
          component: require('@/pages/evaluation/evaluation-python').default,
          name: 'Python',
          meta: {
            title: 'Python',
            isSecondMenu: true,
            login: true
          }
        }, {
          path: '/evaluation/c++',
          component: require('@/pages/evaluation/evaluation-c++').default,
          name: 'C++',
          meta: {
            title: 'C++',
            isSecondMenu: true,
            login: true
          }
        }]
      }, {
        path: '/download',
        name: 'Download',
        component: require('@/pages/download/download-file').default,
        meta: {
            title: '下载专区',
            isHeaderMenu: true
        }
      }, {
        path: '/examination-information',
        name: 'ExaminationInformation',
        component: require('@/pages/evaluation/examination-information').default,
        meta: {
            title: '考试信息',
            login: true
        }
      }, {
        path: '/examination-registration',
        name: 'ExaminationRegistration',
        component: require('@/pages/evaluation/examination-registration').default,
        meta: {
            title: '考试报名',
            login: true
        }
      }, {
        path: '/personal',
        name: 'Personal',
        component: require('@/pages/personal/personal-center').default,
        meta: {
            title: '个人中心',
            isHeaderMenu: false,
            login: true
        },
        children: [ {
          path: '/personal/application-record',
          component: require('@/pages/personal/application-record').default,
          name: 'ApplicationRecord',
          meta: {
            title: '报考记录',
            isSecondMenu: true,
            login: true
          }
        }, {
          path: '/personal/userInfo',
          component: require('@/pages/personal/user-info').default,
          name: 'UserInfo',
          meta: {
            title: '基本信息',
            isSecondMenu: true,
            login: true
          }
        }]
      }, {
        path: '/login',
        name: 'Login',
        component: require('@/pages/login/login-page').default,
        meta: {
            title: '登录',
            isHeaderMenu: false
        }
      }, {
        path: '/modify-password',
        name: 'ModifyPassword',
        component: require('@/pages/personal/modify-password').default,
        meta: {
            title: '修改密码',
            isHeaderMenu: false,
            login: true
        },
      }, {
        path: '/modify-account',
        name: 'ModifyAccount',
        component: require('@/pages/personal/modify-account').default,
        meta: {
            title: '修改账号',
            isHeaderMenu: false,
            login: true
        },
      }, {
        path: '/model-test',
        name: 'ModelTest',
        component: require('@/pages/test/model-test').default,
        meta: {
            title: '考试',
            isHeaderMenu: false,
            // login: true
        },
      }, {
        path: '/query-index',
        name: 'QueryIndex',
        component: require('@/pages/query/query-index').default,
        meta: {
            title: '查询成绩'
        },
      }, {
        path: '/query-detail',
        name: 'QueryDetail',
        component: require('@/pages/query/query-detail').default,
        meta: {
            title: '查询详情'
        },
      }, {
        path: '/query-exam',
        name: 'QueryExam',
        component: require('@/pages/query/query-exam').default,
        meta: {
            title: '查询考试'
        },
      }
]

/* let aa = vue._context.config.globalProperties*/
// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history,
  routes, // `routes: routes` 的缩写
})

// 4. 添加全局路由守卫
router.beforeEach((to, from, next) => {
    let toPath = to.path
    
    window.scrollTo(0, 0);
    // 老师及评审校色隐藏报考记录界面
    if (toPath.indexOf('/personal') != -1) {
      // 找到特定父路由
      const parentRouteToFind = '/personal'; // 替换为实际的父路由路径
      const foundParentRoute = router.options.routes.find(route => route.path === parentRouteToFind);

      if (foundParentRoute) {
        // 找到特定子路由并修改其 meta 值
        const childRouteToFind = '/personal/application-record'; // 替换为实际的子路由路径
        const foundChildRouteIndex = foundParentRoute.children.findIndex(child => child.path === childRouteToFind);
        
        if (foundChildRouteIndex !== -1) {
          const foundChildRoute = foundParentRoute.children[foundChildRouteIndex];
          foundChildRoute.meta.isSecondMenu = (store.getters.userAccount.role == 1 || store.getters.userAccount.role == 2) ? false : true; // 修改子路由的 meta 数据
        }
      }
    }


    if (to.meta.login && !AuthService.getAccountFromLocalStorage()) {
      // 如果需要登录验证，并且用户未登录，则跳转到登录页面
      if (toPath == '/model-test') {
        toPath = '/evaluation'
      }
      next(`/login?redirect=${toPath}`);
    } else {
      if (toPath.indexOf('/login') != -1  && AuthService.getAccountFromLocalStorage()) {
        next('/');
        return
      } 
      if (toPath == '/evaluation/c  ') {
        next('/evaluation/c++'); // 放行路由
        return
      }
      next(); // 放行路由
    }
})

export default router