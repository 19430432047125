const idCardType = [{
    name: '中国居民身份证',
    id: 1
}, {
    name: '香港永久性居民身份证',
    id: 2
}, {
    name: '澳门居民身份证',
    id: 3
}, {
    name: '台湾身份证',
    id: 4
}, {
    name: '护照',
    id: 5
}, {
    name: '港澳居民来往内地通行证',
    id: 6
}, {
    name: '台湾居民来往内地通行证',
    id: 7
}, {
    name: '其他',
    id: 8
}];

const gender = [{
        id: 1,
        name: '男'
    }, {
        id: 2,
        name: '女'
    }, {
        id: 0,
        name: '未知'
}]

const classList = ['幼儿园中班', '幼儿园大班', '一年级', '二年级', '三年级', '四年级', '五年级', '六年级', '七年级', '八年级', '九年级', '高一', '高二', '高三']

const roleList = [{
    id: -1,
    name: '学生'
}, {
    id: 0,
    name: '考生'
}, {
    id: 1,
    name: '评审老师'
}, {
    id: 2,
    name: '管理员'
}]

export default {
    idCardType,
    gender,
    classList,
    roleList
}